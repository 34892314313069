import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import '../../../scss/wspolnoty.css';
import '../../../scss/stawki.css';
import GoBackBtn from "../../GoBackBtn.js";

import WspolnotyStawkiLista from './WspolnotyStawkiLista.js'
import { objIsNotEmpty } from "../../../functions/utilities.js";

import { 
  fetchWspolnoty, 
} from '../../../Redux/Actions.js'; 

import WspolnotyStawkiAddForm from "./WspolnotyStawkiAddForm.js";
import WspolnotyStawkiJednorazowe from "./WspolnotyStawkiJednorazowe.js";

const WspolnotyStawki = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");

    const dispatch = useDispatch();

    const stawkiArch = useSelector( state => state?.stawki?.data);
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);

    const [activeWindow, setActiveWindow] = useState("lista");

    const [wspolnota, setWspolnota] = useState([]);

    useEffect( () => {
      setWspolnota( wspolnoty?.find( w => w.id == idw) );
    },[wspolnoty])

    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
    },[dispatch]);


    const [stawkiArch_w, setStawkiArch_w] = useState([]);
    const [stawkiAkt, setStawkiAkt] = useState([]);

    useEffect( () => {
      setStawkiArch_w( stawkiArch?.filter( s => s.id_wspolnoty == idw ) );
    },[stawkiArch]);

    useEffect( () => {
      setStawkiAkt( stawkiArch_w[0] );
    },[stawkiArch_w])

    const [tablica, setTablica] = useState([]);

    useEffect( () => {
      const tablica_temp = [];
      if (objIsNotEmpty(stawkiAkt)) {
        const o = stawkiAkt;
        const dodaneKlucze = {};
        for (const klucz in o) {
          if (Object.prototype.hasOwnProperty.call(o, klucz)) {
            const kluczBezRodzaj = klucz.replace('_rodzaj', ''); // Usuń '_rodzaj', jeśli występuje
            const kluczBezCzy = klucz.replace('czy_', '');
            const wartosc = o[klucz];
            const odpowiednikRodzaj = o[`${kluczBezRodzaj}_rodzaj`];
            const odpowiednikRodzaj2 = o[`czy_${kluczBezRodzaj}`];
  
            if (!dodaneKlucze[kluczBezRodzaj] && !dodaneKlucze[kluczBezCzy]) {
              tablica_temp.push({
                klucz: kluczBezRodzaj,
                wartosc,
                odpowiednikRodzaj,
                odpowiednikRodzaj2
              });
              dodaneKlucze[kluczBezRodzaj] = true;
              dodaneKlucze[odpowiednikRodzaj2] = true;
            }
          }
        }  
      }
      setTablica(tablica_temp)
    },[stawkiAkt]);

    const page = () => {
      switch(activeWindow) {
        case "lista":
          return (
            <WspolnotyStawkiLista
              idw={idw}
              stawkiAkt={stawkiAkt}
              setStawkiAkt={setStawkiAkt}
              tablica={tablica}
              stawkiArch_w={stawkiArch_w}
            />
          )
        case "dodaj":
          return (
            <WspolnotyStawkiAddForm 
              wspolnota={wspolnota}
              idw={idw}
              stawkiAkt={stawkiAkt}
            />
          )
        case "jednorazowe":
          return (
            <WspolnotyStawkiJednorazowe
              wspolnota={wspolnota}
            />
          )
        default: return ("");
      }

    }

    const handleLista = () => setActiveWindow("lista");
    const handleDodaj = () => setActiveWindow("dodaj");
    const handleJednorazowe = () => setActiveWindow("jednorazowe");

    return (
      <div className="content stawki">
          <h1 className="nazwa">
            Stawki wspólnoty { wspolnota?.name }
          </h1>
          <div className="buttons">
            <GoBackBtn />
            <button
              onClick={handleLista}
            >
              Podgląd stawek
            </button>
            <button 
              onClick={handleDodaj}
            >
              Dodaj nowe stawki
            </button>
            <button
              onClick={handleJednorazowe}
            >
              Stawki jednorazowe
            </button>
          </div>
          {page()}
      </div>
  )
}

export default WspolnotyStawki;