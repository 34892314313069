import React from "react";
import { motion}  from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'

const Strzalka = () => {

    const handleClick = () => {     
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } 

    return (
        <motion.div 
            onClick={handleClick}
            style={
                {
                    "position": "fixed", 
                    "bottom": "50px", 
                    "right": "50px",
                    "padding": "10px",
                    "border": "2px solid var(--fontAwesome)",
                    "borderRadius": "50%",
                    "boxSizing": "border-box",
                    "overflow": "hidden",
                    "cursor": "pointer"
                }
            }
            whileHover={
                {
                    scale: 1.1, 
                    tramsition: {
                        duration: 0.05
                    }
                }
            }
        >
            <FontAwesomeIcon icon={faAnglesUp} />
        </motion.div>
    )
}

export default Strzalka;