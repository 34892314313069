import React, { useState, useEffect } from 'react';
import { zl, podstawa } from '../../functions/utilities';
import { translate, rodzaj_rozliczenia } from '../../functions/dictionary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import MieszkaniecWydruk from './MieszkaniecWydruk';

const MieszkaniecStawki = props => {

    const { stawki_w, stawkiJednorazowe_msc, tablicaCzynsze, mieszkaniec, udzialyKonta_a, lokale, ulice, grupy_a} = props;

    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);
    const [czyWydruk, setCzyWydruk] = useState(false);

    useEffect( () => {
        let len = tablicaCzynsze?.temp_msc?.length;
        if (len && len > 1)
            setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[len - 2] );
        else if (len && len === 1)
        setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[0] );
    },[tablicaCzynsze])

    const generateListItems = (stawki) => {
        const elements = [];
        if (stawki) {
            const hasOwn = Object.prototype.hasOwnProperty;
            for (const key in stawki) {
                if (hasOwn.call(stawki, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki[key];
                    const rodzaj = stawki[rodzajKey];
                    const czyUwzględniany = stawki[czyKey] && !isNaN(tablicaCzynszeAkt[key]);
                    const czyStala = rodzaj_rozliczenia(rodzaj, key) === "opłata stała" ? "1" : podstawa(tablicaCzynszeAkt, key)

                    if (czyUwzględniany && cena > 0 && tablicaCzynszeAkt[key] > 0) {
                        elements.push(
                            <li key={key}>
                                <div>
                                    {translate(key)}
                                </div>
                                <div>
                                    {rodzaj_rozliczenia(rodzaj, key)}
                                </div>
                                <div>
                                    { isNaN(czyStala) ? 0 : czyStala}
                                </div>
                                <div>
                                    { isNaN(cena) ? 0 : zl(cena)}
                                </div>
                                <div>
                                    {zl(tablicaCzynszeAkt[key])}
                                </div>
                            </li>
                        );
                    }
                }
            }
            return elements;
        } else return ""
    };
    const generateListItems2 = s => {

        return (
            <>
              {
                s?.dod_1_czy == 1 ? (
                    <li>
                        <div>
                            { s?.dod_1_opis }
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>
                            { zl(s.dod_1_kwota) }
                        </div>
                        
                    </li>
                ) : ""
              }  
            {
                s?.dod_2_czy == 1 ? (
                    <li>
                        <div>
                            { s?.dod_2_opis }
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>
                            { zl(s.dod_2_kwota) }
                        </div>
                        
                    </li>
                ) : ""
              }  
            {
                s?.dod_3_czy == 1 ? (
                    <li>
                        <div>
                            { s?.dod_3_opis }
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>
                            { zl(s.dod_3_kwota) }
                        </div>
                        
                    </li>
                ) : ""
              }  

            </>
        )
    }

    const showPrint = () => setCzyWydruk( prevState => !prevState );
    
    return (
        <div className='stawkiAkt'>
            <div className='naglowek'>
                <h4>
                    Aktualne stawki:
                </h4>
                    <FontAwesomeIcon 
                        icon={faPrint} 
                        className='pointer'
                        onClick={showPrint}
                    />
            </div>
            <ul>
                <li className='firstRow'>
                    <div>
                        Tytuł zaliczki
                    </div>
                    <div>
                        j.m.
                    </div>
                    <div>
                        ilość
                    </div>
                    <div>
                        cena j.
                    </div>
                    <div>
                        Kwota zaliczki
                    </div>
                </li>
                {generateListItems(stawki_w)}
                {generateListItems2(stawkiJednorazowe_msc)}
                <li className='lastRow'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        Razem:
                    </div>
                    <div>
                        {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
                    </div>
                </li>
            </ul>
            
                { czyWydruk ? (
                    <MieszkaniecWydruk
                        udzialyKonta_a={udzialyKonta_a}
                        showPrint={showPrint}
                        mieszkaniec={mieszkaniec}
                        tablicaCzynszeAkt={tablicaCzynszeAkt}
                        podstawa={podstawa}
                        stawki_w={stawki_w}
                        stawkiJednorazowe_msc={stawkiJednorazowe_msc}
                        lokale={lokale}
                        ulice={ulice}
                        grupy_a={grupy_a}
                    />
                ) : ""}
            

        </div>
    )

}

export default MieszkaniecStawki;