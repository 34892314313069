import React, { useEffect, useState } from 'react';
import axios from "axios";
import '../../../scss/style.css';
import '../../../scss/listy.css';
import GoBackBtn from '../../GoBackBtn';
import KontaBtnAdd from './KontaBtnAdd'

const KontaLista = () => {

    const [konta, setKonta] = useState([]);
    const [wspolnoty, setWspolnoty] = useState([])
    const [wybranaWspolnota, setWybranaWspolnota] = useState({});

    useEffect( () => {
        axios.get("/api/konta")
            .then(response => {
                const { data } = response;
                setKonta( data )
        })    
        axios.get("/api/lista_wspolnot")
            .then(response => {
                const { data } = response;
                setWspolnoty( data )
        })  
    },[])
   
    const takNie = ( p ) => {
        return p == 1 ? "Tak" : "Nie";
    }

    const lista = konta?.map( (e,i) => {
        if (e.id_wspolnoty == wybranaWspolnota) {
            return (
                <li key={i}>
                <div>{e.numer_konta.toString()}</div>
                <div>{e.nazwa}</div>
                <div>{takNie(e.bilansowe)}</div>
                <div>{takNie(e.wynikowe)}</div>
                <div>{takNie(e.pozabilansowe)}</div>
                <div>{takNie(e.rozrachunkowe)}</div>
                <div>{e.kartoteka}</div>
                {/* <div>
                    <FontAwesomeIcon 
                        icon={faFile} 
                        style={{color: "#307bfd",}} 
                        onClick={()=>{duplikuj()}}
                    />
                </div> */}
            </li>
            )
        }

    })

    const listaWspolnot = wspolnoty?.map ( (el, i) => (
        <option key={i} value={el.id}>
            {el.name}
        </option>
    ));

    const onChangeHandle = (e) => {
        setWybranaWspolnota(e.target.value);
    }

    return (
        <div className='content'>
            <h1 className="nazwa">
                Lista kont
            </h1>
            <GoBackBtn /> <KontaBtnAdd />
            <div className='listaWspolnot'>
                    <select onChange={onChangeHandle}>
                        <option value="0">
                            Wybierz wspólnotę:
                        </option> 
                        {listaWspolnot}
                    </select>
                  </div>
            <ul className='lista konta'>
                <li>
                    <div>Numer konta</div>
                    <div>Nazwa</div>
                    <div>Bilansowe</div>
                    <div>Wynikowe</div>
                    <div>Pozabilansowe</div>
                    <div>Rozrachunkowe</div>
                    <div>Kartoteka</div>
                    {/* <div>Duplikuj do</div> */}
                </li>
                {lista}
            </ul>
        </div>
    )
}

export default KontaLista;