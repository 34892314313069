import React, { useEffect, useState } from 'react';
import axios from "axios";
import '../../../scss/style.css';
import '../../../scss/listy.css';
import GoBackBtn from '../../GoBackBtn';
import KontrahentBtnAdd from './KontrahentBtnAdd';




const KontrahenciLista = () => {

    const [kontrahenci, setKontrahenci] = useState([]);

    useEffect(()=>{
        axios.get("/api/kontrahenci")
        .then(response => {
            const { data } = response;
            setKontrahenci( data )
        })   
    },[])

    const kontrahenciLista = kontrahenci?.map( element => (
        <li key={element.id} className={ element.id % 2 === 0 ? "g": ""}>
            <div>{element.nazwa}</div>
            <div>*konto*</div>
            <div>{element.nip}</div>
            <div>{element.regon}</div>
            <div>{element.ulica + " " + element.numer_domu + "/" + element.numer_lokalu + ", " + element.kod_pocztowy + " " + element.miejscowosc}</div>
            <div>{element.telefon + ", " + element.email}</div>
            <div>*edytuj*</div>
        </li>
    ))

    return (
        <div className='content'>
            <h1 className="nazwa">
                Lista kontrahentów
            </h1>
            <GoBackBtn />
            <KontrahentBtnAdd />
            <div className="comment">
              <p>Do zrobienia:</p>
              <p>- podpiąć konta ksiegowe</p>
              <p>- podpiąć formularz do edycji kontrahenta</p>
            </div>
            <ul className="lista kontrahenci">
                <li className='naglowek' >
                    <div>Kontrahent</div>
                    <div>Konto księgowe</div>
                    <div>NIP</div>
                    <div>REGON</div>
                    <div>Adres</div>
                    <div>Kontakt</div>
                    <div>Edytuj</div>
                </li>
                { kontrahenciLista }

            </ul>

        </div>
    )
}

export default KontrahenciLista;