const initialState = 0;

function refreshReducer(state = initialState, action) {
    switch (action.type) {
      case 'INCREMENT':
        return state + 1;
      default:
        return state;
    }
  }
  
  export default refreshReducer;