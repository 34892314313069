import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const GoBackBtn = () => {
    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(-1)}>
            Wróć
        </button>
    )
}

export default GoBackBtn;
