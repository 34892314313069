import React from 'react';

const WspolnotyUdzialyTH = (props) => {
    // console.log(props.podpis)

    const styl = props.podpis ? {
        textAlign: 'center',
        border: '1px solid black',
        padding: '0',
        fontSize: '12px',
        fontWeight: '700',
        width: '150px'
    } : {
        textAlign: 'center',
        border: '1px solid black',
        padding: '0',
        fontSize: '12px',
        fontWeight: '700'
    };

    return (
        <td
            className='central w-50'
            style={styl}>
                {props.value}
        </td>  
    )
}

export default WspolnotyUdzialyTH;