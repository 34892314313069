import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import '../../../scss/style.css';
import '../../../scss/form.css';
import '../../../scss/listy.css';
import '../../../scss/ksiegowosc.css';

import { fetchPrzelewy } from '../../../Redux/Actions.js'; 

const ImportTransakcjiSend = props => {

    const dispatch = useDispatch();

    const {pelneDane, setData, setFileName, setPelneDane, setCzyDubel, setWczytane} = props;

    async function sendHandle() {
        const promises = pelneDane.map(async (item, index) => {
            try {
              const response = await axios.post('/api/przelewy/dodaj', item);
              console.log(`Response for item ${index}:`, response.data);
              
            } catch (error) {
              console.error(`Error for item ${index}:`, error);
            }
        })

        await Promise.all(promises)
        setData([]);
        setFileName("");
        setPelneDane([]);
        document.getElementById("file-input").value = "";
        setCzyDubel(false);
        setWczytane([]);
        dispatch(fetchPrzelewy()); 
    }

    const clearHandle = () => {
        setData([]);
        setFileName("");
        setPelneDane([]);
        document.getElementById("file-input").value = "";
        setCzyDubel(false);
        setWczytane([]);
    }

    return (
        <div className='send'>
            { pelneDane && pelneDane.length > 0 ? (
                <>
                    <button 
                        onClick={sendHandle}
                    >
                        Wyślij plik 
                    </button>
                    <button
                        onClick={clearHandle}
                    >
                        Wyczyść
                    </button>
                </>
            ) : ""
                
            }
        </div>
    )
}

export default ImportTransakcjiSend;