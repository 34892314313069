import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { isNumber, zl } from '../../functions/utilities';

const MieszkaniecSaldo = props => {

    const { tablicaCzynsze, tablicaPrzelewy } = props;

    let sumaNaleznosci = 0;
    if (tablicaCzynsze?.temp_msc?.length > 1) {
        for (let i = 0; i < tablicaCzynsze.temp_msc.length - 1; i++) {
          sumaNaleznosci += tablicaCzynsze.temp_msc[i].czynsz_na_msc;
        }
    } else if (tablicaCzynsze?.temp_msc?.length == 1) {
        sumaNaleznosci = tablicaCzynsze.temp_msc[0].czynsz_na_msc;
    }

    let sumaPrzelewow = 0;
    tablicaPrzelewy?.forEach( e => sumaPrzelewow += e.kwota)

    const [saldo, setSaldo] = useState("");

    useEffect( () => {
        setSaldo( sumaPrzelewow - sumaNaleznosci );
    },[sumaNaleznosci, sumaPrzelewow])

    const data = format(new Date(), 'dd-MM-yyyy');

    const opis = () => {
        if (isNumber(saldo)) {
            if (parseFloat(saldo?.toFixed(2)) > 0)
                return `występuje nadpłata w wysokości ${zl(saldo)}.`;
            else if (parseFloat(saldo?.toFixed(2)) == 0)
                return `saldo wynosi 0 zł.`;
            else if (parseFloat(saldo?.toFixed(2)) < 0)
                return `występuje niedopłata w wysokości ${zl(saldo)}.`;
        } else return "";

    }

    return (
        <div className='saldo'>
            <h4>
                Saldo
            </h4>  
            <span>Na dzień {data} na koncie {opis()}</span>
        </div> 
    )
}

export default MieszkaniecSaldo;