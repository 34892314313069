import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
// import { 
//     fetchLokale, 
//     fetchUlice, 
//     fetchGrupy, 
//     fetchUdzialyKonta,

//   } from '../../Redux/Actions.js'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { dataFormat } from "../../functions/dataFormat";
import { getLast17, zl } from "../../functions/utilities";
// import Dialog from "../Dialog";



const MieszkancyDaneGrupyHistoriaWplat = props => {

    const { przelewyGrupyPokaz, setPrzelewyGrupyPokaz, przelewy, udzialGrupa, tablicaPrzelewy_g } = props;

    const onClickHandle = () => {
        setPrzelewyGrupyPokaz( prevState => !prevState)
    }

    let listaWplat = [];

    if (tablicaPrzelewy_g?.przelewy?.length > 0) {
        listaWplat = tablicaPrzelewy_g?.przelewy?.map( (p,i) => {
            return (
                <li key={i}>
                    <div>
                        {dataFormat(p.data_operacji)}
                    </div>
                    <div>
                        {zl(p.kwota)}
                    </div>
                </li>
            )
        });
    }



    const wplaty = (
        <div className="zmiana">
            <ul className="historiaWplat">
                {listaWplat?.length > 0 ? listaWplat : <span className="brakWplat">brak wpłat w tej grupie</span>}
            </ul>
        </div>
    )

    return (
        <div className="historia">
            <h4>
                <span 
                    onClick={()=>{onClickHandle()}}
                >
                    Historia wpłat { przelewyGrupyPokaz ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                </span>
            </h4>
            { przelewyGrupyPokaz && wplaty}
        </div>
    )
}

export default MieszkancyDaneGrupyHistoriaWplat;