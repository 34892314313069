import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const KontaktForm = ( props ) => {
    const form = useRef();

    const [imie, setImie] = useState("");
    const [email, setEmail] = useState("");
    const [wiadomosc, setWiadomosc] = useState("");

    const handleImie = e => setImie(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handleWiadomosc = e => setWiadomosc(e.target.value)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_nufifjg', 'template_ckz1ce4', form.current, 'Sf5FCcGAApPYZnnXi')
            .then((result) => {
                setImie("");
                setEmail("");
                setWiadomosc("");
                props.setSukces(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <label>Imię i nazwisko</label>
            <input 
                type="text" 
                name="user_name" 
                value={imie} 
                onChange={ e => handleImie(e)}
            />
            <label>E-mail</label>
            <input 
                type="email" 
                name="user_email" 
                value={email} 
                onChange={ e => handleEmail(e)} 
            />
            <label>Treść wiadomości</label>
            <textarea 
                name="message" 
                rows="6"
                value={wiadomosc}
                onChange={ e => handleWiadomosc(e) }
            />
            <button type="submit" >Wyślij</button>
        </form>
    );
};

export default KontaktForm;