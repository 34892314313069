import React, { createContext, useState } from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(false); 
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [userId, setUserId] = useState("");
    const [cred, setCred] = useState([]);
    return (
        <AuthContext.Provider value={{ auth, setAuth, role, setRole, user, setUser, userId, setUserId, cred, setCred }}>
            { children }
        </AuthContext.Provider>
    )
};

export default AuthProvider;