import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, } from 'react-router-dom';
import { adresPro } from "../../../functions/ulice";
import { objIsNotEmpty, zl } from "../../../functions/utilities";

const WspolnotaRaportZbiorowy = ( props ) => {

    const { miesiac, listaMsc, idw } = props;

    const location = useLocation();
    const { state } = location;


    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const przelewyWspolnota = state?.from?.tablicaPrzelewy;
    const czynszeWspolnota = state?.from?.tablicaCzynsze;

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);

    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([])

    useEffect( () => {
        setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( udzial => udzial?.id_wspolnoty == idw))
    },[udzialyKonta, idw])

    // console.log(grupy)

    const findGrupaZMieszkaniem = (udzialKonto, grupy, lokale) => {
        const grupy_u = grupy?.filter( g => g?.id_user == udzialKonto?.id_user && g?.grupa_numer == udzialKonto?.grupa_numer );
        const wynik = grupy_u.filter(grupa => {
            const lokal = lokale.find(lokal => lokal.id === grupa.id_lokal);
            return lokal && lokal.rodzaj === "mieszkanie";
          });
        return wynik;          
    }

    const tab = udzialyKontaAkt_w?.map( (udzial, i) => {

        const mieszkaniec = mieszkancy.find( mieszkaniec => mieszkaniec.id_user == udzial.id_user);

        const grupaMieszkanie = findGrupaZMieszkaniem(udzial, grupy, lokale)[0];

        const grupyMsc = czynszeWspolnota?.find( czynsz => czynsz?.id_user == udzial?.id_user).grupy_msc?.find( c => c?.grupa_numer == udzial.grupa_numer);
        const czynsz_na_msc = grupyMsc?.temp_msc?.find( g => g?.msc == miesiac)?.czynsz_na_msc
        const przelewy = przelewyWspolnota?.find( p => p?.id_user == udzial?.id_user && p?.nr_grupyUdzial == udzial?.grupa_numer)?.przelewy;
        const przelewyMsc = przelewy?.filter( p => p?.msc == miesiac)
        
        let sumaPrzelewowMsc = 0;
        przelewyMsc?.forEach( g => sumaPrzelewowMsc += g.kwota);

        let sumaNaleznosciTotal = 0, sumaPrzelewyTotal = 0;

        const miesiace = listaMsc?.map( msc => {

            const czynsz_na_msc = grupyMsc?.temp_msc?.find( g => g?.msc == msc)?.czynsz_na_msc;
            sumaNaleznosciTotal += czynsz_na_msc;

            const przelewy = przelewyWspolnota?.find( p => p?.id_user == udzial?.id_user && p?.nr_grupyUdzial == udzial?.grupa_numer)?.przelewy;

            let sumaPrzelewy = 0;
            const przelewyMsc = przelewy?.filter( p => p?.msc == msc)
            sumaPrzelewy += przelewyMsc?.reduce((acc, obj) => {
                return acc + obj.kwota;
            },0);
            sumaPrzelewyTotal += sumaPrzelewy;

            return (
                <>
                    <td style={{whiteSpace: "nowrap" }}>{`${zl(czynsz_na_msc)}`}</td>
                    <td style={{whiteSpace: "nowrap" }}>{zl(sumaPrzelewy)}</td>
                    <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>{ zl(sumaPrzelewy - czynsz_na_msc)}</td>
                </>
            )
        })

        const podsumowanie = (
            <>
                <td style={{whiteSpace: "nowrap" }}>{zl(sumaNaleznosciTotal)}</td>
                <td style={{whiteSpace: "nowrap" }}>{zl(sumaPrzelewyTotal)}</td>
                <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>{ zl(sumaPrzelewyTotal - sumaNaleznosciTotal)}</td>
            </>
        )

        return (    
            <tr>
                <td style={{whiteSpace: "nowrap" }}>
                    {`${mieszkaniec?.imie_nazwisko} (id ${mieszkaniec?.id_user})`}
                </td>
                <td style={{whiteSpace: "nowrap" }}>
                    {`${adresPro(grupaMieszkanie?.id_lokal, lokale, ulice)}, (nr grupy: ${udzial?.grupa_numer})`}
                </td>
                {miesiac !== "2024"  ? (
                    <>
                        <td style={{whiteSpace: "nowrap" }}>
                            { zl(czynsz_na_msc)}
                        </td>
                        <td style={{whiteSpace: "nowrap" }}>
                                {zl(sumaPrzelewowMsc)}
                        </td>
                        <td style={{fontWeight: "800"}}>
                            { zl(sumaPrzelewowMsc - czynsz_na_msc)}
                        </td>
                    </>
                ) : (
                    <>
                        {miesiace}
                        {podsumowanie}
                    </>
                )
                }
            </tr>
        )
    }) 

    return (
        <tbody>
            {tab}
        </tbody>
    ) 
}

export default WspolnotaRaportZbiorowy;