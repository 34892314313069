import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const WspolnotyUliceLista = props => {

    const { ulice_w, setActiveWindow, wybranaUlica, setWybranaUlica } = props;

    const handleWybranaUlica = e => {
        setWybranaUlica(e);
    }
    
    const listaUlice = ulice_w?.map ( (ulica, i) => (
        <li 
            key={i} 
            onClick={ ( ) => handleWybranaUlica(ulica.id) } 
            className="pointer"
            style={ wybranaUlica == ulica.id ? {"backgroundColor": "var(--podswietlenie)"} : {} }
        >
            <div>
                { ulica.rodzaj + " " + ulica.nazwa + " " + ulica.numer_budynku }
            </div>
        </li>
    ))

    const handleClick = () => setActiveWindow("dodaj");

    return (
        <div className="wspolnotyUliceLista">
            <div className="naglowek">
                <h3>
                    Lista ulic 
                </h3>
                <FontAwesomeIcon 
                    icon={faPlus}
                    className="pointer" 
                    onClick={handleClick}
                />
            </div>
            { listaUlice && listaUlice?.length > 0 ? (
                    <ul className="uliceLista">
                        {listaUlice}
                    </ul>
                ) : (<h4>Brak ulic</h4>) 
            }            
        </div>
    )
}

export default WspolnotyUliceLista;