import React from "react";
import { Formik, Form } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import FormikControl from "../Formik/FormikControl";
import '../../scss/style.css';
import '../../scss/form.css';
import '../../scss/wspolnoty.css';
import GoBackBtn from "../GoBackBtn";

const WspolnotyAddForm = () => {

    const navigate = useNavigate();

    const initialValues = {
        name: '',
        nip: '',
        ulica: '',
        nrDomu: '',
        nrLokalu: '',
        kodPocztowy: '',
        miejscowosc: '',
        bankNazwa: '',
        nrKonta: '',
    }
    
    const addData = async (values) => {
        let id;
        try {
          // Wysyłamy dane na serwer
          await axios.post('/api/dodaj_wspolnote', {
            name: values.name === '' ? null : values.name,
            nip: values.nip === '' ? null : values.nip,
            ulica: values.ulica === '' ? null : values.ulica,
            nr_domu: values.nrDomu === '' ? null : values.nrDomu,
            nr_lokalu: values.nrLokalu === '' ? null : values.nrLokalu,
            kod_pocztowy: values.kodPocztowy === '' ? null : values.kodPocztowy,
            miejscowosc: values.miejscowosc === '' ? null : values.miejscowosc,
            bank_nazwa: values.bankNazwa === '' ? null : values.bankNazwa,
            nr_konta: values.nrKonta === '' ? null : values.nrKonta
          }).then(response => {
            navigate(`/wspolnoty`);
          });
          // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
      };
    
    const onSubmit = ( values, onSubmitProps) => {

        addData(values);
    
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
    }

    return (
        <div className="content">
            <h1 className="nazwa">
                Dodaj wspólnotę
            </h1>
            <div className="AddForm">
                <GoBackBtn />
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount>
                    {formik => {
                        return (
                            <Form>
                                <FormikControl control='input' type='text' label='Nazwa:' name='name' />                    
                                <FormikControl control='input' type='text' label="NIP:" name='nip' />
                                <FormikControl control='input' type='text' label='Ulica:' name='ulica' />
                                <FormikControl control='input' type='text' label='Nr domu:' name='nrDomu' />
                                <FormikControl control='input' type='text' label='Nr lokalu:' name='nrLokalu' />
                                <FormikControl control='input' type='text' label='Kod pocztowy:' name='kodPocztowy' />
                                <FormikControl control='input' type='text' label='Miejscowość:' name='miejscowosc' />
                                <FormikControl control='input' type='text' label='Nazwa banku:' name='bankNazwa' />
                                <FormikControl control='input' type='text' label='Numer konta:' name='nrKonta' />
                                <button type='submit' disabled={!formik.isValid || formik.isSubmitting}>Zapisz</button>
                                <button type="reset">Wyczyść</button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>       
        </div>
        
    )
}

export default WspolnotyAddForm;

