import React from 'react';

const HistoriaTransakcjiFiltrMieszkancy = (props) => {

    const {mieszkancy_w, wybranyMieszkaniecId, setWybranyMieszkaniecId} = props; 
    const handleChange = e => {
        setWybranyMieszkaniecId(e.target.value)
    }

    const lista = mieszkancy_w?.map( (m, i) => (
        <option key={i} value={m.id_user}>
            {m.imie_nazwisko}
        </option>
    ))

    return (
        <select value={wybranyMieszkaniecId} onChange={handleChange}> 
            <option key={0} value={0}>Wybierz mieszkańca</option>
            {lista}
        </select>
    )
}

export default HistoriaTransakcjiFiltrMieszkancy;