import React, {  } from "react";
import '../scss/style.css';
import '../scss/dialog.css';

const Loader = props => {

    const { stan } = props;

    return (
        <>
            {
                stan ? (
                    <div
                        className="dialog block"
                    >
                        <div
                            className="logo"
                        >
                        </div>
                    </div>
                ) : ""
            }
        </>
    )
}

export default Loader;