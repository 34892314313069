export const wstepy = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.5
        }
    }
}

export const opinie = {
    initial: {
        opacity: 0,
        scale: 0
    },
    animate1: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.5
        }
    },
    animate2: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.25,
            duration: 0.5,
        }
    },
    animate3: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    }
}

export const ofertaSlajdy = {
    initial: {
        x: -100,
        opacity: 0
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0
        }
    }
}

export const once = {
    once: true
}

export const menuLi = {
    y: -2,
    transition: 0.2
}