import React from 'react';

const MieszkancyDaneDodajGrupeLokale = props => {

    const { lokale, ulice, rodzajLokalu, id_wspolnoty, wybraneLokale, setWybraneLokale } = props;

    const handleLokalToggle = lokal => {
        if (wybraneLokale?.includes(lokal)) {
            setWybraneLokale(wybraneLokale?.filter(wybrany=> wybrany !== lokal))
        } else {
            setWybraneLokale([...wybraneLokale, lokal])
        }
    }

    const wolneLokale = lokale?.filter( lokal => lokal.czy_zajety === null && lokal.rodzaj === rodzajLokalu && lokal.id_wspolnoty == id_wspolnoty)
    const box = wolneLokale.map( ( lokal, i ) => {
        const ulica = ulice?.filter( ulica => ulica?.id == lokal?.ulica)[0];
        return (
            <div key={i} className='checkbox'> 
                <input 
                    type="checkbox" 
                    id={'g_' + lokal.id} 
                    onChange={ () => handleLokalToggle(lokal)}
                /> 
                <label 
                    htmlFor={'g_' + lokal.id}
                >
                    { ulica?.nazwa + " " + ulica?.numer_budynku + ", nr " + lokal.numer }
                </label> 
            </div>
        )
    })

    const dodaj = () => {
        switch (rodzajLokalu) {
            case "garaż":
                return {a: "garaże", b: "garaży"};
            case "komórka":
                return {a: "komórki", b: "komórek"};
            case "strych":
                return {a: "strychy", b: "strychów"};
            default:
                return "";
        }        
    }

    return (
        <>
            <h5>{`Dodaj ${dodaj().a}`}:</h5>
            <div className='listaBoxy ramka'>
                {
                    box?.length > 0 ?
                    (box) :
                    (<span className='brak'>{`brak wolnych ${dodaj().b} w tej WM`}</span>) 
                }
            </div>
        </>
    )
}

export default MieszkancyDaneDodajGrupeLokale;