import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

function Textarea(props){
    const {label, name, ...rest} = props;
    return (
        <div className='form-control'>
            <label htmlFor={name} className='input'>{label}</label>
            <Field as='textarea' id={name} name={name} rows="10" cols="50" {...rest} />
            <ErrorMessage name={name} component={TextError}/>
        </div>

    )
}

export default Textarea;