import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import scrollToTop from '../../functions/ScrollToTop';
import Strzalka from '../Strzalka';
import '../../scss/glowna.css';
import axios from "axios";
import { AuthContext} from '../../Context/AuthProvider';

const Glowna = () => {

    useEffect( ()=> {
        scrollToTop();
    },[])
    const navigate = useNavigate();

    const { userId } = useContext(AuthContext);

    useEffect( () => {
        const id_temp = userId ? userId : 0;
        axios.post("/api/log", { id_user: id_temp, akcja: "Strona Główna" })
    },[]);

    return (
        <div className='glowna'>
            <div className='text'>
                <motion.div className='wstep cienie s1' 
                    variants={wstepy}
                    initial="initial"
                    animate="animate"
                >
                    <div className='photo'></div>
                    <div className='opis'>
                        <h3>Słowem wstępu</h3> 
                        <div className='separator'></div>

                        <div>
                            <p>
                                Jesteśmy firmą która składa się z profesjonalistów o bardzo długim i bogatym doświadczeniu w administrowaniu oraz
                                zarządzaniu nieruchomościami. Oferujemy kompleksowe usługi zarządzania oraz administrowania nieruchomościami na
                                zlecenie. Oferujemy również wykonywanie operatów szacunkowych przez rzeczoznawcę majątkowego.
                            </p>
                            <p>
                                Obsługujemy wszystkie rodzaje nieruchomości: mieszkaniowe, użytkowe, komercyjne.
                            </p>
                        </div>
                    </div>
                </motion.div>


                <motion.div className={`wstep cienie s2`}
                    variants={wstepy}
                    initial="initial"
                    whileInView="animate"
                >
                    <div className='opis'>
                        <h3>współpraca</h3> 
                        <div className='separator'></div>

                        <div>
                            <p>
                                Jeśli jesteś naszym klientem, w celu zalogowania prosimy przejść <span className='link' onClick={()=>navigate('/login')}>tutaj</span>.
                            </p>
                            <p>
                                Jeśli jesteś zainteresowany współpracą z nami, zapraszamy do zakładki o nas bądź bezpośredniego <span className='link' onClick={()=>navigate('/kontakt')}>kontaktu</span>. Dziękujemy za zainteresowanie i odwiedzenie naszej strony.
                            </p>
                            <p>
                                Akt prawny - <a href="https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU19940850388/U/D19940388Lj.pdf" target="_blank">Ustawa o własności lokali</a>.
                            </p>
                        </div>
                    </div>
                    <div className='photo'></div>
                </motion.div>

            </div>
            <Strzalka/>
        </div>
    )
}

export default Glowna;