import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import DialogOk from "../../DialogOk.js";
import {  
    fetchLokale
  } from '../../../Redux/Actions.js'; 

const WspolnotyLokaleDodaj = props => {

    const { setActiveWindow, idw, wybranaUlica } = props;

    const dispatch = useDispatch();

    const [rodzajLokalu, setRodzajLokalu] = useState("");
    const [numerLokalu, setNumerLokalu] = useState("");
    const [klatka, setKlatka] = useState("");
    const [metrazLokalu, setMetrazLokalu] = useState("");
    const [udzial, setUdzial] = useState("");

    const handleRodzaj = e => setRodzajLokalu( e.target.value );
    const handleNumer = e => setNumerLokalu( e.target.value );
    const handleKlatka = e => setKlatka( e.target.value );
    const handleMetraz = e => setMetrazLokalu( e.target.value );
    const handleUdzial = e => setUdzial( e.target.value );
    const [open, setOpen] = useState(false);
    const handleAnuluj = () => setActiveWindow("lista");

    const handleZapisz = async () => {
        if (rodzajLokalu === "" || numerLokalu === "" || metrazLokalu === "" || udzial === "")  {
            setOpen(true);
        } else {
            try {   
                // Wysyłamy dane na serwer
                await axios.post('/api/lokal/dodaj', {
                  ulica: wybranaUlica,
                  rodzaj: rodzajLokalu,
                  klatka: klatka,
                  numer: numerLokalu,
                  metraz: metrazLokalu,
                  udzial: udzial,
                  id_wspolnoty: idw
                }).then(response => {
                    if (response.status === 200) {
                        dispatch(fetchLokale());
                        setRodzajLokalu("0");
                        setNumerLokalu("");
                        setKlatka("");
                        setMetrazLokalu("");
                        setUdzial("");
                        setActiveWindow("lista")
                    }
                });
                // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
              } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }

    }

    return (
        <div className="wspolnotyLokaleDodaj">
            <div className="naglowek">
                <h3>
                    Dodawanie lokalu
                </h3>
            </div>
            <DialogOk 
                open={open}
                setOpen={setOpen}
                message={"Dodaj brakujące dane (rodzaj, numer lokalu, metraż lub udział)."}
            />
            <div>
                <p>Rodzaj lokalu:</p>
                <select 
                    onChange={handleRodzaj} 
                    value={rodzajLokalu}
                >
                    <option value={0}>Wybierz:</option>
                    <option value={"mieszkanie"}>mieszkanie</option>
                    <option value={"garaż"}>garaż</option>
                    <option value={"komórka"}>komórka</option>
                    <option value={"strych"}>strych</option>
                </select>
            </div>
            <div>
                <p>Numer lokalu:</p>
                <input 
                    type="text" 
                    onChange={handleNumer} 
                    value={numerLokalu}
                />
            </div>
            <div>
                <p>Klatka:</p>
                <input 
                    type="text" 
                    onChange={handleKlatka} 
                    value={klatka}
                />
            </div>
            <div>
                <p>Metraż:</p>
                <input 
                    type="number" 
                    onChange={handleMetraz} 
                    value={metrazLokalu}
                />
            </div>
            <div>
                <p>Udział:</p>
                <input 
                    type="number" 
                    onChange={handleUdzial} 
                    value={udzial}
                />
            </div>
            <button 
                onClick={handleZapisz}
            >
                Dodaj
            </button>
            <button
                onClick={handleAnuluj}
            >  
                Anuluj
            </button>
        </div>
    )
}

export default WspolnotyLokaleDodaj;