import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataFormat } from '../../functions/dataFormat.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion' 
import { format } from 'date-fns';
import axios from "axios";
import { fetchMieszkancy, fetchMieszkancyAll } from '../../Redux/Actions.js';
import DialogYesNo from '../DialogYesNo.js';

const MieszkancyDaneHistoriaZmian = ( props ) => {

    const { mieszkaniecAll, zmianyMieszkancaPokaz, setZmianyMieszkancaPokaz } = props;
    const dispatch = useDispatch();
    const remove = id => {
        try {
            axios.post('/api/mieszkaniec/usun', {
                id: id,
            }).then( response => {
                dispatch(fetchMieszkancy());
                dispatch(fetchMieszkancyAll());
            })
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
    }

    const [open, setOpen] = useState(false)

    const zmiany = mieszkaniecAll?.map( m => {

        return (
            <motion.div className='mieszkancyHistoria'>
                <DialogYesNo 
                    open={open}
                    setOpen={setOpen}
                    message={`Czy na pewno chcesz usunąć ten wpis?`}
                    f={remove}
                    id={m.id}
                />
                <div className='wiersz'>
                    <div className='data'>
                        Data zmiany: {dataFormat(m.data_od)}
                    </div>
                    <div style={{textAlign: "right"}}>
                        <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={()=>{setOpen(true)}}
                            className='pointer'
                        />
                    </div>
                </div>

                <div className='wiersz'>
                    <div>
                        Data utworzenia:
                    </div>
                    <div>
                        {format(m.data_utworzenia,'yyyy-MM-dd HH:mm:ss')}
                    </div>                 
                </div>
                <div className='wiersz'>
                    <div>
                        {m.imie_nazwisko}
                    </div>
                    <div>
                        {`${m.email}, ${m.telefon}`}
                    </div>
                </div>
                <div className='wiersz'>
                    <div>
                        {`Nota obciążeniowa: ${m.czy_nota ? "Tak" : "Brak"}`}
                    </div>
                    <div>
                        {`Czy zasiada w zarządzie: ${m.czy_zarzad ? "Tak" : "Nie"}`}
                    </div>
                </div>
                <div>
                    {`NIP: ${m.nip ? m.nip : "brak"}`}    
                </div>   
            </motion.div>
        )

    })
        
    const onClickHandle = () => {
        setZmianyMieszkancaPokaz( prevState => !prevState)
    }
    
    return(
        <>
            <h4 onClick={onClickHandle} className='pointer'>
                <span>
                    Historia zmian
                </span> 
                <span>
                    { zmianyMieszkancaPokaz ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                </span>
            </h4>
            { zmianyMieszkancaPokaz && zmiany }
        </>
    )
}

export default MieszkancyDaneHistoriaZmian;