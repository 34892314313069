import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

function InputRef(props){
    const {label, name, ref, ...rest} = props;
    return (
        <div className='form-control'>
            <label htmlFor={name} className='input'>{label}</label>
            <Field id={name} name={name} ref={ref} {...rest} />
            <ErrorMessage name={name} component={TextError}/>
        </div>

    )
}

export default InputRef;