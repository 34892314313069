import React, { useContext, useEffect, useState } from 'react';

import '../../scss/style.css';
import '../../scss/listy.css';
import '../../scss/mieszkancyRozliczenie.css';
import MieszkaniecStawki from './MieszkaniecStawki.js';
import MieszkaniecRozliczenieList from './MieszkaniecRozliczenieList.js';
import MieszkaniecWplaty from './MieszkaniecWplaty.js';
import MieszkaniecSaldo from './MieszkaniecSaldo.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

const MieszkaniecRozliczenie = props => {

    const { activeGroup, tablicaCzynsze, tablicaPrzelewy, stawki_w, stawkiJednorazowe_w, stawkiJednorazowe_msc, mieszkaniec, udzialyKonta_a, lokale, ulice, grupy_a } = props;
    
    return (
        <motion.div 
            className="rozliczeniaContainer ramka-br"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h3>
                Rozliczenia miesięczne
            </h3>
            <MieszkaniecStawki 
                stawki_w={stawki_w}
                stawkiJednorazowe_msc={stawkiJednorazowe_msc}
                tablicaCzynsze={tablicaCzynsze}
                mieszkaniec={mieszkaniec}
                udzialyKonta_a={udzialyKonta_a}
                ulice={ulice}
                lokale={lokale}
                grupy_a={grupy_a}
            />
            <MieszkaniecSaldo 
                tablicaCzynsze={tablicaCzynsze}
                tablicaPrzelewy={tablicaPrzelewy}
            />
            <div className='tabele'>
                <MieszkaniecRozliczenieList 
                    activeGroup={activeGroup} 
                    tablicaCzynsze={tablicaCzynsze}
                />  
                <MieszkaniecWplaty 
                    tablicaPrzelewy={tablicaPrzelewy}
                />
            </div>
        </motion.div>
    )
}

export default MieszkaniecRozliczenie;