import React, { useContext }  from 'react';
import { AuthContext } from '../Context/AuthProvider';

const Zalogowany = () => {


    const {user, role } = useContext(AuthContext);

    return (
        <>
            <div>
                Zalogowany 
                <p>User: {user}</p>
                <p>Role: {role}</p>
            </div>
        </>
    )
}

export default Zalogowany;