import React, {  } from "react";
import '../scss/style.css';
import '../scss/dialog.css';
import { motion } from 'framer-motion';
import { wstepy } from '../functions/motions';

const DialogYesNo = ( props ) => {

    const { open, setOpen, message, f, id } = props;

    const onConfirm = () => {
        f(id);
        setOpen(false);
    }
    
    const onCancel = () => {
        setOpen(false);
    }

    return (
        <motion.div 
            className= { open ? "dialog block" : "dialog none" } 
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <div 
                className="window"
            >
                <h3>{message}</h3>
                <div
                    className="buttons"    
                >
                    <button
                        onClick={onConfirm}
                    >
                        Tak
                    </button>
                    <button
                        onClick={onCancel}
                    >
                        Nie
                    </button>
                </div>
            </div>
        </motion.div>
    )
}

export default DialogYesNo;