import React from 'react';



const MieszkancyUliceBox = props => {

    const { ulice_w, ulica, setUlica, setMieszkaniec } = props;

    const ulice = ulice_w?.map( (u, i) => {

        return (
            <div 
                className= {u.id == ulica?.id ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' } 
                onClick={()=>setUlica(u)}
                key={i}
            >
                {`${u.nazwa} ${u.numer_budynku}`}
            </div>
        )
    })

    return (
        <div className='mieszkancyUlice'>
            <h5>
                Wybierz ulicę
            </h5>
            <div className='box'>
                {ulice}
                <div 
                    className= {ulica?.id == 0 ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' } 
                    id={0}
                    onClick={()=>{
                        setUlica({id: 0, wszystkie: true});
                        setMieszkaniec(0)}
                    }
                >
                    Wszyscy
                </div>
                <div 
                    className= {ulica?.id == -1 ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' } 
                    id={-1}
                    onClick={()=>{
                        setUlica({id: -1, bezGrupy: true});
                        setMieszkaniec(0);
                    }}
                >
                    Bez grupy czynszowej
                </div>
            </div>

            
        </div>
    )
}

export default MieszkancyUliceBox;