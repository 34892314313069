import React, { useState, useEffect } from 'react';

const MieszkancyDaneEdytujGrupeLokale = (props) => {
    
    const { wybraneLokale, ulice, lokale, edytowaneGrupy, edytowanyUdzialKonto, handleLokalToggle, rodzajLokalu } = props;
    
    const [zajeteLokaleRodzajowe, setZajeteLokaleRodzajowe] = useState([]);
    const [wolneLokaleRodzajowe, setWolneLokaleRodzajowe] = useState([]);
    const [wszystkieLokaleRodzajowe, setWszystkieLokaleRodzajowe] = useState([]);

    useEffect( () => {
        const temp = [];
        edytowaneGrupy?.forEach( e => {
            const lokalTemp = lokale?.find( l => l.id == e.id_lokal && l.rodzaj === rodzajLokalu );
            if (lokalTemp && lokalTemp !== undefined)
                temp.push({wlasne: 1, ...lokalTemp});
        })
        setZajeteLokaleRodzajowe( temp )
        setWolneLokaleRodzajowe( lokale?.filter( l => l?.id_wspolnoty == edytowanyUdzialKonto?.id_wspolnoty && l.rodzaj === rodzajLokalu && l.czy_zajety == null) );
    },[lokale, edytowaneGrupy]);

    useEffect( () => {
        setWszystkieLokaleRodzajowe( [...zajeteLokaleRodzajowe, ...wolneLokaleRodzajowe] )
    },[zajeteLokaleRodzajowe,wolneLokaleRodzajowe])

    const box = wszystkieLokaleRodzajowe?.map( ( lokalRodzajowy, i ) => {
        const ulica = ulice?.filter( ulica => ulica?.id == lokalRodzajowy?.ulica)[0];
        const czyWybrany = wybraneLokale?.filter( lokal => lokal.id == lokalRodzajowy?.id)?.length > 0 ? 1 : 0;

        return (
            <div key={i} className='checkbox'> 
                <input 
                    type="checkbox" 
                    id={'g_' + lokalRodzajowy?.id} 
                    checked={czyWybrany}
                    onChange={ () => handleLokalToggle(lokalRodzajowy)}
                /> 
                <label 
                    htmlFor={'g_' + lokalRodzajowy?.id}
                >
                    { `id: ${lokalRodzajowy?.id}, ${ulica?.nazwa} ${ulica?.numer_budynku}, lok. ${lokalRodzajowy?.numer} (${lokalRodzajowy?.metraz} m²)` }
                </label> 
            </div>
        )
    })

    const dodaj = () => {
        switch (rodzajLokalu) {
            case "garaż":
                return "garaże";
            case "komórka":
                return "komórki";
            case "strych":
                return "strychy";
            default:
                return "";
        }        
    }

    return (
        <>
            {box && box?.length ? (
                <div>
                    <h5>Dodaj {dodaj()}:</h5>
                    <div className='listaBoxy ramka'>
                        { box }
                    </div>
                </div>

            ) : (
                <></>
            )}
        </>
    )
}

export default MieszkancyDaneEdytujGrupeLokale;