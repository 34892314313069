import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

function SelectF(props) {
    const { label, name, options, f, ...rest} = props;
    
    return (    
        <div className='form-control'>
            <label htmlFor={name} className='select' >{label}</label>
            <Field 
                as='select' 
                id={name} 
                name={name} 
                onChange={ (e)=> {
                    f(e.currentTarget.value);
                }}>
                {options?.map(option => {
                    return (
                        <option 
                            key={option.value}
                            value={option.value}
                        >
                            {option.key}
                        </option>
                    )
                })}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default SelectF;

// onChange={(e)=> {
//     f === undefined ? "" : f(e.currentTarget.value);
// }}>