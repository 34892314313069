const passwordGenerator = () => {
    
    const lenght = 8;
    const numberArray = [1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9];
    const symbolsArray = ["!","@","#","$","%","&","*","(",")","?","=","+"];

    const lowerCaseLetters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','r','t','u','w','x','v','z']

    const upperCaseLetters = lowerCaseLetters.map(letter => letter.toUpperCase());
    
    // const availbleCharacters = [...upperCaseLetters,...lowerCaseLetters,...numberArray,...symbolsArray];

    const availbleCharacters = [...numberArray];
    
    const shuffleArray = array => array.sort(()=>Math.random() -0.5);
    const characters = shuffleArray(availbleCharacters).slice(0,lenght);

    const password = characters.join('');

    return password;
}

export default passwordGenerator;