import React from 'react';


const FiltrWspolnoty = (props) => {

    const {wspolnoty, wybranaWspolnotaId, setWybranaWpolnotaId, setWybranyMieszkaniecId} = props;


    const handleChange = e => {
        setWybranaWpolnotaId(e.target.value)
        setWybranyMieszkaniecId(0)
    }

    const lista = wspolnoty?.map( (w, i) => (
        <option key={i} value={w.id}>
            {w.name}
        </option>
    ))

    return (
        <select value={wybranaWspolnotaId} onChange={handleChange}> 
            {lista}
        </select>
    )
}

export default FiltrWspolnoty;