import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../Context/AuthProvider';
import Page from './Page';
import { useNavigate  } from 'react-router-dom';
import axios from "axios";
import '../scss/style.css';

const Main = () =>{

    const { setAuth, setRole, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(()=>{
        axios.get('/isLogged')
          .then( res => {
            if (res.data.valid) {
              setAuth(true);
              setUser(res.data.user);
              setRole(res.data.role);
            } else {
              navigate('/login');
            }
          })
          // .catch(err=>{console.log(err)})  
    }, [])

    return (
        <main>
            {<Page />}
        </main>
    )
}

export default Main;