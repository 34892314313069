import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import FormikControl from "../../Formik/FormikControl";
import '../../../scss/style.css';
import '../../../scss/form.css';
import GoBackBtn from "../../GoBackBtn";
import CheckBox from '../../Formik/CheckBox';

const initialValues = {
    kontrahent: '',
    tytul: '',
    data_wystawienia: '',
    termin: '',
    id_wspolnoty: '',
    pozycje: [''],
    czy_zaplacona: false

}

const validationSchema = Yup.object({

})



const FakturyAddForm = () => {

    const [kontrahenci, setKontrahenci] = useState("");
    const [wspolnoty, setWspolnoty] = useState("");
    const [ileFaktur, setIleFaktur] = useState("");
    
    const addData = async (values) => {
      console.log(values.name)

      let suma = 0;
      
      if (values.pozycje[0].kwota !== undefined) {
        values.pozycje.forEach( el => {
          suma += parseFloat(el.kwota);
      })
      }

      try {
        axios.post('/api/dodaj_fakture_naglowek', {
          kontrahent: values.kontrahent,
          tytul: values.tytul,
          data_wystawienia: values.data_wystawienia,
          termin: values.termin,
          id_wspolnoty: values.id_wspolnoty,
          czy_zaplacona: values.czy_zaplacona,
          kwota: suma,
        })
      } catch (error) {
        console.error('Błąd podczas dodawania danych:', error);
      }
      try {
          const podpozycje = values.pozycje.map( el => ({
              ...el,
              id: noweIdFaktury,                
          }));
          podpozycje.forEach(element => {
              axios.post('/api/dodaj_fakture_podpozycja', {
                  id_fv: noweIdFaktury,
                  pozycja: element.nazwa_pozycji,
                  kwota_netto: element.kwota_netto,
                  kwota_vat: element.kwota_vat
                })
          });
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
    };

    useEffect(()=>{
        axios.get("/api/kontrahenci_nazwy")
          .then(response => {
            setKontrahenci( () => {
              return {
                kontrahenci: response,
              }
            })
          })
        axios.get("/api/lista_wspolnot")
          .then(response => {
            setWspolnoty( () => {
              return {
                wspolnoty: response,
              }
            })
          })
        axios.get("/api/fakturyNaglowek")
          .then(response => {
            setIleFaktur( () => {
                  return {
                    ileFaktur: response,
                  }
              })
          })
    },[])

    const onSubmit = ( values, onSubmitProps) => {
        // console.log('Form data:', values);
        // console.log('Submit props:', onSubmitProps);
        
        addData(values);
    
        // onSubmitProps.setSubmitting(false);
        // onSubmitProps.resetForm();
        };
    


    // console.log(kontrahenci?.kontrahenci?.data);
    // const dropdownOptions = "";
    
    

    const dropdownOptions1 = kontrahenci?.kontrahenci?.data?.map(({ id, nazwa }) => ({ value: id, key: nazwa }));
    dropdownOptions1?.unshift({value: -1, key: "Wybierz"});

    const dropdownOptions2 = wspolnoty?.wspolnoty?.data?.map(({id, name}) => ({ value: id, key: name}));
    dropdownOptions2?.unshift({value: -1, key: "Wybierz"});

    const noweIdFaktury = ileFaktur?.ileFaktur?.data.length + 1

    return (
        <div className="content">
            <h1 className="nazwa">
                Dodaj fakturę
            </h1>
            <div className="AddForm">
                <GoBackBtn />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount>
                    {formik => {
                        return (
                            <Form>
                                <FormikControl control='select' label="Kontrahent:" name='kontrahent' options={dropdownOptions1} /> 
                                {/* Konto: { numer_konta } */}
                                <FormikControl control='select' label="Wspólnota:" name='id_wspolnoty' options={dropdownOptions2} /> 
                                <FormikControl control='input' type='text' label='Tytuł' name='tytul' />
                                <FormikControl control='date' type='date' label="Data wystawienia:" name='data_wystawienia' />  
                                <FormikControl control='date' type='date' label="Termin płatności:" name='termin' />  
                                
                                
                                <FieldArray name='pozycje'>
                                    {
                                        (fieldArrayProps) => {
                                            // console.log('fieldArrayProps', fieldArrayProps);
                                            const {push, remove, form} = fieldArrayProps;
                                            const { values } = form;
                                            const { pozycje } = values;
                                            return (
                                                <div className="pozycje ">
                                                    {pozycje.map(( pozycja,i ) => (
                                                        <div key={i}>
                                                            <div className='wiersz'>

                                                              <FormikControl control='input' type='text' name={`pozycje[${i}].nazwa_pozycji`} label="Nazwa towaru/usługi:" className="xx"/>
                                                              <FormikControl control='input' type='number' name={`pozycje[${i}].kwota`} label={"Kwota:"} className='kwota' />
                                                                {i > 0 && (
                                                                  <button type='button' onClick={ () => remove(i)}>
                                                                      {' '}-{' '} 
                                                                  </button>
                                                                )}
                                                              <button type='button' onClick={ () => push('')}> + </button>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                    }}
                                </FieldArray>
                                Czy zapłacone:{' '}<CheckBox constrol='input' type='checkbox' name='czy_zaplacona'/>
                                {/* {console.log(fieldArrayProps)} */}



                                {/* <FormikControl control='input' type='text' label='E-mail:' name='email' />   */}
                                <button type='submit' disabled={!formik.isValid || formik.isSubmitting}>Zapisz</button>
                                {/* <button type="reset">Wyczyść</button> */}
                            </Form>
                            )
                    }}
                </Formik>
            </div>       
        </div>
    )

};

export default FakturyAddForm;