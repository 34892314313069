import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from "../../Formik/FormikControl";
import axios from "axios";
import '../../../scss/style.css';
import '../../../scss/form.css';
import GoBackBtn from "../../GoBackBtn";
import CheckBox from '../../Formik/CheckBox';

const initialValues = {
    rok_obrotowy: '2023',
    id_wspolnoty: 0,
    numer_konta: '',
    nazwa: '',
    bilansowe: 'false',
    wynikowe: 'false',
    pozabilansowe: 'false',
    rozrachunkowe: 'false',
    kartoteka: '',
}

const addData = async (values) => {
    console.log(values.name)
    try {
      // Wysyłamy dane na serwer
      await axios.post('/api/konta/dodaj', {
        rok_obrotowy: values.rok_obrotowy,
        numer_konta: values.numer_konta,
        nazwa: values.nazwa,
        bilansowe: values.bilansowe,
        wynikowe: values.wynikowe,
        pozabilansowe: values.pozabilansowe,
        rozrachunkowe: values.rozrachunkowe,
        kartoteka: values.kartoteka,
      });
      // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
    } catch (error) {
      console.error('Błąd podczas dodawania danych:', error);
    }
};

const onSubmit = ( values, onSubmitProps) => {
    console.log('Form data:', values);
    console.log('Submit props:', onSubmitProps);
    
    // addData(values);

    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
}

const KontaAddForm = () => {

    const [ konta, setKonta ] = useState([]);
    const [savedValues, setSavedValues] = useState({})
    const [wspolnoty, setWspolnoty] = useState({})
    

    useEffect(()=>{
        axios.get("/api/konta")
          .then(response => {
            setKonta( () => {
              return {
                konta: response
              }
            })
          })
        axios.get("/api/lista_wspolnot")
          .then(response => {
            setWspolnoty( () => {
              return {
                wspolnoty: response
              }
            })
          })
    },[])

    const kontaArr = konta?.konta?.data;

    const odbiorcy = kontaArr?.filter( el => el.kartoteka === "Odbiorcy" && el.rok_obrotowy === 2023);
    const dostawcy = kontaArr?.filter( el => el.kartoteka === "Dostawcy" && el.rok_obrotowy === 2023);
    
    const nastOdbiorcy = `200-${String(odbiorcy?.length + 1).padStart(5, '0')}`;
    const nastDostawcy = `210-${String(dostawcy?.length + 1).padStart(5, '0')}`;

    const wspolnotyArr = wspolnoty?.wspolnoty?.data;

    
    const dropdownOptions = [
        { key: 'Wybierz wariant', value: ''},
        { key: 'Brak', value: '1'},
        { key: '200 Odbiorcy', value: '2'},
        { key: '210 Dostawcy', value: '3'},
    ]

    const dropdownOptionsWspolnoty = wspolnotyArr?.map( (el, i) => {
        return ({
            key: el.name,
            value: el.id,
        })
    })

    dropdownOptionsWspolnoty?.unshift({key: "Wybierz:", value: 0});

    const zmiana = (i) => {
        if (i === '2') {
            setSavedValues(prevObject => ({
                ...prevObject,
                numer_konta: nastOdbiorcy
            }));
        }
        if (i === '3') {
            setSavedValues(prevObject => ({
                ...prevObject,
                numer_konta: nastDostawcy
            }));
        }
    }
    
    const wyborWspolnoty = (w) => {
        console.log(w)
        setSavedValues(prevObject => ({
            ...prevObject,
            id_wspolnoty: w
        }))
    }





    return (
        <div className="content">
            <h1 className="nazwa">
                Dodaj konto
            </h1>
            <div className="AddForm">
                <GoBackBtn />

                <Formik
                    initialValues={savedValues || initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount
                    enableReinitialize>
                    {formik => {
                        return (
                            <Form>
                                <FormikControl control='input' type='text' label='Rok obrotowy:' name='rok_obrotowy' />    
                                <FormikControl control='selectF' label='Wspólnota:' name='id_wspolnoty' f={wyborWspolnoty} options={dropdownOptionsWspolnoty} /> 
                                <FormikControl control='selectF' label='Kartoteka:' name='kartoteka' f={zmiana} options={dropdownOptions} />
                                <FormikControl control='input' type='text' label='Numer konta:' name='numer_konta' />    
                                
                                <FormikControl control='input' type='text' label='Nazwa:' name='nazwa' />   
                                <CheckBox constrol='input' type='checkbox' label='Czy bilansowe:' name='bilansowe'/>
                                <CheckBox constrol='input' type='checkbox' label='Czy wynikowe:' name='wynikowe'/>
                                <CheckBox constrol='input' type='checkbox' label='Czy pozabilansowe:' name='pozabilansowe'/>
                                <CheckBox constrol='input' type='checkbox' label='Czy rozrachnkowe:' name='rozrachunkowe'/>
                                 
                                <button type='submit' disabled={!formik.isValid || formik.isSubmitting}>Zapisz</button>
                                <button type="reset">Wyczyść</button>
                            </Form>
                            )
                    }}
                </Formik>
            </div>       
        </div>
    )

};

export default KontaAddForm;