import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';
export const ikona = (rodzaj) => {
    switch (rodzaj){
        case "mieszkanie": 
            return (<FontAwesomeIcon icon={faHouse} />)
        case "garaż":
            return (<FontAwesomeIcon icon={faWarehouse} />);
        case "komórka":
            return (<FontAwesomeIcon icon={faDoorClosed} />);
        case "strych":
            return (<FontAwesomeIcon icon={faAccusoft} />);
        default:
            return "";
    }
}