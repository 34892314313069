import React, {useEffect, useContext} from "react";
import axios from "axios";
import scrollToTop from '../../functions/ScrollToTop';
import '../../scss/style.css';
import '../../scss/polityka.css';
import { AuthContext} from '../../Context/AuthProvider';


const PolitykaPrywatnosci = () => {
    useEffect( ()=> {
        scrollToTop();
    },[])

    const { userId } = useContext(AuthContext);
    useEffect( () => {
        const id_temp = userId ? userId : 0;
        axios.post("/api/log", { id_user: id_temp, akcja: "Polityka prywatności" })
    },[])

    return (
        <div className="polityka">
            <h1>
                Polityka prywatności
            </h1>
                <p>Cześć, dzień dobry!</p>
                <p>
                    Jeżeli tutaj trafiłeś, to niezawodny znak, że cenisz swoją prywatność. Doskonale to rozumiemy, dlatego oddajemy w Twoje ręce 
                    dokument, w którym w jednym miejscu znajdziesz zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies i innych
                    technologii śledzących w związku z funkcjonowaniem serwisu internetowego https://bonadministrator.pl/
                </p>
                <p>Informacja formalna na początek – administratorem serwisu jest BIURO OBSŁUGI NIERUCHOMOŚCI ALEKSANDRA MOJSIEWICZ & KATARZYNA STANEK 
                    SPÓŁKA CYWILNA, ul. Mieszka I 57/6, 66-400 Gorzów Wielkopolski, NIP: 5993279368, REGON: 526531300.
                </p>
                <p>
                    Niniejsza polityka prywatności została skonstruowana w formie pytań i odpowiedzi. Wybór takiej formy został podyktowany dbałością o przejrzystość i czytelność prezentowanych Ci informacji.
                </p>
                <p>
                    W razie jakichkolwiek wątpliwości związanych z polityką prywatności, w każdej chwili możesz skontaktować się z nami, wysyłając wiadomość na adres biuro@bonadministrator.pl.
                </p>
                <h2>
                    # 1: Kto jest administratorem Twoich danych osobowych?
                </h2>
                <p>
                    Administratorem Twoich danych osobowych jest BIURO OBSŁUGI NIERUCHOMOŚCI ALEKSANDRA MOJSIEWICZ & KATARZYNA STANEK SPÓŁKA CYWILNA, 
                    ul. Mieszka I 57/6, 66-400 Gorzów Wielkopolski, NIP: 5993279368, REGON: 526531300.
                </p>
            <h2>
                # 2: Z kim możesz kontaktować się w sprawie przetwarzania Twoich danych osobowych?
            </h2>
                <p>
                    W ramach wdrożenia ochrony danych osobowych w naszej organizacji, podjęliśmy decyzję o niewyznaczaniu inspektora ochrony danych osobowych z uwagi na fakt, że w naszej sytuacji 
                    nie jest to obowiązkowe. W sprawach związanych z ochroną danych osobowych oraz szeroko pojętą prywatnością możesz kontaktować się pod adresem e-mail biuro@bonadministrator.pl.
                </p>
                <h2>
                    # 3: Jakie informacje na Twój temat posiadamy?
                </h2>
                <p>
                    W zależności od celu, możemy przetwarzać następujące informacje na Twój temat:
                    <ul>
                        <li>imię i nazwisko</li>

                        <li>adres e-mail</li>

                        <li>dane zawarte w korespondencji e-mail</li>

                        <li>adres zamieszkania</li>

                        <li>adres wykonywania działalności gospodarczej</li>

                        <li>numer NIP</li>

                        <li>numer telefonu</li>

                        <li>numer rachunku bankowego</li>

                        <li>adres IP</li>

                        <li>statystyki związane z otrzymywanymi newsletterami</li>
                    </ul>
                    Powyżej wymieniliśmy konkretne informacje. Natomiast w załączniku nr 1 do niniejszej polityki prywatności odnajdziesz kategorie danych osobowych przyporządkowane do poszczególnych celów przetwarzania.
                </p>
            <h2>
                # 4: Skąd mamy Twoje dane osobowe?
            </h2>
                <p>
                    Twoje dane osobowe otrzymujemy bezpośrednio od mieszkańców Wspólnoty Mieszkaniowej.
                </p>
                <p>
                    W załączniku nr 1 do niniejszej polityki prywatności przyporządkowaliśmy źródło pozyskania danych do każdego z celów przetwarzania.
                </p>
                <p>
                    Ponadto, część informacji na Twój temat może być automatycznie gromadzona przez narzędzia, z których korzystamy:
                    <ul>
                        <li>
                            mechanizm strony gromadzi Twój adres IP
                        </li>
                        <li>
                            mechanizm systemu newsletterowego gromadzi Twój adres IP oraz informacje na temat Twojej aktywności w odniesieniu do treści przesyłanych Ci w ramach newslettera, takich jak otwarcia 
                            wiadomości, klikanie w linki itp.
                        </li>
                    </ul>
                </p>        
            <h2>
                # 5: Czy Twoje dane są bezpieczne?
            </h2>
                <p>
                    Dbamy o bezpieczeństwo Twoich danych osobowych. Przeanalizowaliśmy ryzyka, jakie wiążą się z poszczególnymi procesami przetwarzania Twoich danych, a następnie wdrożyliśmy 
                    odpowiednie środki bezpieczeństwa i ochrony danych osobowych. Na bieżąco monitorujemy stan infrastruktury technicznej, szkolimy personel, przyglądamy się stosowanym procedurom, 
                    wprowadzamy konieczne usprawnienia. W razie jakichkolwiek pytań dotyczących Twoich danych osobowych, jesteśmy do Twojej dyspozycji pod adresem biuro@bonadministrator.pl
                </p>
            <h2>
                # 6: W jakich celach przetwarzamy Twoje dane osobowe?
            </h2>
                <p>
                    Cele przetwarzania Twoich danych osobowych wymienione są w załączniku nr 1 do niniejszej polityki prywatności.
                </p>
            <h2>
                # 7: Jak długo będziemy przechowywać Twoje dane osobowe?
            </h2>
                <p>
                    Okresy przechowywania danych zostały wskazane odrębnie w stosunku do każdego celu przetwarzania. Odnajdziesz te informacje w załączniku nr 1 do niniejszej polityki prywatności. 
                    Większość danych usuwanych jest po upływie terminu przedawnienia roszczeń, przy czym okres przedawnienia roszczeń może się różnić w świetle obowiązujących przepisów prawa 
                    (np. w przypadku przedsiębiorców mogą to być 3 lata, a w przypadku konsumentów 6 lat).
                </p>
            <h2>
               # 8: Kim są odbiorcy Twoich danych osobowych?
            </h2>
                <p>
                    Zaryzykujemy stwierdzenie, że współczesny biznes nie jest w stanie obejść się bez usług świadczonych przez podmioty trzecie. My również z takich usług korzystamy. 
                    Część z tych usług wiąże się z przetwarzaniem Twoich danych osobowych. Zewnętrzni usługodawcy, którzy biorą udział w przetwarzaniu Twoich danych osobowych, to:
                    <ul>
                        <li>hostingodawca, który przechowuje dane na serwerze</li>
                        <li>
                            dostawcy oprogramowania chmurowego, w którym dochodzi do przetwarzania danych
                        </li>
                        <li>
                            pozostali podwykonawcy, którzy uzyskują dostęp do danych, jeżeli zakres ich działań wymaga takowego dostępu
                        </li>
                    </ul>
                </p>
                <p>
                    Wszystkie podmioty wymienione powyżej przetwarzają Twoje dane na podstawie zawartych z nami umów powierzenia przetwarzania danych osobowych i gwarantują odpowiedni poziom ochrony danych osobowych.
                </p>
                <p>
                    Jeżeli zajdzie taka potrzeba, Twoje dane mogą być udostępnione radcy prawnemu lub adwokatowi związanemu tajemnicą zawodową. Potrzeba może wynikać z konieczności skorzystania z pomocy prawnej 
                    wymagającej dostępu do Twoich danych osobowych.
                </p>
                <p>
                    Twoje dane osobowe mogą być również przekazywane do urzędów skarbowych w zakresie niezbędnym do realizacji obowiązków podatkowo-rozliczeniowo-księgowych. Chodzi w szczególności o wszelkie 
                    deklaracje, raporty, sprawozdania i inne dokumenty księgowe, w których znajdują się Twoje dane osobowe.
                </p>
                <p>
                    Co więcej, gdy chodzi o Informacje Anonimowe, dostęp do nich mają dostawcy narzędzi lub wtyczek, które gromadzą Informacje Anonimowe. Dostawcy tych narzędzi są samodzielnymi administratorami 
                    danych w nich gromadzonych i mogą udostępniać te dane na zasadach określonych przez siebie we własnych regulaminach i politykach prywatności, na co nie mam wpływu.
                </p>
            <h2>
                # 9: Czy przekazujemy Twoje dane do państw trzecich lub organizacji międzynarodowych?
            </h2>
                <p>
                    Nie, nie przekazujemy Twoich danych do państw trzecich ani organizacji międzynarodowych.
                </p>
            <h2>
                # 10: Czy korzystamy z profilowania? Czy podejmujemy w sposób zautomatyzowany decyzja na podstawie Twoich danych osobowych?
            </h2>
                <p>
                    Nie podejmujemy wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny 
                    sposób istotnie na Ciebie wpływały.
                </p>
                <p>
                    Owszem, korzystamy z narzędzi, które mogę podejmować określone działania w zależności od informacji zebranych w ramach mechanizmów śledzących, ale uważamy, że działania te nie mają istotnego 
                    wpływu na Ciebie, ponieważ nie różnicują Twojej sytuacji jako klienta, nie wpływają na warunki umowy, jaką możesz z nami zawrzeć itp.
                </p>
                <p>
                    Korzystając z określonych narzędzi, możemy np. kierować do Ciebie spersonalizowane reklamy w oparciu o wcześniejsze działania podjęte przez Ciebie w ramach strony czy też podpowiadać Ci produkty, 
                    które mogą Cię zainteresować. Mowa tutaj o tzw. reklamie behawioralnej. Zachęcamy Cię do pogłębienia wiedzy na temat reklamy behawioralnej, w szczególności w zakresie kwestii związanych z prywatnością. 
                    Szczegółowe informacje, wraz z możliwością zarządzania ustawieniami w zakresie reklamy behawioralnej, znajdziesz <a href="https://www.youronlinechoices.com/">tutaj</a>.
                </p>
                <p>
                    Podkreślamy, że w ramach narzędzi, z których korzystam mam dostęp wyłącznie do Informacji Anonimowych. Informacje te przechowywane są na serwerach dostawców poszczególnych narzędzi, 
                    a serwery te najczęściej mogą znajdować się na całym świecie.
                </p>
            <h2>
                # 11: Jakie uprawnienia przysługują Ci w związku z przetwarzaniem Twoich danych osobowych?
            </h2>
                <p>
                    RODO przyznaje Ci następujące potencjalne uprawnienia związane z przetwarzaniem Twoich danych osobowych:
                    <ul>
                        <li>prawo dostępu do swoich danych oraz otrzymania ich kopii,</li>
                        <li>prawo do sprostowania (poprawiania) swoich danych,</li>
                        <li>prawo do usunięcia danych (jeżeli Twoim zdaniem nie ma podstaw, abyśmy przetwarzali Twoje dane, możesz żądać, abyśmy je usunęli),</li>
                        <li>prawo do ograniczenia przetwarzania danych (możesz żądać, abyśmy ograniczyli przetwarzanie danych wyłącznie do ich przechowywania lub wykonywania uzgodnionych z Tobą działań, jeżeli w 
                            Twojej opinii mamy nieprawidłowe dane lub przetwarzamy je bezpodstawnie),</li>
                        <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych (masz prawo do sprzeciwu wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu; powinieneś wskazać szczególną 
                            sytuację, która Twoim zdaniem uzasadnia zaprzestanie przez nas przetwarzania objętego sprzeciwem; przestaniemy przetwarzać Twoje dane w tych celach, chyba że wykażemy, że podstawy 
                            przetwarzania przez nas danych są nadrzędne wobec Twoich praw lub też, że Twoje dane są nam niezbędne do ustalenia, dochodzenia lub obrony roszczeń),</li>
                        <li>prawo do przenoszenia danych (masz prawo otrzymać od nas w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego dane osobowe, które dostarczyłeś nam na 
                            podstawie umowy lub Twojej zgody; możesz nam zlecić przesłanie tych danych bezpośrednio innemu podmiotowi),</li>
                        <li>prawo do cofnięcia zgody na przetwarzanie danych osobowych, jeżeli uprzednio taką zgodę wyraziłeś,</li>
                        <li>prawo wniesienia skargi do organu nadzorczego (jeżeli stwierdzisz, że przetwarzamy dane niezgodnie z prawem, możesz złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych 
                            lub innego właściwego organu nadzorczego).</li>
                    </ul>
                </p>
                <p>
                    Zasady związane z realizacją wskazanych powyżej uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Ze swojej strony uważamy za potrzebne 
                    wyjaśnić Ci, że wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności przetwarzania Twoich danych osobowych.
                </p>
                <p>
                    Podkreślamy, że jedno z uprawnień wskazanych powyżej przysługuje Ci zawsze - jeżeli uznasz, że przy przetwarzaniu Twoich danych osobowych dopuściliśmy się naruszenia przepisów o ochronie danych 
                    osobowych, masz możliwość wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych).
                </p>
                <p>
                    Zawsze możesz również zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. Wystarczy, że wyślesz wiadomość 
                    na adres biuro@bonadministrator.pl. Dołożyliśmy jednak wszelkich starań, by interesujące Cię informacje zostały wyczerpująco przedstawione w niniejszej polityce prywatności. Podany powyżej 
                    adres e-mail możesz wykorzystać również w razie jakichkolwiek pytań związanych z przetwarzaniem Twoich danych osobowych.
                </p>
            <h2>
                # 12: Czy korzystamy z cookies i czym one właściwie są?
            </h2>
                <p>
                    Nasz serwis, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies.
                </p>
                <p>
                    Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane przez nasz system teleinformatyczny 
                    (cookies własne) lub systemy teleinformatyczne podmiotów trzecich (cookies podmiotów trzecich). W plikach cookies mogą być zapisywane i przechowywane określone informacje, do których następnie 
                    systemy teleinformatyczne mogą uzyskiwać dostęp w określonych celach.
                </p>
                <p>
                    Niektóre używane przez nas cookies są usuwane po zakończeniu sesji przeglądarki internetowej, tzn. po jej zamknięciu (tzw. cookies sesyjne). Inne cookies są zachowywane na Twoim urządzeniu 
                    końcowym i umożliwiają nam rozpoznanie Twojej przeglądarki przy kolejnym wejściu na stronę (trwałe cookies).
                    Jeżeli chcesz dowiedzieć się więcej o plikach cookies jako takich, możesz zapoznać się np. z tym materiałem: <a href="https://pl.wikipedia.org/wiki/HTTP_cookie">https://pl.wikipedia.org/wiki/HTTP_cookie</a>.
                </p>
            <h2>
                # 13: Na jakiej podstawie korzystamy z plików cookies?
            </h2>
                <p>
                    Z plików cookies korzystamy na podstawie Twojej zgody, z wyjątkiem sytuacji, gdy pliki cookies są niezbędne do prawidłowego świadczenia na Twoją rzecz usługi drogą elektroniczną.
                </p>
                <p>
                    Pliki cookies, które nie są niezbędne do prawidłowego świadczenia usługi drogą elektroniczną, pozostają zablokowane do czasu wyrażenia przez Ciebie zgody na korzystanie z plików cookies. 
                    Podczas Twojej pierwszej wizyty na stronie wyświetlamy Ci komunikat z pytaniem o Twoją zgodę.
                </p>
                <p>
                    Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji dostępnych na naszej stronie internetowej oraz powodować trudności w korzystaniu ze strony, 
                    jak również z wielu innych stron internetowych, które stosują cookies. Przykładowo, jeżeli zablokujesz pliki cookies wtyczek serwisów społecznościowych, przyciski, widgety oraz funkcje społecznościowe 
                    zaimplementowane na stronie mogą być dla Ciebie niedostępne.
                </p>
            <h2>
                # 14: Czy możesz wyłączyć pliki cookies?
            </h2>
                <p>
                    Tak, możesz zarządzać ustawieniami plików cookies w ramach swojej przeglądarki internetowej. Możesz blokować wszystkie lub wybrane pliki cookies. Możesz również blokować pliki cookies konkretnych witryn. 
                    W każdej chwili możesz również usunąć zapisane wcześniej pliki cookies oraz inne dane witryn i wtyczek.
                </p>
                <p>
                    Przeglądarki internetowe oferują również możliwość korzystania z trybu incognito. Możesz z niego skorzystać, jeśli nie chcesz, by informacje o odwiedzonych stronach i pobranych plikach zostały zapisane 
                    w historii przeglądania i pobierania. Pliki cookie utworzone w trybie incognito są usuwane w momencie zamknięcia wszystkich okien tego trybu.
                </p>
                <p>
                    Dostępne są również wtyczki do przeglądarek umożliwiające kontrolę nad plikami cookies, takie jak np. Ghostery (<a href="https://www.ghostery.com">https://www.ghostery.com</a>). Opcję kontroli nad plikami cookies może zapewniać również 
                    dodatkowe oprogramowanie, w szczególności pakiety antywirusowe itp.
                </p>
                <p>
                    Ponadto, w Internecie dostępne są narzędzia pozwalające na kontrolę nad niektórymi rodzajami plików cookies, w szczególności na zbiorowe zarządzanie ustawieniami reklamy behawioralnej 
                    (np. <a href="www.youronlinechoices.com/">www.youronlinechoices.com/</a>, <a href="www.networkadvertising.org/choices">www.networkadvertising.org/choices</a>).
                </p>
                <p>
                    Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji dostępnych w naszym serwisie oraz powodować trudności w korzystaniu z naszego serwisu, 
                    jak również z wielu innych stron internetowych, które stosują cookies. Przykładowo, jeżeli zablokujesz pliki cookies wtyczek serwisów społecznościowych, przyciski, widgety oraz funkcje 
                    społecznościowe zaimplementowane w naszym serwisie mogą być dla Ciebie niedostępne.
                </p>
            <h2>
               # 15: W jakich celach korzystamy z plików cookies własnych?
            </h2>
                <p>
                    Pliki cookies własne wykorzystywane są do zapewnienia prawidłowego funkcjonowania poszczególnych mechanizmów strony, takich jak prawidłowe przesyłanie formularzy widocznych na stronie.
                </p>
                <p>
                    W plikach cookies własnych przechowywana jest również informacja o wyrażonej przez Ciebie zgodzie na cookies.
                </p>
            <h2>
                # 16: Jakie pliki cookies podmiotów trzecich są wykorzystywane?
            </h2>
                <p>
                    W ramach naszej strony internetowej mogą funkcjonować pliki cookies podmiotów trzecich związane z narzędziami wymienionymi w załączniku nr 2 do niniejszej polityki prywatności. Poszczególne 
                    narzędzia mogą wykorzystywać więcej niż jeden plik cookies, ale odstąpiliśmy od ich szczegółowego listowania, nie chcąc przytłaczać Cię nadmiarem informacji i wychodząc z założenia, że 
                    bardziej czytelne będzie dla Ciebie posługiwanie się listą narzędzi wraz z określeniem celów korzystania z tych narzędzi niż wymienianie wszystkich technicznie funkcjonujących w obrębie 
                    poszczególnych narzędzi plików cookies. 
                </p>
                <p>
                    Odstąpiliśmy również od wskazywania zakresu gromadzonych w plikach cookies informacji w odniesieniu do każdego wykorzystywanego narzędzia, biorąc pod uwagę, że każde narzędzie gromadzi 
                    informacje związane z Twoją charakterystyką i zachowaniami podejmowanymi w ramach naszej strony internetowej. W tym zakresie mamy do czynienia z Informacjami Anonimowymi, o których wspominaliśmy 
                    wcześniej w ramach niniejszej polityki prywatności.
                </p>
                <p>
                    Znając wymogi kreowane przez Trybunał Sprawiedliwości Unii Europejskiej, odstąpiliśmy mimo wszystko od wskazywania żywotności plików cookies wykorzystywanych przez poszczególne narzędzia. 
                    Chcąc określić rzetelnie żywotność każdego pliku cookies musielibyśmy przytłoczyć Cię nadmiarem informacji, który w jakikolwiek sposób nie realizowałby zasady przejrzystości i czytelności 
                    kierowanych do Ciebie informacji. Ponadto, żywotność plików cookies może podlegać na tyle aktywnym zmianom po stronie dostawców narzędzi, że nie jesteśmy w stanie sprawować nad tym rzetelnej 
                    kontroli. Odstępując od określania żywotności cookies, przypominamy, że jedyną opcją realnej kontroli czasu przechowywania informacji w plikach cookies jest zarządzanie plikami cookies bezpośrednio 
                    przez Ciebie. Z poziomu swojej przeglądarki internetowej w dowolnej chwili możesz usuwać pliki cookies przechowywane na Twoim urządzeniu.
                </p>
                <p>
                    W tym miejscu jeszcze raz chcemy podkreślić, że w ramach plików cookies nie mamy dostępu do informacji, które pozwalałaby nam na Twoją identyfikację. W tym zakresie mamy do czynienia z 
                    Informacjami Anonimowymi, o których wspominaliśmy w ramach niniejszej polityki prywatności.
                </p>
                <p>
                    Co więcej, o ile w przypadku narzędzi statystycznych i marketingowych możemy przeglądać różnego rodzaju raporty generowane na podstawie Informacji Anonimowych, o tyle w odniesieniu do 
                    pozostałych narzędzi nie uzyskujemy nawet jakiegokolwiek dostępu do gromadzonych w plikach cookies informacji, będąc zainteresowanymi wyłącznie tym czy funkcje danego narzędzia, dla których 
                    narzędzie zostało zainstalowane, działają prawidłowo w ramach naszej strony.
                </p>
            <h2>
                # 17: Czy śledzimy Twoje zachowania podejmowane w ramach naszego serwisu?
            </h2>
                <p>
                    Tak, korzystamy z narzędzi, które wiążą się z gromadzeniem informacji na temat Twoich aktywności na naszej stronie. Narzędzia te zostały wymienione w załączniku nr 2 do niniejszej polityki prywatności.
                </p>
            <h2>
                # 18: Czy kierujemy do Ciebie targetowane reklamy?
            </h2>
                <p>
                    Nie.
                </p>
            <h2>
                § 19: W jaki sposób możesz zarządzać swoją prywatnością?
            </h2>
                <p>
                    Odpowiedź na to pytanie znajduje się w wielu miejscach tej polityki prywatności przy okazji opisywania poszczególnych narzędzi, reklamy behawioralnej, zgody na cookies itp. Niemniej jednak, 
                    dla Twojej wygody zebraliśmy jeszcze raz te informacji w jednym miejscu. Poniżej znajdziesz listę możliwości zarządzania swoją prywatnością.
                    <ul>
                        <li>ustawienia cookies w ramach przeglądarki internetowej</li>
                        <li>wtyczki do przeglądarek wspierające zarządzanie plikami cookies np. Ghostery</li>
                        <li>dodatkowe oprogramowanie zarządzające plikami cookies,</li>
                        <li>tryb incognito w przeglądarce internetowej</li>
                        <li>ustawienia reklamy behawioralnej, np. youronlinechoices.com</li>
                        <li>ustawienia po stronie dostawców narzędzi zewnętrznych (linki do ustawień zostały zebrane w ramach załącznika nr 2 do niniejszej polityki prywatności)</li>
                    </ul>
                </p>
            <h2>
                # 20: Czym są logi serwera?
            </h2>
                <p>
                    Korzystanie z serwisu wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera.
                </p>
                <p>
                    Logi obejmują m.in. Twój adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym z jakiego korzystasz. Logi zapisywane i przechowywane są na serwerze.
                </p>
                <p>
                    Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane przez nas w celu Twojej identyfikacji.
                </p>
                <p>
                    Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.
                </p>
            <h2>
                # 21: Czy jest jeszcze coś, o czym powinieneś wiedzieć?
            </h2>
                <p>
                    Jak widzisz, tematyka przetwarzania danych osobowych, wykorzystywania plików cookies i zarządzania ogólnie pojętą prywatnością jest dość skomplikowana. Dołożyliśmy wszelkich starań by 
                    niniejszy dokument dostarczył Ci jak najdalej idącej wiedzy w ważnych dla Ciebie kwestiach. Jeżeli cokolwiek jest dla Ciebie niejasne, chcesz dowiedzieć się więcej lub po prostu porozmawiać 
                    o Twojej prywatności, napisz do nas na adres biuro@bonadministrator.pl.
                </p>
            <h2>
                # 22: Czy ta polityka prywatności może podlegać zmianom?
            </h2>
                <p>
                    Tak, możemy modyfikować tę politykę prywatności, w szczególności ze względu na zmiany technologiczne po stronie naszego serwisu oraz zmiany przepisów prawa. Jeżeli jesteś zarejestrowanym 
                    użytkownikiem serwisu, o każdej zmianie polityki prywatności otrzymasz wiadomość.
                </p>
                <p>
                    Niniejsza treść Polityki Prywatności obowiązuje od 2024-01-01
                </p>
            <h2>
                Załącznik nr 1 - cele przetwarzania danych osobowych
            </h2>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Cel przetwarzania
                            </th>
                            <th>
                                Podstawa prawna przetwarzania
                            </th>
                            <th>
                                Kategorie przetwarzanych danych
                            </th>
                            <th>
                                Okres przechowywania danych
                            </th>
                            <th>
                                Źródło pozyskania danych
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Obsługa konta użytkownika
                            </td>
                            <td>
                                Art. 6 ust. 1 lit. b RODO – zawarcie i wykonanie umowy o świadczenie usługi drogą elektroniczną w postaci konta użytkownika
                            </td>
                            <td>
                                Dane identyfikacyjne. Dane kontaktowe.
                            </td>
                            <td>
                                Do czasu upływu terminu przedawnienia roszczeń związanych z umową o świadczenie usługi drogą elektroniczną (z uwagi na odrębny cel archiwizacyjny wymieniony poniżej).
                            </td>
                            <td>
                                Formularz rejestracji konta użytkownika.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa zamówienia
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. b RODO – zawarcie i wykonanie umowy zawieranej w wyniku złożenia zamówienia.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe.Szczegóły zamówienia.
                            </td>
                            <td>
                            Do czasu upływu terminu przedawnienia roszczeń związanych z umową zawartą w wyniku złożenia zamówienia (z uwagi na odrębny cel archiwizacyjny wymieniony poniżej).
                            </td>
                            <td>
                            Formularz zamówienia.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa reklamacji
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na obsłudze procedury reklamacyjnej.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe. Szczegóły reklamacji.
                            </td>
                            <td>
                            Do czasu upływu terminu przedawnienia roszczeń związanych z reklamacją.
                            </td>
                            <td>
                            Reklamacja.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa odstąpienia od umowy
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na obsłudze procedury odstąpienia od umowy.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe.
                            </td>
                            <td>
                            Do czasu upływu terminu, przez jaki przepisy prawa nakazują przechowywać dokumentację księgową (oświadczenie o odstąpieniu od umowy jest do niej włączane).
                            </td>
                            <td>
                            Oświadczenie o odstąpieniu od umowy.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Księgowość	
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. c w zw. z właściwymi przepisami prawa podatkowego – realizacja obowiązków podatkowych.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe. Szczegóły faktur.
                            </td>
                            <td>
                            Do czasu upływu terminu, przez jaki przepisy prawa nakazują przechowywać dokumentację księgową.
                            </td>
                            <td>
                            Formularz zamówienia lub formularz odstąpienia od umowy.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Archiwum
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na zabezpieczeniu danych na potrzeby ustalenia, obrony lub dochodzenia 
                            roszczeń oraz na potrzeby wykazania przestrzegania obowiązków wynikających z RODO.
                            </td>
                            <td>
                            Dane o różnym zakresie, w zależności od tego, jakie dane do nas trafiły i jaki zakres danych uzasadniony jest pod kątem archiwalnym.
                            </td>
                            <td>
                            Do czasu upływu terminu przedawnienia roszczeń lub przedawnienia naszej odpowiedzialności związanej z ochroną danych osobowych.
                            </td>
                            <td>
                            Wszystkie formularze służące do przekazywania danych.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa newslettera
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na wysyłce wiadomości po odebraniu uprzedniej zgody na otrzymywanie newslettera.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe. Informacje statystyczne związane z wysyłanymi wiadomościami.
                            </td>
                            <td>
                            Do czasu upływu terminu przedawnienia roszczeń związanych z wysyłaniem newslettera lub przedawnienia naszej odpowiedzialności związanej z ochroną danych osobowych.
                            </td>
                            <td>
                            Formularz zapisu do newslettera.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa korespondencji
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na wymianie korespondencji z użytkownikiem oraz jej ewentualnej archiwizacji.
                            </td>
                            <td>
                            Dane identyfikacyjne. Dane kontaktowe. Szczegóły korespondencji.
                            </td>
                            <td>
                            Niemożliwy do jednoznacznego ustalenia. Część korespondencji może podlegać bieżącemu usuwaniu, a część archiwizacji, jeżeli uznamy, że istnieje potrzeba jej przechowywania, w 
                            szczególności z uwagi na zapewnienie możliwości odtworzenia jej przebiegu w przyszłości.
                            </td>
                            <td>
                            Formularz kontaktowy. Przychodząca wiadomość.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Obsługa mediów społecznościowych
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na obsłudze profilów w mediach społecznościowych.
                            </td>
                            <td>
                            Dane widocznie publicznie w profilu użytkownika w serwisie społecznościowym. Szczegóły interakcji z użytkownikiem w ramach profilów w mediach społecznościowych.
                            </td>
                            <td>
                            Do czasu usunięcia danych przez użytkownika z serwisu społecznościowego.
                            </td>
                            <td>
                            Profile w mediach społecznościowych. Treści publikowane przez użytkownika w mediach społecznościowych.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Analiza i statystyka
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na tworzeniu, przeglądaniu i analizowaniu statystyk związanych z 
                            aktywnością użytkowników na stronie w celu wyciągania wniosków pozwalających na późniejszą optymalizację strony internetowej.
                            </td>
                            <td>
                            Informacje Anonimowe (szczegóły omówione w ramach definicji Informacji Anonimowych zawartej w polityce prywatności)
                            </td>
                            <td>
                            Do czasu ustania przydatności lub wniesienia sprzeciwu.
                            </td>
                            <td>
                            Kod śledzący osadzony na stronie.
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Marketing własny
                            </td>
                            <td>
                            Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes realizowany przez administratora polegający na pomiarowaniu i targetowaniu reklam w ramach marketingu własnych produktów lub usług.
                            </td>
                            <td>
                            Informacje Anonimowe (szczegóły omówione w ramach definicji Informacji Anonimowych zawartej w polityce prywatności)
                            </td>
                            <td>
                            Do czasu ustania przydatności lub wniesienia sprzeciwu.
                            </td>
                            <td>
                            Kod śledzący osadzony na stronie.
                            </td>
                        </tr>
                    </tbody>
                </table> 
            <h2>
                Załącznik nr 2 - wykaz narzędzi zewnętrznych
            </h2>
                <p>
                    Brak.
                </p>
        </div>
    
    )
}

export default PolitykaPrywatnosci;
