import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortUlice, sortLokale, polaczUliceLokale, sortPolaczone } from "../../../functions/ulice.js";
import GoBackBtn from "../../GoBackBtn.js";
import WspolnotyUdzialyTD from './WspolnotyUdzialyTD';
import WspolnotyUdzialyTH from './WspolnotyUdzialyTH';

import { useReactToPrint } from 'react-to-print';

import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchLokale, 
    fetchUlice, 
    fetchGrupy,
    fetchUdzialyKonta,
    fetchUdzialyKontaAll,
    fetchWersje
} from '../../../Redux/Actions.js'; 

const WspolnotyUdzialy = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id");

    const dispatch = useDispatch();

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data);

    const [wspolnota, setWspolnota] = useState();


    useEffect( ()  => {
        setWspolnota( wspolnoty?.find( w => w.id == id ) );
    },[wspolnoty])

    const [ulice_w, setUlice_w] = useState([]);
    const [lokale_w, setLokale_w] = useState([]);

    useEffect( () => {
        setUlice_w( ulice?.filter( u => u.id_wspolnoty == wspolnota?.id ))
    }, [ulice, wspolnota]);

    useEffect( () => {
        setLokale_w( lokale?.filter( l => l.id_wspolnoty == wspolnota?.id ) )
    }, [lokale, wspolnota]);

    const [polaczone, setPolaczone] = useState([]);

    useEffect( () => {
        setPolaczone( polaczUliceLokale(ulice_w, lokale_w) )
    }, [ulice_w, lokale_w])
 
    const [polaczoneSort, setPolaczoneSort] = useState([]);

    useEffect( () => {
        setPolaczoneSort( sortPolaczone(polaczone) )
    }, [polaczone])

    const [wiersz1, setWiersz1] = useState(``);

    useEffect( () => {
        setWiersz1( `Lista właścicieli lokali tworzących ${ wspolnota?.name }` )
    },[wspolnota])

    const [wiersz2, setWiersz2] = useState(`obecnych na zebraniu WM w dniu`);

    const handleWiersz1 = e => setWiersz1( e.target.value );
    const handleWiersz2 = e => setWiersz2( e.target.value );

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchLokale()); 
        dispatch(fetchUlice()); 
        dispatch(fetchGrupy()); 
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAll());
      },[dispatch]);

    let udzialSuma = 0;
    let powierzchniaSuma = 0;

    const lista = polaczoneSort?.map( (lokal, i) => {

        // const ulica = uliceSort?.find( u => u.id == lokal?.ulica);
        const klatka = lokal?.klatka !== null && lokal?.klatka?.length > 0 ? ` kl. ${lokal?.klatka}` : "";
 
        const grupaLokalu = grupyAkt?.find( g => g.id_lokal == lokal?.id_lokalu);
        const mieszkaniec = mieszkancy?.find( m => m?.id_user == grupaLokalu?.id_user )

        udzialSuma += lokal?.udzial;
        powierzchniaSuma += lokal?.metraz;

        if (lokal?.udzial > 0) {
            return (
                <tr key={i} style={{border: "1px solid grey", fontSize: "10px", pageBreakInside: "avoid"}}>
                    <td style={{textAlign: "center", border: "1px solid grey"}}>
                        {i+1}
                    </td>
                    <td style={{padding: "0 5px", border: "1px solid grey"}}>
                        {mieszkaniec ? mieszkaniec?.imie_nazwisko : "(brak mieszkańca)"} 
                    </td>
                    <td style={{padding: "0 5px", border: "1px solid grey"}}>
                        {`${lokal?.rodzaj_ulicy} ${lokal?.nazwa} ${lokal?.numer_budynku}${klatka} ${lokal?.klatka} lok. ${lokal?.numer}`}
                    </td>
                    <td style={{padding: "0 50px 0 0", textAlign: "right", border: "1px solid grey"}}>
                        {`${lokal?.metraz}`}
                    </td>
                    <td style={{padding: "0 5px", textAlign: "center", border: "1px solid grey"}}>
                        {lokal?.udzial}
                    </td>
                    <td>
    
                    </td>
                </tr>
            )
        } 
    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
        pageStyle: `
            
            @media print {
                body {
                padding: 0mm;
                }
            }
            `,
    });

    return (
        <div className="content">
            <h1 className="nazwa">
                Udziały mieszkanców wspólnoty 
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <GoBackBtn />
            <button onClick={handlePrint}>Drukuj</button>
            <div className='wydruk' style={{overflow: "auto"}}>
                <div className='A4'>
                    <div
                        className="pass-title"
                        ref={componentRef}
                        style={{ width: '100%', height: window.innerHeight}}
                    >
                        <div 
                            className='A4 listaObecnosci'
                            style={{
                                width: '210mm',
                                height: '297mm',
                                padding: '50px 40px', 
                                pageBreakBefore: 'always',
                                pageBreakAfter: 'always',
                                // border: '1px solid'
                            }}    
                        >
                            <div 
                                className='tytul'
                                style={{
                                    fontWeight: '800',
                                    textAlign: 'center',
                                    margin: '15px 0 40px 0',
                                }}
                            >
                                
                                <input 
                                    type='text'
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        textAlign: 'center',
                                        border: 'none'
                                    }}
                                    onChange={handleWiersz1}
                                    value={wiersz1}
                                />
                                <input 
                                    type='text'
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        textAlign: 'center',
                                        border: 'none'
                                    }}
                                    onChange={handleWiersz2}
                                    value={wiersz2}
                                />
                            </div>

                            <div 
                                className='naglowekTabeli' 
                                style={{
                                    borderLeft: '1px solid',
                                    borderTop: '1px solid',
                                    borderRight: '1px solid',
                                    padding: '10px 0',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontWeight: '800'
                                }}
                            >
                                { wspolnota?.name }
                            </div>
                            <table 
                                className="listaObecnosci"
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    border: '1px solid black'
                                }}
                            >
                                <tr>
                                    <WspolnotyUdzialyTH value={"Lp."}/>
                                    <WspolnotyUdzialyTH value={"Imię i nazwisko"}/>
                                    <WspolnotyUdzialyTH value={"Nr lokalu"} />
                                    <WspolnotyUdzialyTH value={"Pow. lok. (m²)"}/>
                                    <WspolnotyUdzialyTH value={"Udział"} />
                                    <WspolnotyUdzialyTH value={"Podpis"} podpis={1}/>
                                </tr>
                                {lista}
                                <tr className='lastRow'>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td 
                                        className='central'
                                        style={{
                                            lineHeight: '1.2rem',
                                            fontSize: '11px',
                                            border:'1px solid black',
                                            textAlign: 'center',
                                            fontWeight: '800'
                                        }}
                                    >{powierzchniaSuma?.toFixed(2)}</td>
                                    <td 
                                        className='central'
                                        style={{
                                            lineHeight: '1.2rem',
                                            fontSize: '11px',
                                            border:'1px solid black',
                                            textAlign: 'center',
                                            fontWeight: '800'
                                        }}
                                    >{udzialSuma}</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WspolnotyUdzialy;