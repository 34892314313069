import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import { useNavigate  } from "react-router-dom";
import FormikControl from "../Formik/FormikControl";

const WspolnotyEditForm = (props) => {

    const {wspolnota} = props;

    const navigate = useNavigate();

    const editData = async (values) => {
          await axios.post('/api/edytuj_wspolnote', {
            id: wspolnota?.id,
            name: values.name === '' ? null : values.name,
            nip: values.nip === '' ? null : values.nip,
            ulica: values.ulica === '' ? null : values.ulica,
            nr_domu: values.nrDomu === '' ? null : values.nrDomu,
            nr_lokalu: values.nrLokalu === '' ? null : values.nrLokalu,
            kod_pocztowy: values.kodPocztowy === '' ? null : values.kodPocztowy,
            miejscowosc: values.miejscowosc === '' ? null : values.miejscowosc,
            bankNazwa: values.bankNazwa === '' ? null : values.bankNazwa,
            nrKonta: values.nrKonta === '' ? null : values.nrKonta
          }).then( (res) => {
            navigate("/wspolnoty");
          }).catch( err => {
            console.log(err);
          })

    };

    const onSubmit = ( values, onSubmitProps) => {
        
        editData(values);
    
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
    }

    const initialValues = {
        name: '',
        nip: '',
        ulica: '',
        nrDomu: '',
        nrLokalu: '',
        kodPocztowy: '',
        miejscowosc: '',
        bankNazwa: '',
        nrKonta: '',
    }

    const savedValues = {
        name: wspolnota?.name,
        nip: wspolnota?.nip,
        ulica: wspolnota?.ulica,
        nrDomu: wspolnota?.nr_domu,
        nrLokalu: wspolnota?.nr_lokalu,
        kodPocztowy: wspolnota?.kod_pocztowy,
        miejscowosc: wspolnota?.miejscowosc,
        bankNazwa: wspolnota?.bank_nazwa,
        nrKonta: wspolnota?.nr_konta
    }

    return (
        <div className="wspolnotyEditForm ramka">
            <div className="naglowek">
                <h3>
                    Edycja danych
                </h3>
            </div>
            <Formik
                initialValues={ savedValues || initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize>
                {formik => {
                    return (
                        <Form>
                            <FormikControl control='input' type='text' label='Nazwa:' name='name' />      
                            <FormikControl control='input' type='text' label="NIP:" name='nip' />
                            <FormikControl control='input' type='text' label='Ulica:' name='ulica' />
                            <FormikControl control='input' type='text' label='Nr domu:' name='nrDomu' />
                            <FormikControl control='input' type='text' label='Nr lokalu:' name='nrLokalu' />
                            <FormikControl control='input' type='text' label='Kod pocztowy:' name='kodPocztowy' />
                            <FormikControl control='input' type='text' label='Miejscowość:' name='miejscowosc' />
                            <FormikControl control='input' type='text' label='Nazwa banku:' name='bankNazwa' />
                            <FormikControl control='input' type='text' label='Numer konta:' name='nrKonta' />
                            <button type='submit' disabled={!formik.isValid || formik.isSubmitting}>Zapisz</button>
                            <button type="reset">Wyczyść</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>               
    )
}

export default WspolnotyEditForm;