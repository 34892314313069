import React, { useState, useEffect } from "react";
import '../scss/logi.css';
import '../scss/style.css';
import { useSelector, useDispatch } from 'react-redux';
import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchUsers,
    fetchLogi
  } from '../Redux/Actions.js'; 

  import { format } from 'date-fns';
import { usun_duplikaty } from "../functions/grupy.js";


const Logi = () => {

    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(fetchLogi());
        dispatch(fetchMieszkancy());
        dispatch(fetchUsers());
    },[dispatch])

    const [filtr, setFiltr] = useState("Zalogowanie");
    const [listaLogi, setListaLogi] = useState([])

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const users = useSelector( state => state?.users?.data);
    const logi = useSelector( state => state?.logi?.data);

    const [logiBezDubli, setLogiBezDubli] = useState([]);

    useEffect( () =>{
        setLogiBezDubli( usun_duplikaty(logi) )
    },[logi]);

    const lista = listaLogi?.map( (l, i) => {

        const user = users?.find( u => u.id == l?.id_user)
        const mieszkaniec = mieszkancy?.find( m => m.id_user == l?.id_user);
        
        if (user?.role === "mieszkaniec" || l.id_user == 0) {
            return (
                <tr key={i}>
                    <td>
                        {l.id_user == 0 ? "gość" : user?.user}
                    </td>
                    <td>
                        {mieszkaniec?.imie_nazwisko}
                    </td>
                    <td>
                        {format(l.data_utworzenia,'yyyy-MM-dd HH:mm:ss')}
                    </td>
                    <td>
                        {l.akcja}
                    </td>
                </tr>
            )
        } else return (<></>)

    })



    const handleFiltr = f => setFiltr(f);

    useEffect( ( ) => {
        if (filtr !== "Inne")
            setListaLogi( logiBezDubli?.filter( l => l.akcja == filtr && l.id_user != 1 && l.id_user != 2 && l.id_user != 3 ) )
        else if (filtr === "Inne") {
            setListaLogi( logiBezDubli?.filter( l => l.akcja !== "Strona Główna" 
                && l.akcja !== "O Nas" && l.akcja !== "Kontakt" 
                && l.akcja !== "Komunikaty" && l.akcja !== "Zalogowanie"
                && l.akcja !== "Polityka prywatności"
                && l.id_user != 1 && l.id_user != 2 && l.id_user != 3) )
        }
    },[logiBezDubli, filtr]);

    return (
        <div className="content logi">
            <h4>
                Wybierz filtr:
            </h4>
            <div>
                <span className={filtr === "Strona Główna" ? "b" : ""} onClick={()=>handleFiltr("Strona Główna")}>Strona Główna</span>
                <span className={filtr === "O Nas" ? "b" : ""} onClick={()=>handleFiltr("O Nas")}>O Nas</span>
                <span className={filtr === "Kontakt" ? "b" : ""} onClick={()=>handleFiltr("Kontakt")}>Kontakt</span>
                <span className={filtr === "Komunikaty" ? "b" : ""} onClick={()=>handleFiltr("Komunikaty")}>Komunikaty</span>
                <span className={filtr === "Zalogowanie" ? "b" : ""} onClick={()=>handleFiltr("Zalogowanie")}>Zalogowanie</span>
                <span className={filtr === "Polityka prywatności" ? "b" : ""} onClick={()=>handleFiltr("Polityka prywatności")}>Polityka Prywatności</span>
                <span className={filtr === "Inne / błędy" ? "b" : ""} onClick={()=>handleFiltr("Inne")}>Inne</span>
            </div>

            <table
                className="logi"
            >
                <thead>
                    <tr>
                        <th>
                            login
                        </th>
                        <th>
                            mieszkaniec
                        </th>
                        <th>
                            data
                        </th>
                        <th>
                            strona
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {lista}
                </tbody>
            </table>
        </div>
    )
}

export default Logi;