import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import ZuzycieLista from './ZuzycieLista';
import DialogOk from "../DialogOk";
import { 
    fetchPlanowaneZuzycie
  } from '../../Redux/Actions.js'; 

const ZuzycieFormularz = props => {

    const {wybranaWspolnota, wybranyUdzialKonto, wybranyMieszkaniec, planowaneZuzycie_u, setPlanowaneZuzycie_u, 
            zimnaWoda, setZimnaWoda, cieplaWoda, setCieplaWoda, dataOd, setDataOd
     } = props;

    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleCieplaWoda = e => setCieplaWoda( e.target.value < 0 ? 0 : e.target.value );
    const handleZimnaWoda = e => setZimnaWoda(e.target.value < 0 ? 0 : e.target.value );
    const handleDataOd = e => setDataOd(e.target.value);

    const handleZapisz = async () => {
        
        if (dataOd === "") {
            setMessage("Podaj datę.")
            setOpen(true);
        } else {
            try {
                axios.post('/api/planowane_zuzycie/dodaj', {
                  id_wspolnota: wybranaWspolnota?.id,
                  id_user: wybranyMieszkaniec?.id_user,
                  ciepla_woda: cieplaWoda,
                  zimna_woda: zimnaWoda,
                  data_od: dataOd,
                  grupa_numer: wybranyUdzialKonto?.grupa_numer
                }).then(response => {
                  dispatch(fetchPlanowaneZuzycie()); 
                  setMessage("Dane zostały zapisane.");
                  setOpen(true);
                  
                })
              } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }

      };
  
    return (
        <motion.div 
            className="planowaneZuzycie"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <DialogOk
                message={message}
                open={open}
                setOpen={setOpen}
            />
            <div className="naglowek">
                <h4>
                    {`Wprowadź planowane zużycie dla ${wybranyMieszkaniec?.imie_nazwisko}`}
                </h4>
            </div>

            <div className="formularz">
                <div className="wiersz">
                    <label htmlFor="zimnaWoda">Zimna woda:</label>
                    <div className="input">
                        <input type="number" name="zimnaWoda" value={zimnaWoda} onChange={handleZimnaWoda}/> <span>m³</span>
                    </div>                 
                </div>
                <div className="wiersz">
                    <label htmlFor="cieplaWoda">Ciepła woda:</label>
                    <div className="input">
                        <input type="number" name="cieplaWoda" value={cieplaWoda} onChange={handleCieplaWoda}/> <span>m³</span>
                    </div>                 
                </div>
                <div className="wiersz">
                    <label htmlFor="dataOd">Data od:</label>
                    <div className="input">
                        <input type="date" name="dataOd" value={dataOd} onChange={handleDataOd}/> <span></span> 
                    </div>                 
                </div>
                <div className="wiersz button">
                    <button onClick={handleZapisz}>
                        Zapisz
                    </button>
                </div>
            </div>

            <ZuzycieLista
                planowaneZuzycie_u={planowaneZuzycie_u}
                setPlanowaneZuzycie_u={setPlanowaneZuzycie_u}
            />
        </motion.div>
    )
}

export default ZuzycieFormularz;