import React from "react";
import { translate } from '../../../functions/dictionary.js';
import { jednostki } from '../../../functions/jednostki.js';
import { dataFormat } from "../../../functions/dataFormat.js";
import { objIsNotEmpty } from "../../../functions/utilities.js";
import WspolnotyStawkiListaWszystkich from "./WspolnotyStawkiListaWszystkich.js";

const WspolnotyStawkiLista = props => {

    const { idw, stawkiAkt, setStawkiAkt, tablica, stawkiArch_w } = props;

    const lista = objIsNotEmpty(stawkiAkt) ? tablica?.map((el,i) => (
        el?.klucz !== "data_od" && el?.klucz !== "id" && el?.klucz !== "id_wspolnoty" && el?.odpowiednikRodzaj2 == "1" ? (
          <li key={i}> 
            <div className="klucz">{translate(el?.klucz)}</div>
            <div className="wartosc">{el?.wartosc + " zł" }</div>
            <div className="jednostka">{jednostki(el?.odpowiednikRodzaj)}</div>
          </li>
        ) : ""
    )): "";

    const rozliczanieWody = (rodzaj) => {
      switch (rodzaj) {
        case "1":
          return "prosty";
        case "2":
          return "złożony";
        default:
          return "brak informacji"
      }
    }

    return (
        <div className="stawkiContainer">
            <h3>
              Data obowiązywania od: {
                objIsNotEmpty(stawkiAkt)
                ? dataFormat(stawkiAkt?.data_od) 
                : "-"}
            </h3>
            <div className="listy">
              <div className="lista">
                <ul className="lista stawki">
                    <li key={0}>
                      <div>Składnik</div>
                      <div>Stawka</div>
                      <div>Jednostka</div>
                    </li>
                    {lista}
                </ul>

                <ul className="lista stawki">
                  <li key={0}><div>Rozliczanie wody:</div><div>{rozliczanieWody(tablica[tablica?.findIndex( el => el.klucz === "rozliczanie_wody")]?.wartosc)}</div><div></div></li>
                </ul>

                {/* <h5>Dodakotwe koszty per mieszkaniec: { stawkiAkt?.dodatkowy_koszt_1_opis == null && stawkiAkt?.dodatkowy_koszt_2_opis == null ? " brak" : ""} </h5>

                <ul className="lista stawki">
                  {
                    stawkiAkt?.dodatkowy_koszt_1_opis && (
                      <li key={1}><div className="klucz">{stawkiAkt?.dodatkowy_koszt_1_opis}</div><div className="wartosc">{stawkiAkt?.dodatkowy_koszt_1_kwota + " zł"}</div><div className="jednostka"></div></li>
                    ) 
                  }
                  {
                    stawkiAkt?.dodatkowy_koszt_2_opis && (
                      <li key={2}><div className="klucz">{stawkiAkt?.dodatkowy_koszt_2_opis}</div><div className="wartosc">{stawkiAkt?.dodatkowy_koszt_2_kwota + " zł"}</div><div className="jednostka"></div></li>
                    )
                  }
                </ul> */}

              </div>
              <WspolnotyStawkiListaWszystkich
                stawkiArch_w={stawkiArch_w}
                setStawkiAkt={setStawkiAkt}
                id={idw}
              />
            </div>
        </div>
    )
}

export default WspolnotyStawkiLista;