import React, {useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import GoBackBtn from '../../GoBackBtn';
import Historia from './Historia';
import { getTablicaPrzelewy2, getTablicaCzynsze2, getSaldo } from "../../../functions/tablice.js";
import { fixed } from '../../../functions/stawki.js';
import { getSaldo_g, getSaldo_u } from "../../../functions/tablice";
import { zl } from '../../../functions/utilities.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchMieszkancyAll,
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice, 
    fetchStawki, 
    fetchStawkiJednorazowe,
    fetchGrupy, 
    fetchUdzialyKonta,
    fetchUsers,
    fetchPlanowaneZuzycie,
    fetchUdzialyKontaAkt
  } from '../../../Redux/Actions.js'; 

const przeksiegowaniaForm = (props) => {

    const { przelewy_u, udzialyKontaAkt_u, tablicaCzynsze, tablicaPrzelewy, wybranaWspolnotaId, wybranyMieszkaniecId } = props;
    
    const [udzialZ, setUdzialZ] = useState([]);
    const [udzialNa, setUdzialNa] = useState([]);
    const [saldo_u, setSaldo_u] = useState([]);
    const [czynszeWspolnota, setCzynszeWpolnota] = useState([]);
    const [przelewyWspolnota, setPrzelewyWspolnota] = useState([]);
    const [kwota, setKwota] = useState(0);
    const [opis, setOpis] = useState("");
    const [disabled, setDisabled] = useState(false)

    const handleZ = e => setUdzialZ( udzialyKontaAkt_u?.find( u => u.id == e.target.value));
    const handleNa = e => setUdzialNa( udzialyKontaAkt_u?.find( u => u.id == e.target.value));
    
    const dispatch = useDispatch();

    const udzialyZ = udzialyKontaAkt_u?.map( (u, i) => (
        <option 
            key={i} 
            value={u.id}
        >
            {`Grupa numer ${u.grupa_numer} (saldo: ${zl(saldo_u?.find(s=>s?.id == u.id)?.saldo)})`}
        </option>
    ));

    const udzialyNa = udzialyKontaAkt_u?.map( (u, i) => (
        <option 
            key={i} 
            value={u.id}
        >
            {`Grupa numer ${u.grupa_numer} (saldo: ${zl(saldo_u?.find(s=>s?.id == u.id)?.saldo)})`}
        </option>
    ));

    useEffect( ( ) => {
        setSaldo_u( getSaldo_u( przelewyWspolnota, czynszeWspolnota, udzialyKontaAkt_u ) )
    },[przelewyWspolnota, czynszeWspolnota, udzialyKontaAkt_u])

    useEffect( ()  => {
        setCzynszeWpolnota( tablicaCzynsze?.find( t => t?.id_wspolnoty == wybranaWspolnotaId)?.grupy );
        setPrzelewyWspolnota( tablicaPrzelewy?.find( t => t?.id_wspolnoty == wybranaWspolnotaId)?.grupy )
    },[tablicaCzynsze, tablicaPrzelewy, wybranaWspolnotaId])

    const handleKwotaChange = e => setKwota( e.target.value );
    const handleOpisChange = e => setOpis( e.target.value);

    useEffect( () => {
        setKwota(0)
    },[udzialZ, wybranyMieszkaniecId])

    // console.log(tablicaCzynsze)

    // useEffect( () => {
    //     console.log(udzialZ, udzialNa)
    //     console.log(udzialZ?.id, udzialNa?.id, disabled)
    //     if ( udzialZ && udzialNa && udzialZ?.id > 0 && udzialZ?.id == udzialNa?.id )
    //         setDisabled(true);
    //     else    
    //         setDisabled(false);
    // },[udzialZ, udzialNa])

    const handleSubmit = () => {
        console.log(kwota)
        if (kwota > 0) {
            const objZ = {
                data: new Date(),
                kwota: kwota * -1,
                id_user: wybranyMieszkaniecId,
                grupa_numer: udzialZ.grupa_numer,
                id_wspolnoty: wybranaWspolnotaId,
                operacja: "przeksięgowanie z",
                przeksiegowanie_z: udzialZ.grupa_numer,
                przeksiegowanie_na: udzialNa.grupa_numer,
                nadawca_konto: udzialZ?.konto_bankowe,
                odbiorca_konto: udzialNa?.konto_bankowe,
                nazwa_transakcji: opis,
                data_operacji: new Date()
            }
            const objNa = {
                data: new Date(),
                kwota: kwota,
                id_user: wybranyMieszkaniecId,
                grupa_numer: udzialNa.grupa_numer,
                id_wspolnoty: wybranaWspolnotaId,
                operacja: "przeksięgowanie na",
                przeksiegowanie_z: udzialZ.grupa_numer,
                przeksiegowanie_na: udzialNa.grupa_numer,
                nadawca_konto: udzialZ?.konto_bankowe,
                odbiorca_konto: udzialNa?.konto_bankowe,
                nazwa_transakcji: opis,
                data_operacji: new Date()
            }
            send(objZ);
            send(objNa);
            setKwota(0);
            setOpis("");
            setUdzialZ([]);
            setUdzialNa([]);

        }
    }

    async function send(obj) {
        try {
            await axios.post('/api/przelewy/przeksieguj', obj)
                .then( response => {
                    dispatch(fetchWspolnoty()); 
                    dispatch(fetchMieszkancy()); 
                    dispatch(fetchMieszkancyAll());
                    dispatch(fetchUsers());
                    dispatch(fetchPrzelewy()); 
                    dispatch(fetchLokale()); 
                    dispatch(fetchUlice()); 
                    dispatch(fetchStawki()); 
                    dispatch(fetchStawkiJednorazowe());
                    dispatch(fetchGrupy()); 
                    dispatch(fetchUdzialyKonta());
                    dispatch(fetchUdzialyKontaAkt());
                    dispatch(fetchPlanowaneZuzycie());

                });
        } catch (error) {
            console.error(`Error for item:`, error);
        }            
    }     

    return (
        <div className='przeksiegowaniaForm' >
            <div className='listy'>
                <div className='zNa'>
                    <span>
                        Przeksięgowanie z:
                    </span>
                    <select 
                        value={udzialZ?.id}
                        onChange={handleZ}
                    >
                        <option
                            key={0}
                            value={0}
                        >
                            (wybierz)
                        </option>
                        {udzialyZ}
                    </select>
                </div>
                <div className='zNa'>
                    <span>
                        Przeksięgowanie na:
                    </span>
                    <select 
                        value={udzialNa?.id}
                        onChange={handleNa}
                    >
                        <option
                            key={0}
                            value={0}
                        >
                            (wybierz)
                        </option>
                        {udzialyNa}
                    </select>
                </div>
            </div>
            <div className='form'>
                <div>
                    <span>
                        Opis:
                    </span>
                    <input
                        type="text"
                        className='opis'
                        value={opis}
                        onChange={handleOpisChange}
                    />
                </div>
                <div>
                    <span>
                        Kwota:
                    </span>
                    <input 
                        type="number"
                        value={kwota}
                        onChange={handleKwotaChange}
                    />
                </div>
                <button
                    onClick={handleSubmit}
                    disabled={disabled}
                    className='pointer'
                >
                    <FontAwesomeIcon icon={faPlay} />                    
                </button>
            </div>
        </div>
    )
}

export default przeksiegowaniaForm;