import React from 'react';
import Input from './Input';
import InputF from './InputF';
import InputRef from './InputRef';
import CheckBox from './CheckBox';
import DatePicker from './DatePicker';
import Select from './Select';
import SelectF from './SelectF';
import Textarea from './Textarea';

function FormikControl(props){
    const { control, ...rest }  = props;
    switch(control) {
        case 'input': 
            return <Input {...rest} />
        case 'inputF': 
            return <InputF {...rest} />
        case 'inputRef':   
            return <InputRef {...rest} />    
        case 'textarea': 
            return <Textarea {...rest} />
        case 'select': 
            return <Select {...rest}/>
        case 'selectF': 
            return <SelectF {...rest}/>
        case 'radio': return <></>
        case 'checkbox': 
            return <CheckBox {...rest}/>
        case 'date': 
            return <DatePicker {...rest} />
        default: return null
    }
}

export default FormikControl;