import React, {useEffect, useState } from 'react';

import { dataFormat } from '../../functions/dataFormat';
import { zl, zaokr } from '../../functions/utilities';
import '../../scss/style.css';
import '../../scss/listy.css';

const WspolnotaRozliczenieSaldo = (props) => {

    const {listaMsc, przelewy_w, przelewyWspolnota, mieszkancy, czynsze, miesiac, setMiesiac, udzialyKontaUniq} = props;
    const [przelewyMsc, setPrzelewyMsc] = useState([]);

    const findMieszkaniec = (id_przelew) => {
        let id = 0;
        przelewyWspolnota?.forEach( p => {
            p?.przelewy?.forEach( e => {
                if ( e.id == id_przelew)
                    id = p.id_user;
            })
        });        
        return mieszkancy?.find( m => m.id_user == id)?.imie_nazwisko;
    }
    
    const listaWplat = przelewy_w?.map( (el, i) => (
        <li key={i}>
            <div>{dataFormat(el?.data_operacji)}</div>
            <div>{findMieszkaniec(el.id)}</div>
            <div className='right'>{zl(el.kwota)}</div>
        </li>
    ));
        
    let sumaMiesiacaTotal = 0;
    let sumaPrzelewyTotal = 0;
    
    const listaNaleznosci = listaMsc?.slice(0, -1).map( (msc,i) => {
        let sumaMiesiaca = 0;

        czynsze?.forEach((czynsz) => {
            czynsz.grupy_msc?.forEach( c => {
                c.temp_msc?.forEach( t => {
                    sumaMiesiaca += t.msc == msc ? zaokr(t?.czynsz_na_msc,2) : 0;
                    sumaMiesiacaTotal += t.msc == msc ? t?.czynsz_na_msc : 0;
                })
            })
        });
               

        let sumaPrzelewy = 0;
        przelewyWspolnota?.forEach( przelew => {
            przelew?.przelewy?.forEach( p => {
                sumaPrzelewy += p.msc == msc ? p.kwota : 0;
                sumaPrzelewyTotal += p.msc == msc ? p.kwota : 0;
            })
        })

        return (
            <li 
                key={i} 
                className={ miesiac == msc ? "active pointer" : "pointer" }
                onClick={()=>{setMiesiac(msc)}}
            >
                <div className='pointer'>{msc}</div>
                <div className='right'>{zl(sumaMiesiaca)}</div>
                <div className='right'>{zl(sumaPrzelewy)}</div>
                <div className='right'>{zl(sumaPrzelewy - sumaMiesiaca)}</div>
            </li>
        )
    })

    useEffect(()=> {
        let podsumowanieWplat = 0;
        przelewyWspolnota?.forEach( p => {
            p?.przelewy?.forEach( z => {
                podsumowanieWplat += z?.kwota;
            })
        })
        setPrzelewyMsc(podsumowanieWplat);
    },[przelewyWspolnota,  listaNaleznosci])

    return (
            <div 
                className='wspolnotaRozliczenieSaldo'
            >
                <div 
                    className='tabelaNaleznosci' 
                    style={{
                        "width": "30%",
                        "margin": "0 30px",
                        "maxHeight  ": "600px",
                        overflow: "auto"
                    }}
                >
                    <h5>Należności</h5>
                    <ul className='lista'>
                        <li>
                            <div>Miesiąc</div>
                            <div className='right'>Czynsz</div>
                            <div className='right'>Wpłaty</div>
                            <div className='right'>Saldo</div>
                        </li>
                        {listaNaleznosci}
                        <li className='lastRow'>
                            <div></div>
                            <div className='right'><b>{zl(sumaMiesiacaTotal)}</b></div>
                            <div className='right'><b>{zl(sumaPrzelewyTotal)}</b></div>
                            <div className='right'><b>{zl(sumaPrzelewyTotal - sumaMiesiacaTotal)}</b></div>
                        </li>
                    </ul>
                    <h5 className='central' style={{"marginTop": "50px"}} >
                        {`Saldo wspólnoty na dzień ${dataFormat(new Date())} wynosi  ${zl(sumaPrzelewyTotal - sumaMiesiacaTotal)}.`} 
                    </h5>
                </div>
                {/* <div 
                    className='tabelaWplaty' 
                >
                    <h5>Wpłaty</h5>
                    <ul className='lista'>
                        <li>
                            <div>Data</div>
                            <div>Mieszkaniec</div>
                            <div className='right'>Kwota</div>
                        </li>
                        {listaWplat}
                        {
                            listaWplat?.length ? (
                                <li>
                                    <div></div>
                                    <div className='right'><b>{zl(przelewyMsc)}</b></div>
                                </li>
                            ) : (<></>)
                        }

                    </ul>
                </div> */}
            </div>

    )
}



export default WspolnotaRozliczenieSaldo;

