import React from 'react';
import Papa from 'papaparse';

const CsvUploader = (props) => {
  
  const { setData, fileName, setFileName } = props;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name)
      Papa.parse(file, {
        complete: (result) => {
          // Dodaj tutaj nagłówki ręcznie
          const headers = ['pole1', 'pole2', 'pole3', 'pole4', 'data', 'pole6', 'id', 'pole8', 'kwota', 'nadawca', 'pole10', 'rodzaj_transakcji', 'konto_tytul'];
          const rows = result.data.map((row, index) => {
            const obj = {};
            headers.forEach((header, i) => {
              obj[header] = row[i];
            });
            return obj;
          });
          setData(rows);
        },
        skipEmptyLines: true
      });
    }
  };

  const handleReset = () => {
    setData([]);
    setFileName("")
    document.getElementById("file-input").value = ""; // Resetowanie wartości inputa
  };

  return (
    <>  
        <input
          id="file-input"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
        />
        { fileName !== "" ? ( <button onClick={handleReset}>Usuń plik</button> ) : "" }
    </>

  );
};

export default CsvUploader;

// const headers = ['pole1', 'pole2', 'pole3', 'pole4', 'data', 'pole6', 'id', 'pole8', 'kwota', 'nadawca', 'pole10', 'rodzaj_transakcji', 'konto_tytul'];