const initialState = {
  data: [],
  isLoading: false,
  error: null
};
  
const saldoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATA_START':
      return { ...state, isLoading: true };
    case 'SET_SALDO':
      return { ...state, isLoading: false, data: action.payload };
    case 'FETCH_DATA_FAILURE':
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default saldoReducer;