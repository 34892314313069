import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { dataFormatMsc } from "../../functions/dataFormat.js";
import ZarzadRozliczenieWspolnoty from "./ZarzadRozliczenieWspolnoty";
import WspolnotaRozliczenieSaldo from "../Wspolnoty/WspolnotaRozliczenieSaldo.js";


import '../../scss/style.css';
import '../../scss/listy.css';
import '../../scss/mieszkancy.css'

import { AuthContext } from "../../Context/AuthProvider";

import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchMieszkancyAll,
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice, 
    fetchStawki, 
    fetchStawkiJednorazowe,
    fetchGrupy, 
    fetchGrupyAll,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUsers,
    fetchPlanowaneZuzycie
  } from '../../Redux/Actions.js'; 

import { getTablicaCzynsze2, getTablicaPrzelewy2 } from "../../functions/tablice.js";

const Zarzad = () => {

    const { cred, user, userId } = useContext(AuthContext);
    const dispatch = useDispatch();


    const [wspolnota, setWspolnota] = useState({});
    const [mieszkaniec, setMieszkaniec] = useState({});
    const [thisUser, setThisUser] = useState(0);

    const [listaMsc, setListaMsc] = useState([]);
    const [przelewy_w, setPrzelewy_w] = useState([]);
    const [miesiac, setMiesiac] = useState(dataFormatMsc(new Date()));

    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);

    const [stawki_w, setStawki_w] = useState([]);
    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);

    const [tablicaCzynsze_w, setTablicaCzynsze_w] = useState([]);
    const [tablicaPrzelewy_w, setTablicaPrzelewy_w] = useState([]);

    const [udzialyKonta_w, setUdzialyKonta_w] = useState([]);

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const users = useSelector(state => state?.users?.data);

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchUsers());
        dispatch(fetchMieszkancyAll());
        dispatch(fetchPrzelewy()); 
        dispatch(fetchLokale()); 
        dispatch(fetchUlice()); 
        dispatch(fetchStawki()); 
        dispatch(fetchStawkiJednorazowe());
        dispatch(fetchGrupy()); 
        dispatch(fetchGrupyAll()); 
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAkt());
        dispatch(fetchPlanowaneZuzycie());
    },[dispatch]);
  
    useEffect( () => {
        setStawki_w( stawki?.find( s => s.id_wspolnoty == mieszkaniec?.id_wspolnoty ) )
    },[stawki]);

    useEffect( () => {
        setStawkiJednorazowe_w( stawkiJednorazowe?.find( s => s.id_wspolnoty == mieszkaniec?.id_wspolnoty && s.msc == miesiac) )
    },[stawkiJednorazowe, miesiac])

    useEffect( ( ) => {
        setThisUser( users?.find( u => u?.user == user ) )
    },[user, users])

    useEffect(()=>{
        setMieszkaniec( mieszkancy?.find( m => m.id_user == thisUser?.id) );
    },[mieszkancy, thisUser])

    useEffect( () => {
        setWspolnota( wspolnoty?.find( w => w.id == mieszkaniec?.id_wspolnoty) )
    },[wspolnoty, mieszkaniec]);

    useEffect( () => {
        setPrzelewy_w( przelewy?.filter( p => p.id_wspolnoty == mieszkaniec?.id_wspolnoty));
    },[przelewy, mieszkaniec]);

    useEffect( () =>{
        setUdzialyKonta_w( udzialyKontaAkt?.filter( u => u.id_wspolnoty == mieszkaniec?.id_wspolnoty) );
    },[udzialyKontaAkt, mieszkaniec])

    useEffect( () => {
        setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKonta, przelewy)?.find( t => t.id_wspolnoty == mieszkaniec?.id_wspolnoty) )
    },[wspolnoty, udzialyKonta, przelewy])

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, [], lokale, stawki, planowaneZuzycie /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie /*, zaliczki */])

    useEffect( () => {
        setTablicaCzynsze_w(tablicaCzynsze?.find( t => t.id_wspolnoty == mieszkaniec?.id_wspolnoty));        
    },[tablicaCzynsze, mieszkaniec]);

    
    useEffect( () => {
        const temp = tablicaPrzelewy?.find( t => t.id_wspolnoty == mieszkaniec?.id_wspolnoty);
        setTablicaPrzelewy_w( temp ? temp : {});
    },[tablicaPrzelewy, mieszkaniec]);


    const dodajMiesiac = d => {
        d.setMonth(d.getMonth() + 1);
        return d;
      }

    useEffect(()=> {
        if (stawki?.length > 0) {
          let minData = stawki[0].data_od
          for (let i = 1; i < stawki.length; i++) {
            if (stawki[i].data_od < minData) {
              minData = stawki[i].data_od;
            }
          }
  
          const dataObecna = new Date();
          const dataMiesiacPoObecnej = new Date(dataObecna);
          const tablicaMiesiecy = [];
          let aktualnaData = new Date(minData);
          while (aktualnaData <= dataMiesiacPoObecnej) {
            tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
            aktualnaData = dodajMiesiac(aktualnaData);
        }
  
          setListaMsc(tablicaMiesiecy)
        }
    },[stawki]);

    return (
        <div className="content">
            <h2 className="nazwa">
                Należności wspólnoty 
            </h2>
            <h3>
                { wspolnota?.name }
            </h3>

            <div style={{"height": "20px"}}></div>
            
            <ZarzadRozliczenieWspolnoty
                stawki_w={stawki_w}
                stawkiJednorazowe={stawkiJednorazowe_w}
                czynszeWspolnota={tablicaCzynsze_w?.grupy}
                grupy={grupy}
                idw={mieszkaniec?.id_wspolnoty}
                przelewyWspolnota={tablicaPrzelewy_w?.grupy}
                wspolnota={wspolnota}
                udzialyKonta={udzialyKonta_w}
                miesiac={miesiac}
            />

            <div style={{"height": "50px"}}></div>

            <WspolnotaRozliczenieSaldo
                listaMsc={listaMsc}
                przelewy_w={przelewy_w}
                przelewyWspolnota={tablicaPrzelewy_w?.grupy}
                mieszkancy={mieszkancy}
                czynsze={tablicaCzynsze_w?.grupy}
                miesiac={miesiac}
                setMiesiac={setMiesiac}
            />

        </div>
    )
}

export default Zarzad;