import React from 'react';
import { NavLink } from 'react-router-dom';



const KontaBtnAdd = () => {


    return (
        <NavLink to="/konta/dodaj">
            <button>Dodaj konto</button>
        </NavLink>
    )
}

export default KontaBtnAdd;