const initialState = {
    data: [],
};
  
const credReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CRED':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default credReducer;