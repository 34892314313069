import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { adres } from "../../functions/ulice";
import { ikona } from '../../functions/ikony';
import { usun_duplikaty } from '../../functions/grupy';
import { zl } from "../../functions/utilities";
import { getSaldo_g, saldoWykrzyknik } from "../../functions/tablice";

const WspolnotaRozliczenie = (props) => {

    const { idw, wspolnota, lokale, ulice, grupy, udzialyKontaAkt, stawki_w, stawkiJednorazowe_w, przelewyWspolnota, czynszeWspolnota, czynszeMsc, mieszkancy } = props;

    const tab = udzialyKontaAkt?.map( (udzial, i) => {

      const mieszkaniec = mieszkancy.filter( mieszkaniec => mieszkaniec.id_user == udzial.id_user)[0];
      const czynsz_temp = czynszeMsc?.filter( czynsz => czynsz.id_user == udzial.id_user && czynsz.grupa_numer == udzial.grupa_numer);
      const czynsz = czynsz_temp && czynsz_temp.length > 0 ? czynsz_temp[0].czynsz : null
      const saldo = parseFloat(getSaldo_g(przelewyWspolnota, czynszeWspolnota, udzial).toFixed(2));
      
      if (czynsz?.length == 0)
        return {}

      const grupy_usera = grupy?.filter( g => g.id_user == mieszkaniec?.id_user && g.grupa_numer == udzial?.grupa_numer);

      const lokale_usera_temp = grupy_usera?.map( g => {
        return {
          id_lokal: g.id_lokal,
          id_user: g.id_user,
          grupa_numer: g.grupa_numer
        }
      });
      
      const lokale_usera = usun_duplikaty(lokale_usera_temp);
      
      const lokale_td = lokale_usera?.map( (lokal, i) => {
        return (
          
          <li key={i}>
            <NavLink 
              to={`/mieszkancy/rozliczenia?id=${lokal?.id_user}&idw=${idw}&g=${lokal?.grupa_numer}`}
              state={
                {
                  from: {
                    czynszeMieszkaniec: czynszeWspolnota?.find( t => t.id_user == lokal.id_user )?.grupy_msc,
                    przelewyMieszkaniec: przelewyWspolnota?.find( t => t.id_user == lokal?.id_user && t.nr_grupyUdzial == lokal.grupa_numer)?.przelewy,
                    wspolnota: wspolnota
                  }
                }
              }
            > 
              {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.ulica, ulice, lokal.id_lokal, lokale)}`} 
            </NavLink>
          </li>
        )
      })

      return (
          czynsz && <tr key={i}>
            <td>
              {`${mieszkaniec.imie_nazwisko} (id: ${mieszkaniec.id_user})`} {saldo < 0.00 ? saldoWykrzyknik() : "" } 
            </td>
            <td>
              <ul style={{"listStyle": "none"}}>
                {lokale_td}
              </ul>
            </td>
              { stawki_w?.czy_eksploatacja == 1 ? (<td>{zl(czynsz.eksploatacja)}</td>) : ""   }
              { stawki_w?.czy_garaz == 1 ? (<td>{zl(czynsz.garaz)}</td>) : ""}
              { stawki_w?.czy_strych == 1 ? (<td>{zl(czynsz.strych)}</td>) : "" }
              { stawki_w?.czy_komorka ==  1 ? (<td>{zl(czynsz.komorka)}</td>) : "" }
              { stawki_w?.czy_utrzymanie_czystosci == 1 ? (<td>{zl(czynsz.utrzymanie_czystosci)}</td>) : ""} 
              { stawki_w?.czy_wywoz_smieci == 1 ? (<td>{zl(czynsz.wywoz_smieci)}</td>) : ""} 
              { stawki_w?.czy_fundusz_remontowy == 1 ? (<td>{zl(czynsz.fundusz_remontowy)}</td>) : ""} 
              { stawki_w?.czy_konserw_domofonu == 1 ? (<td>{zl(czynsz.konserw_domofonu)}</td>) : ""} 
              { stawki_w?.czy_konserw_windy == 1 ? (<td>{zl(czynsz.konserw_windy)}</td>) : ""} 
              { stawki_w?.czy_opl_za_adm == 1 ? (<td>{zl(czynsz.opl_za_adm)}</td>) : ""} 
              { stawki_w?.czy_abonament_cw == 1 ? (<td>{zl(czynsz.abonament_cw)}</td>) : ""} 
              { stawki_w?.czy_co == 1 ? (<td>{zl(czynsz.co)}</td>) : ""} 
              { stawki_w?.czy_energia_el_lokalu == 1 ? (<td>{zl(czynsz.energia_el_lokal)}</td>) : ""} 
              { stawki_w?.czy_ryczalt_gaz == 1 ? (<td>{zl(czynsz.ryczalt_ga)}</td>) : ""} 
              { stawki_w?.czy_zagosp_terenu == 1 ? (<td>{zl(czynsz.zagosp_teren)}</td>) : ""} 
              { stawki_w?.czy_antena == 1 ? (<td>{zl(czynsz.antena)}</td>) : ""} 
              { stawki_w?.czy_ciepla_woda == 1 ? (<td>{zl(czynsz.ciepla_woda)}</td>) : ""} 
              { stawki_w?.czy_zimna_woda == 1 ? (<td>{zl(czynsz.zimna_woda)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_1_czy == 1 ? (<td>{zl(czynsz.dod_1)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_2_czy == 1 ? (<td>{zl(czynsz.dod_2)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_3_czy == 1 ? (<td>{zl(czynsz.dod_3)}</td>) : ""} 
            <td>{zl(czynsz.czynsz_na_msc)}</td>
          </tr>
      )


    })

    return (
      <tbody>
        {tab}
      </tbody>
    )
}

export default WspolnotaRozliczenie;