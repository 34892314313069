import React from "react";
import { NavLink } from 'react-router-dom';
import '../../../scss/style.css';

const FakturaBtnAdd = () => {


    return (
        <NavLink to="/faktury/dodaj">
            <button>Dodaj fakturę</button>
        </NavLink>
    )
}

export default FakturaBtnAdd;