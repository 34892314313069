export const adres = ( (id_ulica, ulice, id_lokal, lokale) => {

  const rekord_ = ulice?.filter( u => u.id === id_ulica);
  const rekord = rekord_ && rekord_.length > 0 ? rekord_[0] : undefined;

  const lokal_ = lokale?.filter( l => l.id === id_lokal);
  const lokal = lokal_ && lokal_.length > 0 ? lokal_[0]?.numer : undefined;
  
  return rekord ? rekord?.rodzaj + " " + rekord?.nazwa + " " + rekord?.numer_budynku + " / " + lokal : "";

});

export const adresPro = ( (id_lokal, lokale, ulice) => {

  if (id_lokal && id_lokal != "" ) {
    const lokal = lokale?.find( l => l.id == id_lokal);
    const ulica = ulice?.find( u => u.id == lokal.ulica );
  
    return `${ulica?.rodzaj} ${ulica?.nazwa} ${ulica?.numer_budynku} / ${lokal?.numer}`
  
  }
  else 
    return "";  

});

export const rodzajLokalu = r => {
  switch(r) {
    case "mieszkanie":
      return "(m) ";
    case "garaż":
      return "(g) ";
    case "komórka":
      return "(k) ";
    case "strych":
      return "(s) "
    default:
      return "";
  }
}

export const findMieszkanieId = (udzialKonto, grupy, lokale) => {
  const przeszukaneGrupy = grupy?.filter(g => g.id_user == udzialKonto?.id_user && g.grupa_numer == udzialKonto?.grupa_numer);
  let id_mieszkanie; 
  przeszukaneGrupy?.forEach( g => {
    const temp = lokale?.find( l => l.id == g.id_lokal && l.rodzaj === "mieszkanie")?.id;
    if (temp !== undefined) 
      id_mieszkanie = temp;
  })
  return id_mieszkanie;
}


export const  polaczUliceLokale = (ulice, lokale) => {
  return lokale.map(lokal => {
    // Znajdź odpowiadającą ulicę dla danego lokalu
    const ulica = ulice.find(u => u.id === lokal.ulica);

    // Jeśli znaleziono odpowiednią ulicę, połącz oba obiekty
    if (ulica) {
        return {
            id_lokalu: lokal.id,  // Zmieniamy nazwę pola id z tablicy lokale
            rodzaj_ulicy: ulica.rodzaj,
            ...ulica,
            ...lokal,
        };
    }

    // Jeśli nie znaleziono odpowiadającej ulicy, zwróć lokal bez zmian (możesz zmodyfikować wg potrzeb)
    return {
        id_lokalu: lokal.id,  // Zmieniamy nazwę pola id z tablicy lokale
        ...lokal,
    };
});
}

export const sortPolaczone = (tablica) => {
  return tablica.sort((a, b) => {
      // Sortowanie według rodzaju (sortowanie alfabetyczne)
      if (a.nazwa.toLowerCase() < b.nazwa.toLowerCase()) {
          return -1;
      }
      if (a.nazwa.toLowerCase() > b.nazwa.toLowerCase()) {
          return 1;
      }

      // Sortowanie według ulicy (sortowanie alfabetyczne)
      if (a.numer_budynku < b.numer_budynku) {
          return -1;
      }
      if (a.numer_budynku > b.numer_budynku) {
          return 1;
      }

    if (a.rodzaj.toLowerCase() < b.rodzaj.toLowerCase()) {
        return -1;
    }
    if (a.rodzaj.toLowerCase() > b.rodzaj.toLowerCase()) {
        return 1;
    }

      // Sortowanie według numeru (sortowanie alfanumeryczne)
      if (parseInt(a.numer) < parseInt(b.numer)) {
          return -1;
      }
      if (parseInt(a.numer) > parseInt(b.numer)) {
          return 1;
      }

      return 0; // Jeśli wszystkie pola są równe
  });
}