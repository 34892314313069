import React, { useRef } from "react";
import { data_z_dniem, dataFormatMsc, zamienTekstNaDate } from "../../functions/dataFormat";
import { formatKonta, zl } from "../../functions/utilities";
import { translate, rodzaj_rozliczenia } from "../../functions/dictionary";
import { useReactToPrint } from 'react-to-print';
import { adresPro, findMieszkanieId } from "../../functions/ulice";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import emailjs from '@emailjs/browser';


const MieszkaniecWydruk = props => {

    const { udzialyKonta_a, showPrint, mieszkaniec, tablicaCzynszeAkt, podstawa, stawki_w, stawkiJednorazowe_msc, lokale, ulice, grupy_a } = props;

    const handleModalClick = (event) => event.stopPropagation();

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });
        
    const idMieszkania = findMieszkanieId(udzialyKonta_a,grupy_a,lokale);

    const adres = adresPro(idMieszkania,lokale, ulice);
   
    const tabela = (stawki_w) => {
        const elements = [];
        let i = 1;
        if (stawki_w) {
            const hasOwn = Object.prototype.hasOwnProperty;
            for (const key in stawki_w) {
                if (hasOwn.call(stawki_w, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki_w[key];
                    const rodzaj = stawki_w[rodzajKey];
                    const czyUwzgledniany = stawki_w[czyKey] && !isNaN(tablicaCzynszeAkt[key]);
    
                    const podstawa_ = rodzaj_rozliczenia(rodzaj, key) == "opłata stała" ? "1" :  podstawa(tablicaCzynszeAkt, key);

                    if (czyUwzgledniany && cena > 0 && tablicaCzynszeAkt[key] > 0) {
                        elements.push(
                            <tr key={key}>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >    
                                    {i++}                                     
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                    }}
                                >
                                    {translate(key)}                        
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {rodzaj_rozliczenia(rodzaj, key)}       
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    { isNaN(podstawa_) ? 0 : podstawa_}      
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {zl(cena)}                              
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'right',
                                    }}
                                >    
                                    {zl(tablicaCzynszeAkt[key])}            
                                </td>
                            </tr>
                        );
                    }
                }
            }
            return elements;
        } else return ""
    };

    const tabela2 = s => {
        return (
            <>
                {
                    s?.dod_1_czy == 1 ? (
                        <tr key='dod_1'>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'left',
                                }}
                            >    
                                { s?.dod_1_opis }
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'right',
                                }}
                            >    
                                { zl(s.dod_1_kwota) }
                            </td>

                        </tr>
                    ) : ""
                }  
                {
                    s?.dod_2_czy == 1 ? (
                        <tr key='dod_2'>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'left',
                                }}
                            >    
                                { s?.dod_2_opis }
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'right',
                                }}
                            >    
                                { zl(s.dod_2_kwota) }
                            </td>

                        </tr>
                    ) : ""
                }  
                {
                    s?.dod_3_czy == 1 ? (
                        <tr key='dod_3'>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'left',
                                }}
                            >    
                                { s?.dod_3_opis }
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'center',
                                }}
                            >    
                            </td>
                            <td 
                                style={{
                                    borderLeft: '1px solid black', 
                                    borderRight: '1px solid black',
                                    padding: '1mm 2mm',
                                    textAlign: 'right',
                                }}
                            >    
                                { zl(s.dod_3_kwota) }
                            </td>

                        </tr>
                    ) : ""
                }  
            </>
        )
    }

    const pdfRef = useRef(null);

    const setRefs = (element) => {
        pdfRef.current = element;
        componentRef.current = element;
    };

    const handleGenerateAndSendPDF = async () => {
        const element = pdfRef.current;
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4'
        });
        pdf.addImage(imgData, 'PNG', 0, 0);
        const pdfBlob = pdf.output('blob');

        // Konwertuj PDF blob do Base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = function () {
            const base64data = reader.result.split(',')[1]; // Pobiera Base64 bez nagłówka
            console.log(base64data)
            // Wysyłanie e-maila z załącznikiem
            emailjs.send('service_d6ugz9r', 'template_rgzgc5s', {
                to_email: 'recipient@example.com',
                from_name: 'Your Name',
                message: 'W załączeniu PDF z wygenerowanego dokumentu.',
                attachment: base64data,
                attachment_name: 'wydruk.pdf'
            }, 'Sf5FCcGAApPYZnnXi')
            .then((response) => {
                console.log('Sukces!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Błąd podczas wysyłania e-maila:', error);
            });
        };
    };

    return (
        <div className="mieszkaniecWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                <div 
                    style={{
                        width: '21cm',
                        height: '29.7cm',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        padding: '15mm 15mm',
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }} 
                    ref={setRefs}
                >
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '13px'
                        }}
                    >
                        <div>
                            <span>
                                <b>Administrator</b><br/>
                                Biuro Obsługi Nieruchomości<br/>
                                Mojsiewicz & Stanek s.c.<br/>
                                ul. Mieszka I 57/6<br/>
                                66-400 Gorzów Wielkopolski<br/>
                                t: 95 724 19 08<br/>
                                e: biuro@bonadministrator.pl
                            </span>
                        </div>
                        <div>
                            Gorzów Wlkp. {data_z_dniem()}
                        </div>
                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: 'right',
                            fontSize: '13px',
                            margin: '20mm 0 0 0'
                        }}
                    >
                        <span
                            style={{
                                fontWeight: '600',
                                fontSize: '14px'
                            }}
                        >
                            Sz. P.<br/>
                            {mieszkaniec?.imie_nazwisko}<br/>
                            {adres}<br/>
                            66-400 Gorzów Wlkp.

                        </span>
                        
                    </section>
                    <section
                        style={{
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '13px'
                            }}
                        >
                            {`Uprzejmie zawiadamiamy, iż wymiar miesięcznej zaliczki na pokrycie kosztów zarządu nieruchomością wspólną oraz zaliczek na poczet mediów i usług dodatkowych w miesiącu ${dataFormatMsc(zamienTekstNaDate(tablicaCzynszeAkt?.msc))} r. wynosi:`}
                        </span>
                    </section>
                    <section
                        style={{
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <table
                            style={{
                                fontSize: '13px',
                                borderCollapse: 'collapse',
                                width: '100%'
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Lp.             </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Tytył zaliczki  </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    j.m.            </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    ilość           </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    cena j.         </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Kwota zaliczki  </th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                { tabela(stawki_w) }
                                { tabela2(stawkiJednorazowe_msc) }
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: '1px solid black',
                                            textAlign: 'right',
                                            padding: '1mm 2mm',
                                            fontWeight: '600'
                                        }}
                                        colSpan={5}
                                    >
                                        Razem zaliczka:
                                    </td>
                                    <td 
                                        style={{
                                            border: '1px solid black', 
                                            padding: '1mm',
                                            textAlign: 'right',
                                            fontWeight: '600'
                                        }}
                                    >
                                        {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section
                        style={{
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '13px'
                            }}
                        >
                            {`Należność powyższą prosimy uregulować do 10-tego dnia każdego miesiąca dokonując wpłaty na konto `}
                        </span>
                        <p
                            style={{
                                fontSize: '13px'
                            }}
                        >
                            {formatKonta(udzialyKonta_a?.konto_bankowe)}
                        </p>
                    </section>

                </div>

                <div className="buttons">
                    <button 
                        onClick={handlePrint}
                    >
                        Drukuj
                    </button>
                    <button
                        onClick={handleGenerateAndSendPDF}
                    >
                        Wyślij
                    </button>
                    <button
                        onClick={showPrint}
                    >
                        Anuluj
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MieszkaniecWydruk;