import React from "react";

const WspolnotaRozliczenie = (props) => {

    const { stawki_w, stawkiJednorazowe_w } = props;

    return (
      <thead>
        <tr key={0}>
          <th>Mieszkaniec</th>
          <th>Lokal</th>
            { stawki_w?.czy_eksploatacja == 1 ? ( <th>eks.</th> ) : ""   }
            { stawki_w?.czy_garaz == 1 ? ( <th>gar.</th> ) : ""}
            { stawki_w?.czy_strych == 1 ? ( <th>str.</th> ) : "" }
            { stawki_w?.czy_komorka ==  1 ? ( <th>kom.</th> ) : "" }
            { stawki_w?.czy_utrzymanie_czystosci == 1 ? ( <th>utr.</th> ) : ""} 
            { stawki_w?.czy_wywoz_smieci == 1 ? ( <th>wyw.</th> ) : ""} 
            { stawki_w?.czy_fundusz_remontowy == 1 ? ( <th>rem.</th> ) : ""} 
            { stawki_w?.czy_konserw_domofonu == 1 ? ( <th>dom.</th> ) : ""} 
            { stawki_w?.czy_konserw_windy == 1 ? ( <th>win.</th> ) : ""} 
            { stawki_w?.czy_opl_za_adm == 1 ? ( <th>adm.</th> ) : ""} 
            { stawki_w?.czy_abonament_cw == 1 ? ( <th>abo.</th> ) : ""} 
            { stawki_w?.czy_co == 1 ? ( <th>c.o.</th> ) : ""} 
            { stawki_w?.czy_energia_el_lokalu == 1 ? ( <th>ene.</th> ) : ""} 
            { stawki_w?.czy_ryczalt_gaz == 1 ? ( <th>gaz </th>) : ""} 
            { stawki_w?.czy_zagosp_terenu == 1 ? ( <th>zag.</th> ) : ""} 
            { stawki_w?.czy_antena == 1 ? ( <th>ant.</th> ) : ""} 
            { stawki_w?.czy_ciepla_woda == 1 ? ( <th>cie.</th> ) : ""} 
            { stawki_w?.czy_zimna_woda == 1 ? ( <th>zim.</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_1_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_1_opis}</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_2_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_2_opis}</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_3_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_3_opis}</th> ) : ""} 
          <th>Suma </th>
        </tr>
      </thead>
    )
}

export default WspolnotaRozliczenie;