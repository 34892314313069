import React, { useState } from "react";
import { useDispatch } from 'react-redux';

import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { dataFormat } from "../../functions/dataFormat";
import { fetchGrupy, fetchGrupyAll, fetchGrupyAkt, fetchLokale, fetchUdzialyKonta, fetchUdzialyKontaAkt, fetchUdzialyKontaAll } from "../../Redux/Actions";
import DialogYesNo from "../DialogYesNo";
import { format } from 'date-fns';


const MieszkancyDaneGrupyHistoriaZmian = props => {

    const { zmianyGrupyPokaz, setZmianyGrupyPokaz, taGrupaUdzial } = props;

    const dispatch = useDispatch();
    const onClickHandle = () => {
        setZmianyGrupyPokaz( prevState => !prevState)
    }

    const [open, setOpen] = useState(false);

    async function usunGrupe(id) {
        try {
            const response = await axios.post('/api/grupy_konta_udzialy/usun', { id: id });
            if (response.data.success === true) {
                dispatch(fetchUdzialyKonta()); 
                dispatch(fetchUdzialyKontaAll());
                dispatch(fetchUdzialyKontaAkt());
                dispatch(fetchGrupy());
                dispatch(fetchGrupyAkt());
                dispatch(fetchGrupyAll());
                dispatch(fetchLokale());
            }
        } catch (error) {
            console.error('Error sending ID to backend:', error);
        }
    }

    const [trashId, setTrashId] = useState(0);

    const handleClick = (id) => {
        setTrashId(id)
        setOpen(true);   
    }
    
    const listaZmian = taGrupaUdzial?.map( (u,i) => {
        return (
            <li key={i}>
                <div className="kosz-con">
                    <div className="kosz" onClick={()=>handleClick(u.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </div>
                <div className="dane">
                    <div className="daty">
                        <div className="opis">
                            Data utworzenia:
                        </div> 
                        <div>
                            {format(u.data_utworzenia,'yyyy-MM-dd HH:mm:ss')}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Data od:
                        </div>
                        <div>
                            {dataFormat(u.data_od)}
                        </div>
                    </div>
                    <div>
                        Konto: {u.konto_bankowe}
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Udział: 
                        </div>
                        <div>
                            {u.udzial}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Liczba mieszańców:
                        </div>
                        <div>
                            {u.liczba_domownikow}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Id wpisu:
                        </div>
                        <div>
                            {u.id}
                        </div>
                    </div>
                </div>                
            </li>
        )
    })

    const zmiany = (
        <div className="zmiana">
            <ul className="historiaZmian">
                {listaZmian}
            </ul>
        </div>
    )

    return (
        <div className="historia">
            <h4>
                <span 
                    onClick={()=>{onClickHandle()}}
                >
                    Historia zmian { zmianyGrupyPokaz ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                </span>
            </h4>
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć ten wpis? ${trashId}`}
                f={usunGrupe}
                id={trashId}
            />
            { zmianyGrupyPokaz && zmiany}
        </div>
    )
}

export default MieszkancyDaneGrupyHistoriaZmian;