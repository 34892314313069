import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import axios from "axios";
import '../../scss/style.css';
import '../../scss/form.css';
import { fetchMieszkancy, fetchUsers } from '../../Redux/Actions';
import DialogOk from '../DialogOk'; 

const MieszkancyDaneEditForm = (props) => {

    const dispatch = useDispatch();

    const { mieszkaniec, id_wspolnoty, setEdit } = props;

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const [values, setValues] = useState({
        imieNazwisko: "",
        telefon: "",
        email: "",
        czyZarzad: false,
        czyNota: false,
        nip: "",
    })

    useEffect( () => {
        setValues({
            imieNazwisko: mieszkaniec?.imie_nazwisko,
            telefon: mieszkaniec?.telefon,
            email: mieszkaniec?.email,
            czyZarzad: mieszkaniec?.czy_zarzad === "true" || mieszkaniec?.czy_zarzad == true ? true : false,
            czyNota: mieszkaniec?.czy_nota === "true" || mieszkaniec?.czy_nota == true ? true : false,
            nip: mieszkaniec?.nip,
            notaFirma: mieszkaniec?.notaFirma,
            notaAdres: mieszkaniec?.notaAdres,
            notaKodMiasto: mieszkaniec?.notaKodMiasto
        })
    },[mieszkaniec])
    
    const editData = async () => {

        if (mieszkaniec?.telefon !== values?.telefon) {
            try {
                await axios.post('/api/user/edytuj', {
                    user: values?.telefon,
                    id_user: mieszkaniec?.id_user
                }).then( response => {
                    dispatch(fetchUsers());
                })
            } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }

        try {
          // Wysyłamy dane na serwer
          await axios.post('/api/mieszkaniec/dodaj', {
            id_wspolnoty: id_wspolnoty,
            id_user: mieszkaniec?.id_user,
            imie_nazwisko: values.imieNazwisko,
            telefon: values.telefon,
            email: values.email,
            data_od: values.dataOd,
            czy_zarzad: values.czyZarzad,
            status: "",
            czy_nota: values.czyNota,
            nip: values.nip,
            notaFirma: values.notaFirma,
            notaAdres: values.notaAdres,
            notaKodMiasto: values.notaKodMiasto
          }).then( response => {
            if (response.status === 200) {
                dispatch(fetchMieszkancy()); 
                setEdit(false);
            }

        })
        } catch (error) {
        console.error('Błąd podczas dodawania danych:', error);
      }
    };
    
    const handleAnuluj = () => setEdit(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setValues(prevValues => ({
          ...prevValues,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    return (
        <div className="content">
            <DialogOk 
                open={open}
                setOpen={setOpen}
                message={message}
            />
            <h3 className="nazwa">
                Edytuj dane mieszkańca
            </h3>
            <div className="addForm">
                <div className='wiersz'>
                    <span>
                        Imię i nazwisko:
                    </span>
                    <input 
                        type="text"
                        name="imieNazwisko"
                        value={values.imieNazwisko}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Telefon (login):
                    </span>
                    <input
                        type="text"
                        name="telefon"
                        value={values.telefon}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        E-mail:
                    </span>
                    <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Czy zarząd?
                    </span>
                    <input
                        type="checkbox"
                        name="czyZarzad"
                        checked={values.czyZarzad}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Czy nota?
                    </span>
                    <input
                        type="checkbox"
                        name="czyNota"
                        checked={values.czyNota}
                        onChange={handleChange}
                    />
                </div>
                {
                    values.czyNota == 1 ? (
                        <>
                            <div className='wiersz'>
                                <span>
                                    Nota - nazwa firmy:
                                </span>
                                <input
                                    type="text"
                                    name="notaFirma"
                                    value={values.notaFirma}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    Nota - ulica, nr budynku/lokalu:
                                </span>
                                <input
                                    type="text"
                                    name="notaAdres"
                                    value={values.notaAdres}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    Nota - Kod pocztowy i miejscowość:
                                </span>
                                <input
                                    type="text"
                                    name="notaKodMiasto"
                                    value={values.notaKodMiasto}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    NIP:
                                </span>
                                <input
                                    type="text"
                                    name="nip"
                                    value={values.nip}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    ) : ""
                }

                <div className='wiersz'>
                    <span>
                        Data utworzenia:
                    </span>
                    <input
                        type="date"
                        name="dataOd"
                        value={values.dataOd}
                        onChange={handleChange}
                    />
                </div>
                <div className='buttons'>
                    <button 
                        onClick={editData}
                    >
                        Zapisz
                    </button>
                    <button
                        onClick={handleAnuluj}
                    >
                        Anuluj
                    </button>
                </div>
            </div>       
        </div>
    )


}

export default MieszkancyDaneEditForm;