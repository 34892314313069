import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { AuthContext} from '../../Context/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import scrollToTop from '../../functions/ScrollToTop';
import { wstepy, opinie, ofertaSlajdy, once } from '../../functions/motions';
import { faMoneyBills, faGears, faListCheck } from '@fortawesome/free-solid-svg-icons'
import '../../scss/style.css';
import '../../scss/onas.css';
import Strzalka from '../Strzalka';

const ONas = () => {

    const navigate = useNavigate();

    const { userId } = useContext(AuthContext);

    const [k1, setK1] = useState(true);
    const [k2, setK2] = useState(false);
    const [k3, setK3] = useState(false);

    const handleK1 = () => {
        setK1(true);
        setK2(false);
        setK3(false);
    }

    const handleK2 = () => {
        setK1(false);
        setK2(true);
        setK3(false);
    }

    const handleK3 = () => {
        setK1(false);
        setK2(false);
        setK3(true);
    }

    const handleKontakt = () => {
        navigate(`/kontakt`);
    }

    const hover = {
        scale: 1.08,
        transition: {
            duration: 0.1
        }
    }

    useEffect( ()=> {
        scrollToTop();
    },[])

    useEffect( () => {
        const id_temp = userId ? userId : 0;
        axios.post("/api/log", { id_user: id_temp, akcja: "O Nas" })
    },[])

    return (
        <div className='oNas'>
            <div className='text'>
                <motion.div className='misja cienie'
                    variants={wstepy}
                    initial="initial"
                    animate="animate"
                >
                    <div className='opis'>
                        <h3>NASZA MISJA</h3> 
                        <div className='separator'></div>
                        <div>
                            <span>
                                <p>
                                    Naszym celem jest, aby wszyscy Właściciele zrozumieli, że ich decyzje mają realny wpływ na wszelkie działania na nieruchomości.
                                </p>
                                <p>
                                    Podejmowane przez Państwa decyzje w postaci uchwał, które Administrator, Zarządca z mocy
                                    Ustawy o własności lokali zobowiązany jest realizować to najlepszy sposób wyrażania własnego zdania.
                                </p>        
                                <p> 
                                    Tylko ścisła współpraca Właściciel – Zarządca, Administrator daje gwarancję realizacji celów.
                                </p>
                                <p>
                                    Zebrania Wspólnot to nie przykry obowiązek, a możliwość nakreślenia celów działania i sposobów ich
                                    realizacji.
                                </p>
                                <p>
                                    Naszą misją jest uświadomienie Właścicieli, że ich decyzje mają wpływ na wartość oraz estetykę
                                    nieruchomości.
                                </p>                
                                <p>
                                    Jak sama nazwa wskazuje Wspólnota to wspólne dobro którym chcemy się zaopiekować!
                                </p>                
                                <p>
                                    Działamy w sposób SPRAWNY PROFESJONALNY I ELASTYCZNY
                                </p>
                                <p>
                                    Najważniejszy dla nas akt prawny to <a href="https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU19940850388/U/D19940388Lj.pdf" target="_blank">Ustawa o własności lokali</a>.
                                </p>
                            
                            </span>
                        </div>
                    </div>
                    <div className='photo'>
                        <div className='p1'/>
                        <div className='p2'/>
                    </div>
                </motion.div>
                
{/* 
                <p>Naszym celem jest, aby wszyscy Właściciele zrozumieli, że ich decyzje mają realny wpływ na wszelkie działania na nieruchomości.</p>
                <p>Podejmowane przez Państwa decyzje w postaci uchwał, które Administrator, Zarządca z mocy Ustawy o własności lokali zobowiązany jest realizować to najlepszy sposób wyrażania własnego zdania.</p>
                <p>Tylko ścisła współpraca Właściciel - Zarządca, Administrator daje gwarancję realizacji celów. Zebrania Wspólnot to nie przykry obowiązek, a możliwość nakreślenia celów działania i sposobów ich realizacji.</p>
                <p>Naszą misją jest uświadomienie Właścicieli, że ich decyzje mają wpływ na wartość oraz estetykę nieruchomości.</p>
                <p>Jak sama nazwa wskazuje, Wspólnota to wspólne dobro którym chcemy się zaopiekować! Działamy w sposób SPRAWNY PROFESJONALNY I ELASTYCZNY.</p>
                <p>Najważniejszy dla nas akt prawny to ustawa o własności lokali - link ustawy z tekstem jednolitym.</p> */}

                <div className='oferta'>
                    <h3>NASZA OFERTA</h3>
                    <div className='separator'></div>
                    <span>System zapewnia właścicielom dostęp online do zaliczek, rozliczeń i stanów ich lokali, <br/>a Zarządom Wspólnot umożliwia przegląd zbiorczych zestawień.</span>
                </div>
            
                <div className='kategorie'>
                    <motion.div 
                        className={ k1 ? 'kategoria cienie active' : 'kategoria cienie' } 
                        onClick={()=>handleK1()}
                        variants={opinie}
                        initial="initial"
                        whileInView="animate1"
                        whileHover={hover}
                        viewport={once}
                    >
                        <div className='ikona'>
                            <FontAwesomeIcon icon={faMoneyBills} style={{color: "#307bfd",}} size="2xl" />
                        </div>
                        <h3>
                            Obsługa Księgowa
                        </h3>
                        <span>
                            Koncentrujemy się precyzyjnym rozliczeniu finansów, dostarczając mieszkańcom transparentne raporty finansowe.
                        </span>
                    </motion.div>
                    <motion.div 
                        className={ k2 ? 'kategoria cienie active' : 'kategoria cienie' } 
                        onClick={()=>handleK2()}
                        variants={opinie}
                        initial="initial"
                        whileInView="animate2"
                        whileHover={hover}
                        viewport={once}
                    >
                        <div className='ikona'>
                            <FontAwesomeIcon icon={faListCheck} style={{color: "#307bfd",}} size="2xl" />
                        </div>
                        <h3>
                            Bieżące Funkcjonowanie
                        </h3>
                        <span>
                            Reagujemy szybko na wszelkie problemy  by zapewnić mieszkańcom komfort i bezpieczeństwo w ich otoczeniu.
                        </span>
                    </motion.div>
                    <motion.div 
                        className={ k3 ? 'kategoria cienie active' : 'kategoria cienie' } 
                        onClick={()=>handleK3()}
                        variants={opinie}
                        initial="initial"
                        whileInView="animate3"
                        whileHover={hover}
                        viewport={once}
                    >
                        <div className='ikona'>
                            <FontAwesomeIcon icon={faGears} style={{color: "#307bfd",}} size="2xl" />
                        </div>
                        <h3>
                            Obsługa techniczna 
                        </h3>
                        <span>
                            Zapewniamy fachową opiekę nad infrastrukturą dbając  o należyte utrzymanie nieruchomości.
                        </span>
                    </motion.div>                                        
                </div>

 
                

                {/* <ul>
                    <li>
                        Dostęp każdego właściciela do internetowej platformy z zaliczkami, rozliczeniami oraz saldami do konta własnego lokalu
                    </li>
                    <li>
                        Dostęp Zarządów Wspólnot do zbiorczych zestawień
                    </li>
                </ul>  */}



                { k1 && (
                    <motion.div className='opisKategorii'
                        variants={ofertaSlajdy}
                        initial="initial"
                        whileInView="animate"
                        viewport={once}
                    > 
                        <div className='img img1' />
                        <div className='text'>
                            <h3>OBSŁUGA KSIĘGOWA</h3>
                            <ul className='c'>
                                <li>
                                    Otwarcie rachunku bankowego dla Wspólnoty i dokonywanie rozliczeń poprzez rachunek bankowy,
                                </li>
                                <li>
                                    Rozliczanie się z właścicielami wyodrębnionych lokali z opłat wnoszonych na pokrycie kosztów zarządu nieruchomością wspólną w ciągu roku, w formie miesięcznych zaliczek,
                                </li>          
                                <li>
                                    Sporządzanie i składanie odpowiednich sprawozdań i deklaracji we właściwych Urzędach,
                                </li>      
                                <li>
                                    Opłacanie podatków i innych opłat publicznoprawnych związanych z nieruchomością wspólną,
                                </li>
                                <li>
                                    Pobieranie i windykacja należności z tytułu wnoszonych zaliczek, pożytków oraz innych przychodów z nieruchomości wspólnej
                                </li>
                                <li>
                                    Wykonywanie rozliczeń mediów, np.: woda, centralne ogrzewanie, itp..
                                </li>
                                <li>
                                    Przygotowywanie dokumentów do reprezentowania Wspólnoty w sprawach windykacji należności z tytułu wnoszonych zaliczek, pożytków i innych przychodów z nieruchomości wspólnej przed Sądami Powszechnymi,
                                </li>
                                <li>
                                    Każdy właściciel może na bieżąco kontrolować stan swojego konta oraz wysokości zaliczek na koszty utrzymania nieruchomości.
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                )}

                

                { k2 && (
                    <motion.div className='opisKategorii'
                        variants={ofertaSlajdy}
                        initial="initial"
                        whileInView="animate"
                        viewport={once}
                    > 
                        <div className='img img2' />
                        <div className='text'>
                            <h3>
                                BIEŻĄCE FUNKCJONOWANIE
                            </h3>
                            <ul>
                                <li>
                                    Utrzymywanie czystości na terenie Wspólnoty
                                </li>
                                <li>
                                    Reprezentowanie Wspólnoty przy zawieraniu wszelkich umów na dostawę mediów czy obsługę techniczną nieruchomości,
                                </li>
                                <li>
                                    Ubezpieczenie części wspólnych nieruchomości,
                                </li>
                                <li>
                                    Przygotowywanie, zwoływanie i obsługa zebrań Wspólnoty Mieszkaniowej,
                                </li>
                                <li>
                                    Przygotowywanie projektów planu gospodarczego,
                                </li>
                                <li>
                                    Sporządzanie sprawozdań i rozliczeń z wykonania planu gospodarczego,
                                </li>
                                <li>
                                    Przeprowadzanie głosowań w formie uchwał
                                </li>
                                <li>
                                    Prowadzenie wykazu właścicieli wyodrębnionych lokali oraz wprowadzanie zmian dotyczących stanu prawnego po zgłoszeniu tych zmian przez właścicieli,
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                )}

                { k3 && (
                    <motion.div className='opisKategorii'
                        variants={ofertaSlajdy}
                        initial="initial"
                        whileInView="animate"
                        viewport={once}
                    > 
                        <div className='img img3' />
                        <div className='text'>
                            <h3>
                                SPRAWY TECHNICZNE
                            </h3>
                            <ul>
                                <li>
                                    Zapewnienie bieżącej konserwacji i bieżących napraw części wspólnych nieruchomości, umożliwiających właścicielom lokali korzystanie z oświetlenia, ogrzewania lokalu, zimnej wody, domofonu, windy, itp.,
                                </li>
                                <li>
                                    Zapewnieni 24 godzinnego pogotowia technicznego w przypadku powstania awarii w częściach wspólnych ( instalacja centralnego ogrzewania, piony wodno-kanalizacyjne, itp.),
                                </li>
                                <li>
                                    Zlecanie kontroli technicznych i okresowych przeglądów nieruchomości i urządzeń wspólnych, zgodnie z wymaganiami Prawa budowlanego,
                                </li>
                                <li>
                                    W przypadku konieczności wykonania prac remontowych i innych na częściach wspólnych, przygotowanie ofert do wyboru dla Wspólnoty,
                                </li>
                                <li>
                                    Prowadzenie książki obiektu
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                )}

                <div className='ocena'>
                    <div className='text'>
                        <p>Oczywiście cena naszej usługi interesuje Państwa najbardziej.</p> 
                        <p>Koszt naszej usługi jest zależny od indywidulanej wyceny i wielu czynników. Dlatego zapraszamy do rozmów i indywidulanego kontaktu. Jesteśmy elastyczni i pełni zapału do pracy. Czekamy na <span className='link' onClick={()=>handleKontakt()}>kontakt</span> z Państwa strony!</p>
                    </div>
                </div>
            </div>
            <Strzalka/>
        </div>
    )
}

export default ONas;