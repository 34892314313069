import React, { useContext, useState, useEffect } from "react";

const MieszkaniecInformacje = props => {

    const { mieszkaniec } = props;

    const mieszkaniecDane = (
        <ul className="lista mieszkaniecDane">
            <li>
                <div>Imię i nazwisko</div>
                <div>{mieszkaniec?.imie_nazwisko}</div>
            </li>
            <li>
                <div>Telefon</div>
                <div>{mieszkaniec?.telefon}</div>
            </li>
            <li>
                <div>E-mail</div>
                <div>{mieszkaniec?.email}</div>
            </li>
            {/* <li>
                <div>Data zasiedlenia</div>
                <div>{dataFormat(mieszkaniec?.data_zasiedlenia)}</div>
            </li> */}
        </ul>
    );


    return (
        <div>
            {mieszkaniecDane}
        </div>
    )
}

export default MieszkaniecInformacje;