const jednostki_lista = [
    ["1","per lokal"], 
    ["2", ""],
    ["3", "per osoba"],
    ["4", ""],
    ["5", "p.u. lokalu"],
    ["6", ""],
    ["7", ""],
    ["8", ""],
    ["9", "całkowita p.u."],
    ["10", ""],
    ["11", ""],
    ["12", ""],
    ["13", ""],
    ["14", ""],
    ["15", ""],
    ["16", "p.u. garażu"],
    ["17", "p.u. strychu"],
    ["18", "p.u. komórki"],
    ["19", "m3"]

  ];

export const jednostki = (word) => { 
    let result = jednostki_lista.filter((a)=>a[0]===word);
    return result.length > 0 ? result[0][1] : word;
}

