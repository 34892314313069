import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotyStawkiJednorazoweAddForm from "./WspolnotyStawkiJednorazoweAddForm";
import WspolnotyStawkiJednorazoweListaWszystkich from "./WspolnotyStawkiJednorazoweListaWszystkich";



const WspolnotyStawkiJednorazowe = props => {

    const { wspolnota } = props;

    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);

    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data );

    useEffect( () => {
        setStawkiJednorazowe_w( stawkiJednorazowe?.filter( s => s?.id_wspolnoty == wspolnota?.id ) )
    },[stawkiJednorazowe, wspolnota]);

    return (
        <div className="stawkiJednorazowe">
            <WspolnotyStawkiJednorazoweAddForm
                wspolnota={wspolnota}
            />
            <WspolnotyStawkiJednorazoweListaWszystkich
                stawkiJednorazowe_w={stawkiJednorazowe_w}
            />  
        </div>
    )
}

export default WspolnotyStawkiJednorazowe;