import React, {  } from 'react';

const MieszkancyDaneEdytujGrupeMieszkanie = (props) => {

    const { liczbaDomownikow, setLiczbaDomownikow, mieszkanieAdres, zamknijMieszkanie, setZamknijMieszkanie } = props;

    const handleChange = e => {
        setLiczbaDomownikow( e.target.value );
    }

    const handleClick = e => {
        setZamknijMieszkanie(prevState => !prevState)
    }

    return (
        <>
            <li>
                <div>
                    Mieszkanie:
                </div>
                <div>
                    {mieszkanieAdres}
                </div>
            </li>     
            <li>
                <div>
                    Liczba domowników:
                </div>
                <div>
                    <input 
                        type="number"
                        value={liczbaDomownikow}
                        onChange={handleChange}
                    /> 
                </div>
            </li>                  
            <li>
                <div>
                    Zaznacz, aby zamknąć mieszkanie
                </div>
                <div>
                    <input 
                        type="checkbox"
                        id="mieszkanieCheckbox"
                        value={zamknijMieszkanie}
                        onChange={handleClick}
                    />
                </div>
            </li>
        </>
    )

} 

export default MieszkancyDaneEdytujGrupeMieszkanie;