import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import '../../scss/style.css';
import '../../scss/wydruk.css';
import { zl, pln, formatKonta, slownie } from '../../functions/utilities';
import { translate, rodzaj_rozliczenia } from "../../functions/dictionary";
import { getTablicaCzynsze2, hasKey } from '../../functions/tablice';
import { findMieszkanieId } from "../../functions/ulice.js";
import { obliczDatyNoty } from '../../functions/dataFormat.js';

import { useReactToPrint } from 'react-to-print';
// import { fetchWspolnotyStawkiJednorazowe } from '../../Redux/Actions';

const MieszkancyNota = (props) => {

    const { showPrint, mieszkaniec, wspolnota, lokale, nota, udzialyKontaAkt_u} = props;

    const stawki = useSelector( state => state?.stawki?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    
    const [grupy_u, setGrupy_u] = useState([]);
    const [mieszkanie, setMieszkanie] = useState([]);
    const datyNoty = obliczDatyNoty();
    const [numerNoty, setNumerNoty] = useState(0);

    useState( () =>{
        setGrupy_u( grupy?.filter( g => g?.id_user == mieszkaniec?.id_user))
    },[grupy, mieszkaniec]);
    
    const [udzialGrupaNota, setUdzialGrupaNota] = useState([]);
    const [tytul, setTytul] = useState("Nota księgowa");
    
    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState([]);
    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);

    const [stawki_w, setStawki_w] = useState([]);

    useEffect( () => {
        setStawki_w( stawki?.find( s => s?.id_wspolnoty == wspolnota?.id ));
    }, [stawki, wspolnota])

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie /*, zaliczki */))
      },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie /*, zaliczki */])

    useEffect( () => {
        const tablicaCzynsze_w = tablicaCzynsze?.find( t => t.id_wspolnoty == wspolnota?.id);
        const tablicaCzynsze_u = tablicaCzynsze_w?.grupy?.find( t => t.id_user == mieszkaniec?.id_user );

        setTablicaCzynsze_g( tablicaCzynsze_u?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzialGrupaNota.grupa_numer )  );
        
    },[tablicaCzynsze, mieszkaniec, wspolnota, udzialGrupaNota]);

    useEffect( () => {
        setUdzialGrupaNota( udzialyKontaAkt_u?.find( u => u?.id == nota) )
    },[nota, udzialyKontaAkt_u]);

    useEffect( () => {
        const tablicaCzynszeNoty = tablicaCzynsze?.find( t => t.id_wspolnoty == udzialGrupaNota?.id_wspolnoty)?.grupyNoty;
        const nr = tablicaCzynszeNoty?.find( t => t?.id_user == udzialGrupaNota?.id_user && t.grupa_numer == udzialGrupaNota?.grupa_numer)?.notaNr;
        setNumerNoty(nr)
    },[udzialGrupaNota, tablicaCzynsze])

    useEffect( () => {

        const czyMieszkanie = udzialGrupaNota?.czy_mieszkanie_w_grupie;
        const grupa_numer = grupy_u?.filter( g => g?.grupa_numer == udzialGrupaNota?.grupa_numer) ;
        
        const lokaleArr = grupa_numer?.map( g => 
            {

                return ({
                    czy_mieszkanie_w_grupie: czyMieszkanie,
                    grupa_numer: g.grupa_numer,
                    id: g.id,
                    id_lokal: g.id_lokal,
                    rodzaj: lokale?.find( lokal => lokal.id == g.id_lokal)?.rodzaj,
                    id_user: g.id_user,
                    imie_nazwisko: mieszkaniec?.imie_nazwisko,
                    id_wspolnoty: g.id_wspolnoty,
                    data_od: g.data_od,
                    data_do: g.data_do,
                    wersja: g.wersja,
                    udzial: lokale?.find( lokal => lokal.id == g.id_lokal)?.udzial
                })
        })

        
        
        const mieszkanieLista = [];
        const lokalDane = id => lokale?.filter( lokal => lokal.id == id)[0];
        const ulicaDane = id => ulice?.filter( ulica => ulica.id == id)[0];
        if (czyMieszkanie) {
            const mieszkanieId = findMieszkanieId(udzialGrupaNota, grupy_u, lokale);
            
            const mieszkanie = lokaleArr?.find( lokal => lokal?.id_lokal == mieszkanieId );
            
            const lokal = lokalDane(mieszkanie?.id_lokal);
            const ulica = ulicaDane(lokal?.ulica);
            const klatka = lokal?.klatka !== null && lokal?.klatka !== undefined && lokal?.klatka?.length > 0 ?  ` (klatka ${lokal?.klatka})` : "";
            const metraz = lokalDane(mieszkanie?.id_lokal)?.metraz; 
            const udzial = lokalDane(mieszkanie?.id_lokal)?.udzial;
            
            mieszkanieLista.push({
                ulica,
                lokal: lokal?.numer,
                klatka,
                metraz,
                udzial,
                grupa_numer: mieszkanie?.grupa_numer,
                grupa_id: mieszkanie?.id,
                liczba_domownikow: udzialGrupaNota?.liczba_domownikow,
                wersja: mieszkanie?.wersja
            });
            
            setMieszkanie(mieszkanieLista[0])
        }   
        
    },[udzialGrupaNota])
    
    useEffect( () => {
        let len = tablicaCzynsze_g?.temp_msc?.length;
        if (len && len > 1)
            setTablicaCzynszeAkt( tablicaCzynsze_g?.temp_msc[len - 2] );
        else if (len && len === 1)
            setTablicaCzynszeAkt( tablicaCzynsze_g?.temp_msc[0] );
    },[tablicaCzynsze_g])

    const podstawa = (tablicaCzynszeAkt, key) => {
        const klucz = key + "_podstawa";
        return tablicaCzynszeAkt[klucz];
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });
    
    const tabela = (stawki_w) => {
        const elements = [];
        let i = 1;
        if (stawki_w) {
            const hasOwn = Object.prototype.hasOwnProperty;
            for (const key in stawki_w) {
                if (hasOwn.call(stawki_w, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki_w[key];
                    const rodzaj = stawki_w[rodzajKey];
                    const czyUwzgledniany = stawki_w[czyKey] && !isNaN(tablicaCzynszeAkt[key]);
                    
                    const podstawa_ = rodzaj_rozliczenia(rodzaj, key) == "opłata stała" ? "1" :  podstawa(tablicaCzynszeAkt, key);

                    if (czyUwzgledniany && cena > 0 && tablicaCzynszeAkt[key] > 0) {
                        elements.push(
                            <tr key={key}>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >    
                                    {i++}                                     
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                    }}
                                >
                                    {translate(key)}                        
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {rodzaj_rozliczenia(rodzaj, key)}       
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    { isNaN(podstawa_) ? 0 : podstawa_}      
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {zl(cena)}                              
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'right',
                                    }}
                                >    
                                    {zl(tablicaCzynszeAkt[key])}            
                                </td>
                            </tr>
                        );
                    }
                }
            }
            return elements;
        } else return ""
    };
    
    const handleModalClick = (event) => event.stopPropagation();
    const handleChangeTytul = e => setTytul(e.target.value)

    return (
        <div className="mieszkaniecWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                
                <div 
                    style={{
                        width: '21cm',
                        height: '29.7cm',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        padding: '15mm 15mm',
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }} 
                    ref={componentRef}
                >
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '13px'
                        }}
                    >
                        <div>
                            <span>
                                <b>Wspólnota</b><br/>
                                {wspolnota?.name}<br/>
                                {`${wspolnota?.ulica} ${wspolnota?.nr_domu}`}<br/>
                                {`${wspolnota?.kod_pocztowy} ${wspolnota?.miejscowosc}`}<br/>
                                {`NIP: ${wspolnota?.nip}`}
                            </span>
                        </div>
                        <div>
                            <span>
                                ORYGINAŁ
                            </span>
                        </div>
                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '16px',
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <input
                            style={{
                                display: "block",
                                width:"100%",
                                type:"text",
                                fontWeight:"500",
                                border:"none",
                                textAlign: "center",
                                fontSize: "16px"
                            }}
                            value={`Nota księgowa ${numerNoty}/${datyNoty?.miesiacTytul}`}
                            
                            onChange={handleChangeTytul}
                        />
                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: 'right',
                            fontSize: '13px',
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <div>
                            <span
                                style={{fontWeight: "600"}}
                            >
                                Adresat:<br/>
                                {mieszkaniec?.notaFirma}<br/>
                                {`${mieszkaniec?.notaAdres}`}<br/>
                                {`${mieszkaniec?.notaKodMiasto}`}<br/>
                                {`NIP: ${mieszkaniec?.nip}`}

                            </span>
                        </div>
                        
                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: "left",
                            fontSize: '13px',
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <div
                            style={{
                                marginRight: "30px",
                                width: "110px"
                            }}
                        >
                            <span>
                                Sposób zapłaty:<br/>
                                Data sprzedaży:
                            </span>
                        </div>
                        <div
                            style={{
                                marginRight: "120px"
                            }}
                        >
                            <span>
                                przelew<br/>
                                {datyNoty?.dataSprzedazy}<br/>
                            </span>
                        </div>
                        <div
                            style={{
                                marginRight: "30px",
                                width: "110px"
                            }}
                        >
                            <span>
                                Termin zapłaty:<br/>
                                Miesiąc nalicz.:
                            </span>
                        </div>
                        <div>
                            <span>
                                {datyNoty?.terminZaplaty}<br/>
                                {datyNoty?.miesiacNaliczenia}
                            </span>
                        </div>
                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: "left",
                            fontSize: '13px',
                            margin: '1mm 0 0 0'
                        }}
                    >
                        <div
                            style={{
                                marginRight: "30px",
                                width: "110px"
                            }}
                        >
                            <span>
                                Dotyczy lokalu:
                            </span>
                        </div>
                        <div
                            style={{
                                marginRight: "120px"
                            }}
                        >
                            <span>
                                {`${mieszkanie?.ulica?.rodzaj} ${mieszkanie?.ulica?.nazwa} ${mieszkanie?.ulica?.numer_budynku} / ${mieszkanie?.lokal} ${mieszkanie?.klatka}`}
                            </span>
                        </div>

                    </section>
                    <section
                        style={{
                            display: 'flex',
                            justifyContent: "left",
                            fontSize: '13px',
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <div
                            style={{
                                marginRight: "30px"
                            }}
                        >
                            <span>
                                Konto bankowe:
                            </span>
                        </div>
                        <div>
                            <span>
                                {formatKonta(udzialGrupaNota?.konto_bankowe)}
                            </span>
                        </div>
                    </section>

                    <section
                        style={{
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <table
                            style={{
                                fontSize: '13px',
                                borderCollapse: 'collapse',
                                width: '100%'
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Lp.             </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Tytył zaliczki  </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    j.m.            </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    ilość           </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    cena j.         </th>
                                    <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Kwota zaliczki  </th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                { tabela(stawki_w) }
                                <tr>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                    <td
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            padding: '3cm 0 0 0'
                                        }}
                                    >

                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: '1px solid black',
                                            textAlign: 'right',
                                            padding: '1mm 2mm',
                                            fontWeight: '600'
                                        }}
                                        colSpan={5}
                                    >
                                        Razem:
                                    </td>
                                    <td 
                                        style={{
                                            border: '1px solid black', 
                                            padding: '1mm',
                                            textAlign: 'right',
                                            fontWeight: '600'
                                        }}
                                    >
                                        {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section
                        style={{
                            margin: '10mm 0 0 0',
                            fontSize: '13px',
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <div>
                            <span
                                style={{
                                    marginRight: "20px"
                                }}                        
                            >
                                {`Do zapłaty: `}
                            </span>
                            <span
                                style={{
                                    fontWeight: "600",
                                    marginRight: "20px"
                                }}
                            >
                                {pln(tablicaCzynszeAkt?.czynsz_na_msc)}
                            </span>
                        </div>
                        <div>
                            <span>
                                {`Słownie:  ${slownie(tablicaCzynszeAkt?.czynsz_na_msc)}`}
                            </span>
                        </div>

                    </section>


                </div>

                <div className="buttons">
                    <button 
                        onClick={handlePrint}
                    >
                        Drukuj
                    </button>
                    <button
                        onClick={showPrint}
                    >
                        Anuluj
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MieszkancyNota;