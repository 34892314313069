import { useEffect } from "react";

const MieszkancyDaneDodajGrupeMieszkanie = props => {

    const { setWybraneMieszkanie, domownicy, setDomownicy, ulice, lokale, id_wspolnoty, liczbaDomownikow, setLiczbaDomownikow } = props;

    const wolneMieszkania = lokale?.filter( lokal => lokal.czy_zajety === null && lokal.rodzaj === "mieszkanie" && lokal.id_wspolnoty == id_wspolnoty)
    const mieszkaniaLista = wolneMieszkania?.map( (mieszkanie, i) => {
        const ulica = ulice?.filter( ulica => ulica?.id == mieszkanie?.ulica)[0];
        return (
            <option key={i} value={mieszkanie.id}> 
                {ulica?.nazwa + " " + ulica?.numer_budynku + ", lok. " + mieszkanie?.numer}
            </option>
        )
    })

    const handleWybraneMieszkanie = e => {
        setWybraneMieszkanie(e.target.value);
        if (e.target.value<=0) 
            setDomownicy(false)
        else
            setDomownicy(true)
    }

    useEffect( () => {
        if (liczbaDomownikow < 0 )
            setLiczbaDomownikow(0)
    },[liczbaDomownikow])

    return (
        <div>
            <p>Wybierz mieszkanie:</p>
            <select onChange={handleWybraneMieszkanie}>
                <option value={0}>(wybierz)</option>
                { mieszkaniaLista }
            </select>
            {
                domownicy && (
                    <div>
                        <p>Liczba domowników:</p>
                        <input
                            type="number"
                            value={liczbaDomownikow}
                            onChange={e=>setLiczbaDomownikow(e.target.value)}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default MieszkancyDaneDodajGrupeMieszkanie;