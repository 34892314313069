import React from 'react';
import { Formik, Form } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import FormikControl from "../../Formik/FormikControl";
import '../../../scss/style.css';
import '../../../scss/form.css';
import '../../../scss/wspolnoty.css';
import GoBackBtn from "../../GoBackBtn";

const initialValues = {
    nazwa: '',
    nip: '',
    regon: '',
    ulica: '',
    numer_domu: '',
    numer_lokalu: '',
    kod_pocztowy: '',
    miejscowosc: '',
    telefon: '',
    email: '',
    numer_konta: '',
    status: ''
}

const validationSchema = Yup.object({

})

const addData = async (values) => {
    console.log(values.name)
    try {
      // Wysyłamy dane na serwer
      await axios.post('/api/dodaj_kontrahenta', {
        nazwa: values.nazwa,
        nip: values.nip,
        regon: values.regon,
        ulica: values.ulica,
        numer_domu: values.numer_domu,
        numer_lokalu: values.numer_lokalu,
        kod_pocztowy: values.kod_pocztowy,
        miejscowosc: values.miejscowosc,
        telefon: values.telefon,
        email: values.email,
        numer_konta: values.numer_konta
      });
      // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
    } catch (error) {
      console.error('Błąd podczas dodawania danych:', error);
    }
  };

  const onSubmit = ( values, onSubmitProps) => {
    // console.log('Form data:', values);
    // console.log('Submit props:', onSubmitProps);
    
    addData(values);

    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
}


const KontrahentAdd = () => {
    return (
        <div className="content">
            <h1 className="nazwa">
                Dodaj kontrahenta
            </h1>
            <div className="AddForm">
                <GoBackBtn />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount>
                    {formik => {
                        return (
                            <Form>
                                <FormikControl control='input' type='text' label='Nazwa:' name='nazwa' />    
                                <FormikControl control='input' type='text' label='NIP:' name='nip' />  
                                <FormikControl control='input' type='text' label='REGON:' name='regon' />  
                                <FormikControl control='input' type='text' label='Ulica:' name='ulica' />  
                                <FormikControl control='input' type='text' label='Numer budyku:' name='numer_domu' />  
                                <FormikControl control='input' type='text' label='Numer lokalu:' name='numer_lokalu' />  
                                <FormikControl control='input' type='text' label='Kod pocztowy:' name='kod_pocztowy' />  
                                <FormikControl control='input' type='text' label='Miejscowość:' name='miejscowosc' />  
                                <FormikControl control='input' type='text' label='Numer konta:' name='numer_konta' />  
                                <FormikControl control='input' type='text' label='Telefon:' name='telefon' />  
                                <FormikControl control='input' type='text' label='E-mail:' name='email' />  
                                <button type='submit' disabled={!formik.isValid || formik.isSubmitting}>Zapisz</button>
                                <button type="reset">Wyczyść</button>
                            </Form>
                            )
                    }}
                </Formik>
            </div>       
        </div>
    )

};

export default KontrahentAdd;