import React from "react";
import { useLocation } from 'react-router-dom';
import { zl } from "../../../functions/utilities";

const WspolnotaRaportZbiorowyTFoot = ( props ) => {

    const { miesiac, listaMsc } = props;
    
    const location = useLocation();
    const { state } = location;

    const przelewyWspolnota = state?.from?.tablicaPrzelewy;
    const czynszeWspolnota = state?.from?.tablicaCzynsze;

    const sumujNaleznosciMsc = (msc) => {
        let suma = 0;
        czynszeWspolnota?.forEach( czynsz => {
            czynsz?.grupy_udzial_msc?.forEach( grupa => {
                grupa?.temp_msc?.forEach( temp => {
                    if (temp?.msc === msc && typeof temp?.czynsz_na_msc === 'number' && temp?.czynsz_na_msc > 0) {
                        suma += temp?.czynsz_na_msc;
                        
                    }
                })
            })
        })
        return suma;
    }

    const sumujWplatyMsc = (msc) => {
        let suma = 0;
        przelewyWspolnota?.forEach(przelew => {
            przelew?.przelewy?.forEach( p => {
                if (p?.msc === msc)
                    suma += p?.kwota;
            })
        })
        return suma;
    }

    const podsumowanieMsc = (
        <>
            <td style={{whiteSpace: "nowrap" }}> 
                {zl(sumujNaleznosciMsc(miesiac))}
            </td>
            <td style={{whiteSpace: "nowrap" }}>
                {zl(sumujWplatyMsc(miesiac))}
            </td>
            <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>
                {zl( sumujWplatyMsc(miesiac) - sumujNaleznosciMsc(miesiac) )}
            </td>
        </>
    )

    const podsumowanieRok = listaMsc?.map( msc => (
        <>
            <td style={{ whiteSpace: "nowrap" }}>
                {zl(sumujNaleznosciMsc(msc))}
            </td>
            <td style={{whiteSpace: "nowrap" }}>
                {zl(sumujWplatyMsc(msc))}
            </td>
            <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>
                {zl( sumujWplatyMsc(msc) - sumujNaleznosciMsc(msc) )}
            </td>
        </>
    ))

    const podsumowanieRokTotal = () => {
        let naleznosci = 0, wplaty = 0;
        
        listaMsc?.forEach( m => {
            naleznosci += sumujNaleznosciMsc(m);
            wplaty += sumujWplatyMsc(m)
        });

        return (
            <>
                <td style={{whiteSpace: "nowrap" }}>
                    {zl(naleznosci)}
                </td>
                <td style={{whiteSpace: "nowrap" }}>
                    {zl(wplaty)}
                </td>
                <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>
                    {zl( wplaty - naleznosci)}                    
                </td>
            </>
        )
    }

    const podsumowanieFooter = (
        <tr>
            { miesiac === "2024" ? (
                <>
                    <td colSpan={2}>

                    </td>
                    {podsumowanieRok}
                    {podsumowanieRokTotal()}
                </> ) : (
                <>
                    <td colSpan={2}>
                        
                    </td>
                    {podsumowanieMsc}
                </>
                ) 
            }
        </tr>
    )

    return (
        <tfoot>
            {podsumowanieFooter}
        </tfoot>
    ) 

}

export default WspolnotaRaportZbiorowyTFoot;