import React, { useEffect } from "react";
import { adresPro } from "../../functions/ulice";
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

const ListaMieszkancow = ( props ) => {

  const { wybranyMieszkaniec, setWybranyMieszkaniec, wybranyUdzialKonto, setWybranyUdzialKonto, setMode, setLoadedLicznik, wybranaWspolnota, 
    udzialyKonta, grupyAkt, mieszkancy, lokale, ulice, activeWindow, setZimnaWoda, setCieplaWoda, setDataOd, setPlanowaneZuzycie_u, planowaneZuzycie }  = props;

  const onClickList = (udzialKonto) => {
    switch (activeWindow) {
      case "dodajStanLicznikow":
        
        setMode("new");
        setLoadedLicznik([])
      break;
      case "dodajZuzycie":
        
      break;
      default: return undefined;
    }
    setWybranyUdzialKonto(udzialKonto);
    setWybranyMieszkaniec( mieszkancy?.find( m => m.id_user == udzialKonto?.id_user) );
    

    setZimnaWoda(0);
    setCieplaWoda(0);
    setDataOd("");
  }

  // useEffect(() => {
  //   console.log(wybranyMieszkaniec?.id_user)
  //   setPlanowaneZuzycie_u( planowaneZuzycie?.filter( p => p.id_user == wybranyMieszkaniec?.id_user && p?.grupa_numer ==  wybranyUdzialKonto?.grupa_numer) ); 
  // },[wybranyMieszkaniec, planowaneZuzycie])

  const udzialyKonta_ = udzialyKonta?.filter( u => u.id_wspolnoty == wybranaWspolnota?.id );

  const listaMieszkancow = udzialyKonta_?.map ( (el,i) => {

    const mieszkaniec = mieszkancy?.find( m => m.id_user == el.id_user);
    
    const grupyAkt_u = grupyAkt?.filter( g => g.id_user == el.id_user && g.grupa_numer == el.grupa_numer);
    let id_mieszkania = 0;
    let id_ulicy = 0;
    grupyAkt_u?.forEach( g => {
      
      const lokal = lokale?.find( l => l.id == g.id_lokal);
      
      if (lokal?.rodzaj === "mieszkanie") {
        id_mieszkania = g.id_lokal;
        id_ulicy = lokal.ulica;
      }
    })

    const adres = adresPro(id_mieszkania, lokale, ulice) === "" ? `(brak mieszkania w grupie nr ${el.grupa_numer})` : adresPro(id_mieszkania, lokale, ulice);

    return (
      <motion.li 
        key={i} 
        id={el.id_user} 
        className={el.id_user == wybranyMieszkaniec?.id_user && el?.grupa_numer == wybranyUdzialKonto?.grupa_numer ? "active pointer" : "pointer"} 
        onClick={()=>{onClickList(el)}} 
        variants={wstepy}
        initial="initial"
        animate="animate"
      >
        <div>{ `(ug: ${el?.id}) ${mieszkaniec?.imie_nazwisko}` }</div>
        <div>{adres}</div>
      </motion.li>
    )

  })  

  return (
    <motion.div 
      className='listaMieszkancow'
      variants={wstepy}
      initial="initial"
      animate="animate"
    >
      <ul className="lista">
          <li>
              <div>Imię i nazwisko</div>
              <div>Adres mieszkania</div>   
          </li>
          {listaMieszkancow}
      </ul>
    </motion.div>
  );
}

export default ListaMieszkancow;