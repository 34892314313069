import React from "react";

const WspolnotaRaportZbiorowyTHead = ( props ) => {

    const { miesiac, listaMsc } = props;

    const kolumnyMiesiace = listaMsc?.map( (e, i) => {
        return (
            <>
                <td>
                    należności
                </td>
                <td>
                    wpłaty
                </td>
                <td>
                    saldo
                </td>
            </>
        )

    })
    
    const thDaty = listaMsc?.map( (e,i) => {
        return (
            <th colSpan={3} style={{textAlign: "center"}}>
                {e}
            </th>
        )
    })

    return (
        <thead>
            <tr key={-1}>
                <th rowSpan={2}>
                    Mieszkaniec
                </th>
                <th rowSpan={2}>
                    Adres
                </th>
                {
                    miesiac !== "2024" ? (
                        <th colSpan={3} style={{textAlign: "center"}}>
                            {miesiac}
                        </th>
                    ) : (
                        <>
                            {thDaty}
                            <th colSpan={3} style={{textAlign: "center"}}>
                                2024
                            </th>
                        </>
                    )
                }

            </tr>
            {
                <tr key={0}>
                    {miesiac !== "2024" ? (
                        <>
                            <th>
                                należności
                            </th>
                            <th>
                                wpłaty
                            </th>
                            <th 
                            >
                                saldo
                            </th>
                        </>
                    ) : ( 
                    <> 
                        {kolumnyMiesiace}
                        <td>
                            należność razem
                        </td>
                        <td>
                            wpłaty razem
                        </td>
                        <td>
                            saldo razem
                        </td>
                    </>   
                    )  }
                </tr>
            }
        </thead>
    ) 

}

export default WspolnotaRaportZbiorowyTHead;