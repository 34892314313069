import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import FiltrWspolnoty from "../FiltrWspolnoty"
import FiltrMieszkancy from "../FiltrMieszkancy";
import PrzeksiegowaniaForm from "./PrzeksiegowaniaForm";
import { sortByTxt, sortByNum } from "../../../functions/utilities";


const Przeksiegowania = props => {

    const {wspolnoty, udzialyKontaAkt, mieszkancy, przelewy, tablicaCzynsze, tablicaPrzelewy} = props;
    const dispatch = useDispatch();
    
    const [wybranaWspolnotaId, setWybranaWpolnotaId] = useState(0);
    const [wybranyMieszkaniecId, setWybranyMieszkaniecId] = useState(0)
    const [przelewy_u, setPrzelewy_u] = useState([]);
    const [mieszkancy_w, setMieszkancy_w] = useState([]);
    const [udzialyKontaAkt_u, setUdzialyKontaAkt_u] = useState([]);   

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)  
            setWybranaWpolnotaId( wspolnoty[0]?.id);
    },[wspolnoty, mieszkancy, przelewy]);

    useEffect( () => {
        setPrzelewy_u( przelewy?.filter( p => p.id_user ==  wybranyMieszkaniecId))
    },[przelewy, wybranyMieszkaniecId]);

    useEffect( () => {
        setMieszkancy_w( sortByTxt(mieszkancy?.filter( m => m.id_wspolnoty == wybranaWspolnotaId),"imie_nazwisko"))
    },[mieszkancy, wybranaWspolnotaId]);

    useEffect( () => {
        setUdzialyKontaAkt_u( sortByNum(udzialyKontaAkt?.filter( u => u?.id_user == wybranyMieszkaniecId ), "grupa_numer"))
    },[udzialyKontaAkt, wybranyMieszkaniecId]);

    return (
        <div className="przelewy">
            <h4 className="nazwa">
                Przeksięgowania
            </h4>
            <div className='historia'>
                <div className='filtry'>
                    <FiltrWspolnoty 
                        wspolnoty={wspolnoty}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        setWybranaWpolnotaId={setWybranaWpolnotaId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                    <FiltrMieszkancy 
                        mieszkancy_w={mieszkancy_w}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                </div>
            </div>
            {
                wybranyMieszkaniecId ? (
                    <PrzeksiegowaniaForm
                        przelewy_u={przelewy_u}
                        udzialyKontaAkt_u={udzialyKontaAkt_u}
                        tablicaCzynsze={tablicaCzynsze}
                        tablicaPrzelewy={tablicaPrzelewy}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                    />
                ) : ""
            }


        </div>
    )
}

export default Przeksiegowania;