export const mieszkancyMax = mieszkancy => {  /*ma sens*/
  const najnowszeDaty = {};

  // Iteruj przez obiekty w tablicy "mieszkancy"
  mieszkancy.forEach((mieszkaniec) => {
    const { id_user, data_od } = mieszkaniec;
    const obecnaData = new Date(data_od);

    // Jeśli nie ma jeszcze daty dla tego id_user, obecnaData jest nowsza lub daty są takie same, ale inne_pola są różne
    if (!najnowszeDaty[id_user] || obecnaData > new Date(najnowszeDaty[id_user]) || (obecnaData.toString() === new Date(najnowszeDaty[id_user]).toString() && mieszkaniec.inne_pola !== najnowszeDaty[id_user].inne_pola)) {
      najnowszeDaty[id_user] = { ...mieszkaniec };
    }
  });

  // Zamień obiekty z unikalnym id_user i ich najnowszymi datami na tablicę
  const wynik = Object.values(najnowszeDaty);

  return wynik;
}

export const grupa_udzial_akt = udzialyKonta => {
  // console.log(udzialyKonta)
  const grupyPoKluczach = {};

  // Grupowanie obiektów według id_wspolnoty, id_user, grupy i dnia
  udzialyKonta.forEach((udzial) => {
    // const klucz = `${udzial.id_wspolnoty}-${udzial.id_user}-${udzial.grupa_numer}-${udzial.data_od}`;
    const klucz = `${udzial.id_wspolnoty}-${udzial.id_user}-${udzial.grupa_numer}`;

    // Jeśli nie ma jeszcze wpisu dla danego klucza lub znaleziono nowszą datę utworzenia, aktualizujemy wpis
    if (!grupyPoKluczach[klucz] || new Date(grupyPoKluczach[klucz].data_utworzenia) < new Date(udzial.data_utworzenia)) {
      grupyPoKluczach[klucz] = udzial;
    }
  });

  // Zwracamy wszystkie obiekty z najnowszą datą utworzenia dla każdej kombinacji kluczy jako tablicę
  const temp = Object.values(grupyPoKluczach);
  return temp;
}

export const grupa_max = (grupy) => {
  const grupyPoDniach = {};

  // Grupujemy obiekty według grupy i dnia
  grupy.forEach((grupa) => {
    const klucz = `${grupa.id_wspolnoty}-${grupa.id_user}-${grupa.grupa_numer}-${grupa.data_od}`;

    if (!grupyPoDniach[klucz]) {
      grupyPoDniach[klucz] = [];
    }
    grupyPoDniach[klucz].push(grupa);
  });

  const wynik = [];

  // Dla każdej grupy po dniach znajdujemy najwyższą wersję
  Object.values(grupyPoDniach).forEach((grupyWDniu) => {
    const najwyzszaWersja = Math.max(...grupyWDniu.map(g => g.wersja));

    // Filtrowanie obiektów z najwyższą wersją i dodanie do wyniku
    const grupyZNajwyzszaWersja = grupyWDniu.filter(g => g.wersja === najwyzszaWersja);
    wynik.push(...grupyZNajwyzszaWersja);
  });

  return wynik;
};

export const grupy_all_max = (grupy, wersje) => {
  
  const temp = [];
  wersje?.forEach( w =>  {
    const temp_wew = grupy?.filter( g => g.grupa_numer == w.grupa_numer && g.id_user == w.id_user && g.wersja == w.wersja && g.id_lokal == w.id_lokal);
    temp_wew?.forEach( t => {
      temp.push(t);
    })
  }) 
    
  return temp;
};

function filterHighestVersion(objects) {
  const map = new Map();

  objects.forEach(obj => {
    const key = `${obj.id_user}_${obj.grupa_numer}`;
    const existing = map.get(key);
    if (!existing || existing.wersja < obj.wersja) {
      map.set(key, obj);
    }
  });

  return Array.from(map.values());
}

export const grupy_all_max_akt = (grupy, wersje) => {

  const temp_wersje = wersje?.map( w => {
    return {
      grupa_numer: w.grupa_numer,
      id_user: w.id_user,
      wersja: w.wersja
    }
  })

  const temp_wersje_bez_dubli = filterHighestVersion(temp_wersje);
  const temp = [];
  temp_wersje_bez_dubli?.forEach( w =>  {
    const temp_wew = grupy?.filter( g => g.grupa_numer == w.grupa_numer && g.id_user == w.id_user && g.wersja == w.wersja && (g.data_do == null || new Date(g.data_do) >= new Date())
   );
    temp_wew?.forEach( t => {
      temp.push(t);
    })
  }) 
  return temp;
};

export const wersjaMax = (grupy) => {
  const mapaNajwyzszychWersji = new Map();

  grupy.forEach((element) => {
    const { grupa_numer, wersja, id_user, id_lokal } = element;

    // Klucz mapy będzie teraz kombinacją grupa_numer i id_user
    const klucz = `${grupa_numer}-${id_user}-${id_lokal}`;

    if (mapaNajwyzszychWersji.has(klucz)) {
      const aktualnaNajwyzszaWersja = mapaNajwyzszychWersji.get(klucz);
      if (wersja > aktualnaNajwyzszaWersja) {
        mapaNajwyzszychWersji.set(klucz, wersja);
      }
    } else {
      mapaNajwyzszychWersji.set(klucz, wersja);
    }
  });

  const wynikowaTablica = Array.from(mapaNajwyzszychWersji, ([klucz, wersja]) => {
    // Rozdzielamy klucz na grupa_numer i id_user
    const [grupa_numer, id_user, id_lokal] = klucz.split('-');
    return {
      grupa_numer,
      wersja,
      id_user,
      id_lokal
    };
  });

  return wynikowaTablica;
};

export const mieszkanie_wersja_max = mieszkania => {
  // console.log(mieszkania)
  if (mieszkania.length === 0) {
    return null;
  }

  let obiektZNajwyzszaWersja = mieszkania[0];

  for (const obiekt of mieszkania) {
    if (obiekt.wersja > obiektZNajwyzszaWersja.wersja) {
      obiektZNajwyzszaWersja = obiekt;
    }
  }
  
  return obiektZNajwyzszaWersja;
}

export const lokale_wersja_max = (obiekty) => {
  if (obiekty.length === 0) {
    return [];
  }

  let najwyzszaWersja = obiekty[0].wersja;

  // Pierwsza pętla znajduje najwyższą wartość wersja
  for (const obiekt of obiekty) {
    if (obiekt.wersja > najwyzszaWersja) {
      najwyzszaWersja = obiekt.wersja;
    }
  }

  // Druga pętla zbiera wszystkie obiekty z najwyższą wartością wersja
  const obiektyZNajwyzszaWersja = obiekty.filter(obiekt => obiekt.wersja === najwyzszaWersja);

  return obiektyZNajwyzszaWersja;
}

export const usun_duplikaty = obiekty => {
  const unikalne = new Map();

  obiekty.forEach(obiekt => {
    const reprezentacjaJSON = JSON.stringify(obiekt);
    if (!unikalne.has(reprezentacjaJSON)) {
      unikalne.set(reprezentacjaJSON, obiekt);
    }
  });

  return Array.from(unikalne.values());
}