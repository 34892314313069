import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import WspolnotyLokaleLista from "./WspolnotyLokaleLista";
import WspolnotyLokaleDodaj from "./WspolnotyLokaleDodaj";


const WspolnotyLokale = props => {

    const { wspolnota, wybranaUlica } = props;

    const lokale = useSelector( state => state?.lokale?.data);

    const [lokaleUlica, setLokaleUlica] = useState([]);

    const [activeWindow, setActiveWindow] = useState("lista");

    useEffect( () => {
        setLokaleUlica( lokale?.filter( l => l?.ulica == wybranaUlica) )
    },[wybranaUlica, lokale])


    const page = () => {
        switch(activeWindow) {
            case "lista":
                return (   
                    <WspolnotyLokaleLista 
                        lokaleUlica={lokaleUlica}
                        setActiveWindow={setActiveWindow}
                    /> );
            case "dodaj":
                return (
                    <WspolnotyLokaleDodaj
                        idw={wspolnota?.id}
                        setActiveWindow={setActiveWindow}
                        wybranaUlica={wybranaUlica}
                    />
                );
            default: return (<></>);
        }
    }

    return (
        <div className="wspolnotyLokale ramka">
            {page()}
        </div>
    )
}

export default WspolnotyLokale;