import React from "react";


const StanLicznikowFormularzInput = props => {

    const {label, type, state, onChange } = props;

    return (
        <div className="wiersz zuzycie">
            <span>
                {label}
            </span>
            <input 
                type={type}
                value={state}
                onChange={onChange}
            /> 
        </div>
    )

}

export default StanLicznikowFormularzInput;