import React from 'react';
import { dataFormat } from '../../functions/dataFormat';
import { zl } from '../../functions/utilities';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPrint } from '@fortawesome/free-solid-svg-icons'

const MieszkancyRozliczenieSaldo = (props) => {

    const {przelewy, czynsze, wybierzMiesiac, listaMsc, showPrint} = props;

    const listaWplat = przelewy?.map( (przelew, i) => (
        <li key={i}>
            <div>{dataFormat(przelew.data_operacji)}</div>
            <div className='right'>{zl(przelew.kwota)}</div>
        </li>
    ));
    
    const listaNaleznosci = listaMsc?.map( (msc, i) => {

        if (i < listaMsc?.length - 1) {
            let sumaMiesiaca = 0;
            czynsze?.forEach((czynsz) => {
                if (czynsz?.msc == msc)
                    sumaMiesiaca += czynsz?.czynsz_na_msc;
            })
    
            let sumaPrzelewy = 0;
            przelewy?.forEach( przelew => {
                if (przelew.msc === msc)
                sumaPrzelewy+=przelew.kwota;
            })
    
            return (
                <li key={i}>
                    <div
                        onClick={()=>{wybierzMiesiac(msc)}}
                        className='pointer'
                    >
                        {msc}
                    </div>
                    <div className='right'>{zl(sumaMiesiaca)}</div>
                    <div className='right'>{zl(sumaPrzelewy)}</div>
                    <div className='right'>{zl(sumaPrzelewy - sumaMiesiaca)}</div>
                </li>
            )
        }

    });


    const sumaNaleznosci = czynsze?.slice(0, -1).reduce((acc,obj) => {
        if (Object.prototype.hasOwnProperty.call(obj,   "czynsz_na_msc")) {
            return acc + obj?.czynsz_na_msc;
        }
        return acc;
    }, 0);

    const sumaPrzelewow = przelewy?.reduce((acc,obj) => {
        if (Object.prototype.hasOwnProperty.call(obj,"kwota")) {
            return acc + obj?.kwota;
        }
        return acc;
    }, 0);

    return (
        <div className='mieszkancyRozliczenieSaldo'>
            {/* <FontAwesomeIcon 
                icon={faPrint} 
                className='pointer'
                onClick={showPrint}
            /> */}
            <div className="tabele">

                <div className='tabelaNaleznosci'>
                    <h5>Należności</h5>
                    <ul className='lista'>
                        <li>
                            <div>Miesiąc</div>
                            <div className='right'>Czynsz</div>
                            <div className='right'>Wpłaty</div>
                            <div className='right'>Saldo</div>
                        </li>
                        {listaNaleznosci}
                        <li className='lastRow'>
                            <div></div>
                            <div className='right'><b>{zl(sumaNaleznosci)}</b></div>
                            <div className='right'><b>{zl(sumaPrzelewow)}</b></div>
                            <div className='right'><b>{zl(sumaPrzelewow - sumaNaleznosci)}</b></div>
                        </li>
                    </ul>
                </div>
                <div className='tabelaWplaty' >
                    <h5>Wpłaty</h5>
                    <ul className='lista'>
                        <li>
                            <div>Data</div>
                            <div className='right'>Kwota</div>
                        </li>
                        {listaWplat}    
                        {listaWplat?.length > 0 ? (
                            <li className='lastRow'>
                                <div></div>
                                <div className='right'><b>{zl(sumaPrzelewow)}</b></div>
                            </li>
                        ) : <></>}

                    </ul>
                </div>
            </div>

            <div>
                <h5 className='central' style={{"marginTop": "50px"}} >
                    {`Saldo na dzień ${dataFormat(new Date())} wynosi ${zl(sumaPrzelewow - sumaNaleznosci)}.`} 
                </h5>
            </div>
        </div>

    )
}

export default MieszkancyRozliczenieSaldo;