import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import '../../../scss/style.css';
import '../../../scss/listy.css';
import { dataFormat } from "../../../functions/dataFormat.js";
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { Toast } from 'primereact/toast';
import GoBackBtn from '../../GoBackBtn';
import KontrahentBtnAdd from '../Kontrahent/KontrahentBtnAdd';
import FakturaBtnAdd from './FakturaBtnAdd';

import "primereact/resources/themes/lara-light-blue/theme.css"; 
import "primereact/resources/primereact.min.css"; 

const FakturyLista = () => {

    const [ fakturyNaglowek, setFakturyNaglowek ] = useState([]);
    const [ wspolnoty, setWspolnoty ] = useState();
    const [ fakturyPodpozycja, setFakturyPodpozycja] = useState();
    const [ kontrahenci, setKontrahenci ] = useState();
    const [fakturaDoZaplacenia, setFakturaDoZaplacenia] = useState();

    const [ sumaRazem, setSumaRazem ] = useState(0);
    const [ sumaNiezaplacone, setSumaNiezaplacone ] = useState(0);

    const fakturySumaRazem = () => {
        let sumaRazem = 0;
        fakturyNaglowek?.fakturyNaglowek?.data.forEach(element => {
                sumaRazem += parseFloat(element.kwota);
        });
        setSumaRazem(sumaRazem);
        return sumaRazem;
    }

    const fakturySumaNiezaplaone = () => {
        let sumaNiezaplacone = 0;
        fakturyNaglowek?.forEach(element => {
                sumaNiezaplacone += element.czy_zaplacona == 0 ? parseFloat(element.kwota) : 0;
        });
        setSumaNiezaplacone(sumaNiezaplacone);
        return sumaNiezaplacone
    }

    useEffect(()=>{
        const suma = fakturySumaRazem();
        setSumaRazem(suma)
    },[fakturyNaglowek]);

    useEffect(()=>{
        const suma = fakturySumaNiezaplaone();
        setSumaNiezaplacone(suma);
    },[fakturyNaglowek]);

    useEffect(()=>{
        axios.get("/api/fakturyNaglowek")
        .then(response => {
            const { data } = response;
            setFakturyNaglowek( data )
        })            
        axios.get("/api/fakturyPodpozycja")
        .then(response => {
            const { data } = response;
            setFakturyPodpozycja( data )
        })
        axios.get("/api/lista_wspolnot")
        .then(response => {
            const { data } = response;
            setWspolnoty( data )
        })
        axios.get("/api/kontrahenci_nazwy")
        .then(response => {
            const { data } = response;
            setKontrahenci( data )
        })
    },[])

    const toast = useRef(null);

    const czyZaplacona = (data, warunek, id) => {
        const dataFormat = new Date(data);
        const dzis = new Date();
        
        if (warunek === "1") {
            return (
                <span className='green'>tak</span>
            )
        } else if (warunek === "0" && dataFormat >= dzis) {
            return (
                <span className='yellow pointer' onClick={() => {setVisible(true); setFakturaDoZaplacenia(id)}}>nie</span> 
            )
        }  else if (warunek === "0" && dataFormat < dzis) {
            return (
                <span className='red pointer' onClick={() => {setVisible(true); setFakturaDoZaplacenia(id)}}>nie</span> 
            )
        }
    }

    const kontrahentNazwa = (id, kontrahenci) => {
        if (kontrahenci !== undefined) {
            for (let i = 0; i < kontrahenci?.length; i++) {
                if (kontrahenci[i].id == id) {
                  return kontrahenci[i].nazwa;
                }
              }
        } else return id;

    }

    const wsolnotaNazwa = (id, wspolnoty) => {
        if (wspolnoty !== undefined) {
            for (let i = 0; i < wspolnoty.length; i++) {
                if (wspolnoty[i].id == id) {
                  return wspolnoty[i].name;
                }
              }
        } else return id; 

    }

    const fakturyNaglowekLista = fakturyNaglowek?.map( element => (
        <li  key={element.id} className={ element.id % 2 === 0 ? "g": ""}> 
            <div> { kontrahentNazwa(element.kontrahent, kontrahenci) } </div>
            <div> { wsolnotaNazwa(element.id_wspolnoty, wspolnoty) } </div>
            <div> { element.tytul } </div>
            <div> { dataFormat(element.data_wystawienia) } </div>
            <div> { parseFloat(element.kwota) } zł</div>
            <div> { czyZaplacona(element.termin ,element.czy_zaplacona, element.id) } </div>
        </li>
           
    ))

    const [visible, setVisible] = useState(false);

    const accept = () => {
        console.log("zapłacono fakturę ", fakturaDoZaplacenia)
        axios.post("/api/zaplac_fakture", { id: fakturaDoZaplacenia })
        .then(response => {
            const { data } = response;
            setFakturyNaglowek( data )
        }).then(
            axios.get("/api/fakturyNaglowek")
            .then(response => {
                const { data } = response;
                setFakturyNaglowek( data )
            })
        )

    }
    const reject =  () => {
        console.log('no accept');
    }

    return (
        <div className='content'>
            <h1 className="nazwa">
                Lista faktur
            </h1>
            <GoBackBtn />
            <KontrahentBtnAdd />
            <FakturaBtnAdd />
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Czy oznaczyć fakturę jako?" 
                header="Confirmation" icon="pi pi-exclamation-triangle"  accept={accept} reject={reject} 
            />
            <ul className="lista faktury">
                <li className='naglowek' >
                    <div>Kontrahent</div>
                    <div>Wspólnota</div>
                    <div>Tytuł</div>
                    <div>Data wystawienia</div>
                    <div>Kwota brutto</div>
                    <div>Czy zapłacona</div>
                </li>
                { fakturyNaglowekLista }
                <li>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div><span className='suma'>{sumaRazem}zł</span></div>
                    <div><span className='suma red'>{sumaNiezaplacone}zł</span></div>
                </li>
            </ul>

        </div>
    )
}

export default FakturyLista;