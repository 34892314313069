import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import MieszkancyDaneDodajGrupeLokale from './MieszkancyDaneDodajGrupeLokale.js';
import MieszkancyDaneDodajGrupeMieszkanie from './MieszkancyDaneDodajGrupeMieszkanie.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import { dataFormat2 } from '../../functions/dataFormat.js';
import {  
    fetchLokale, 
    fetchGrupy, 
    fetchGrupyAkt,
    fetchWersje, 
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchMieszkancy,
    fetchWspolnoty
  } from '../../Redux/Actions.js'; 

import DialogOk from '../DialogOk.js'

const MieszkancyListaDodajGrupe = (props) => {

    const { mieszkaniec, id_wspolnoty, setActiveWindow, setDodajGrupe, ktoraGrupa } = props;

    const dispatch = useDispatch();

    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const [wybraneMieszkanie, setWybraneMieszkanie] = useState(0);
    const [wybraneLokale, setWybraneLokale] = useState([]);
   
    const [kontoBankowe, setKontoBankowe] = useState("");
    const [liczbaDomownikow, setLiczbaDomownikow] = useState(0)
    const [domownicy, setDomownicy] = useState(false);
    const [dataOd, setDataOd] = useState(dataFormat2(new Date()));

    const [kontoValid, setKontoValid] = useState(true);

    useEffect( () => {
        if ( kontoBankowe?.length !== 26)
            setKontoValid(false);
        else
            setKontoValid(true)
    },[kontoBankowe])

    useEffect( ()=> {
        dispatch(fetchLokale()); 
        dispatch(fetchGrupy()); 
        dispatch(fetchGrupyAkt()); 
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAkt());
    },[dispatch]);
  

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const addData = async (obj) => {
        try {
            await axios.post('/api/grupy/dodaj', {
                id_wspolnoty: obj.id_wspolnoty,
                id_user:obj.id_user,
                grupa_numer: obj.grupa_numer,
                id_lokal: obj.id_lokal,    
                data_od: obj.data_od,
                wersja: 1
            }).then( response => {
                axios.post('/api/lokal/zajmij', {
                    id_lokal: obj.id_lokal,    
                }).then( response => {
                    dispatch(fetchLokale()); 
                    dispatch(fetchGrupy()); 
                    dispatch(fetchGrupyAkt()); 
                    dispatch(fetchUdzialyKonta());
                    dispatch(fetchUdzialyKontaAkt());
                    dispatch(fetchMieszkancy());
                    dispatch(fetchWspolnoty()); 
                });
            })
        } catch (error) {
            console.log(error)
        }       
    }

    const addData2 = async (obj) => {
        try {
            await axios.post('/api/grupy_konta_udzialy/dodaj', {
                id_wspolnoty: obj.id_wspolnoty,
                id_user:obj.id_user,
                grupa_numer: obj.grupa_numer,
                konto_bankowe: obj.konto_bankowe,
                liczba_domownikow: obj.liczba_domownikow,
                data_od: obj.data_od,
                czy_mieszkanie_w_grupie: obj.czy_mieszkanie_w_grupie
            }).then( response => {
                dispatch(fetchLokale()); 
                dispatch(fetchGrupy()); 
                dispatch(fetchGrupyAkt()); 
                dispatch(fetchUdzialyKonta());
                dispatch(fetchUdzialyKontaAkt());
                dispatch(fetchMieszkancy());
                dispatch(fetchWspolnoty()); 
            })
        } catch (error) {
            console.log(error)
        }   
    }

    const handleAdd = () => {

        let czy_mieszkanie_w_grupie = null;

        if (domownicy) {
            czy_mieszkanie_w_grupie = 1;
            addData({
                id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                grupa_numer: ktoraGrupa,
                id_lokal: wybraneMieszkanie,
                data_od: dataOd,
            })
        }
        wybraneLokale?.forEach( lokal => {
            addData({
                id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                grupa_numer: ktoraGrupa,
                id_lokal: lokal.id,
                data_od: dataOd,
            })
        })
        addData2({
            id_wspolnoty, 
            id_user: mieszkaniec?.id_user,
            grupa_numer: ktoraGrupa,
            konto_bankowe: kontoBankowe, 
            liczba_domownikow: liczbaDomownikow,
            czy_mieszkanie_w_grupie,
            data_od: dataOd
        })

        setWybraneMieszkanie(0);
        setWybraneLokale([]);
        setDodajGrupe(false);
        
        setMessage("Dane zostały zapisane.");
        setOpen(true);
    }

    const handleKontoBankowe = e => setKontoBankowe(e.target.value); 
    const handleDataOd = e => setDataOd( e.target.value )

    const handleAnuluj = () => {
        setDodajGrupe(false);
    }

    return (
        <motion.div 
            className='form ramka-br'
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <DialogOk
                open={open}
                setOpen={setOpen}
                message={message}
            />
            <h4>{`Dodawanie grupy dla: ${ mieszkaniec?.imie_nazwisko === undefined ? "-" : mieszkaniec?.imie_nazwisko }`}</h4> 
            <h5>{ `Grupa numer: ${ ktoraGrupa }` }</h5>
            <p>Data od:</p>
            <input
                type="date"
                value={dataOd}
                onChange={handleDataOd}
            />
            <p>Numer konta (bez spacji):</p>
            <input 
                type="text" 
                style={{"width": "300px"}} 
                value={kontoBankowe}
                onChange={handleKontoBankowe}
                className={ kontoValid ? "" : "red" }
            />

            <MieszkancyDaneDodajGrupeMieszkanie 
                setWybraneMieszkanie={setWybraneMieszkanie}
                domownicy={domownicy}
                setDomownicy={setDomownicy}
                ulice={ulice}
                lokale={lokale}
                id_wspolnoty={id_wspolnoty}
                liczbaDomownikow={liczbaDomownikow}
                setLiczbaDomownikow={setLiczbaDomownikow}
            />
            <MieszkancyDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="garaż"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            />
            <MieszkancyDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="komórka"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            /> 
            <MieszkancyDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="strych"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            />

            <button onClick={handleAdd}>
                Zapisz
            </button>

            <button onClick={handleAnuluj}>
                Anuluj
            </button>
        </motion.div>
    )
}

export default MieszkancyListaDodajGrupe;