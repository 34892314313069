import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import WspolnotyEditForm from "./WspolnotyEditForm";
import WspolnotyUlice from "./Ulice/WspolnotyUlice";
import WspolnotyLokale from "./Lokale/WspolnotyLokale";
import GoBackBtn from "../GoBackBtn";

const WspolnotyDane = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);

    const [wspolnota, setWspolnota] = useState();

    const ulice = useSelector( state => state?.ulice?.data);

    const [ulice_w, setUlice_w] = useState([]);

    useEffect( () => {
        setUlice_w( ulice?.filter( u => u.id_wspolnoty == wspolnota?.id ) );
    },[ulice, wspolnota])

    const [wybranaUlica, setWybranaUlica] = useState([]);

    useEffect( () => {
        setWybranaUlica( ulice_w[0]?.id )
    },[ulice_w])

    useEffect( () => {
        setWspolnota( wspolnoty?.find( w => w?.id == idw) );
    },[wspolnoty]);

    return (
        <div className="content">
            <h1 className="nazwa">
                { wspolnota?.name }
            </h1>
            <GoBackBtn/>
            <div className="wspolnotyDane">
                <WspolnotyEditForm 
                    wspolnota={wspolnota}
                />
                <WspolnotyUlice
                    wspolnota={wspolnota}
                    wybranaUlica={wybranaUlica}
                    setWybranaUlica={setWybranaUlica}
                    ulice_w={ulice_w}
                />
                <WspolnotyLokale
                    wspolnota={wspolnota}
                    wybranaUlica={wybranaUlica}
                />
            </div>


        </div>
    )
}

export default WspolnotyDane;