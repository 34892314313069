import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
    fetchCred,
    fetchUser
} from '../Redux/Actions'

import { AuthContext} from '../Context/AuthProvider';
import scrollToTop from '../functions/ScrollToTop';
import { motion } from 'framer-motion';
import axios from "axios";
import '../scss/login.css';
import Strzalka from './Strzalka';
const LOGIN_URL = "/api/auth";

const Login = () => {

    const {auth, setAuth, role, setRole, user, setUser, userId, setUserId, setCred} = useContext(AuthContext);

    const dispatch = useDispatch();

    const userRef = useRef();    
    const errRef = useRef();
    useEffect( ()=> {
        scrollToTop();
    },[])
    const [userName, setUserName] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPwd, setShowPdw] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const navigate = useNavigate();

    if (auth && role != undefined) {
        navigate('/');
    }

    useEffect(() => {
        userRef.current.focus()
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [userName], [pwd]);

    axios.defaults.withCredentials = true;

    const handleUserName = e => setUserName(e.target.value);
    const handlePwd = e => setPwd(e.target.value);

    const handleShow = () => setShowPdw( prevState => !prevState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post(LOGIN_URL, {userName, pwd})
            .then(res => {
                if(res.data.login) {
                    setAuth(true);
                    setRole(res.data.role);
                    setUser(res.data.user);
                    setUserId(res.data.id);
                    setCred(res.data);

                    axios.post("/api/log", { id_user: res.data.id, akcja: "Zalogowanie" })
                        
                    if (res.data.role === "mieszkaniec")
                        navigate('/mieszkaniec');  
                    else 
                        navigate('/wspolnoty');
                }
                else {
                    alert("Nieprawidłowy login lub hasło.");
                }
            })
            .catch(err=> {
                console.log(err)
                axios.post("/api/log", { id_user: 0, akcja: `${userName}: ${err}` });
            });
    }

    return (
        <div className='login' style={{"transform": "translateY(-200px)"}}>       

            <motion.section  
                className='cienie' 
                whileHover={{ scale: 1.05}} 
                transition={{duration: 0.1}}
            >
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1>Logowanie</h1>
                <form onSubmit={handleSubmit}>
                    <section className="user">
                        <label htmlFor='username'>Użytkownik</label>
                        <input 
                            type="text" 
                            id="username"
                            ref={userRef}
                            autoComplete='off'
                            onChange={handleUserName}
                            value={userName}
                            required
                        />
                    </section>
                    <section className='pwd'>
                        <label htmlFor='password'>Hasło</label>
                        <div className='pwd'>
                            <input 
                                type={ !showPwd ? "password" : "text"  }
                                id="password"
                                autoComplete='off'
                                onChange={handlePwd}
                                value={pwd}
                                required
                            />
                            { !showPwd ? ( <FontAwesomeIcon icon={faEye} className='pointer' onClick={handleShow}/> ) : (<FontAwesomeIcon icon={faEyeSlash} className='pointer' onClick={handleShow}/>) }
                        </div>
                    </section>
                    <div className='button'>
                        <button>Zaloguj</button>
                    </div>

                    {/* <span
                        className='warning'
                    >
                        <FontAwesomeIcon icon={faTriangleExclamation} /> 
                        W dniu 6.08.2024 r. prowadzone są prace serwisowe. 
                    </span> */}
                    
                </form>
            </motion.section>
            <Strzalka/>
        </div>
    )
}

export default Login;