import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import ListaMieszkancow from './ListaMieszkancow.js';
import SelectWspolnoty from './SelectWspolnoty.js';
import ZuzycieFormularz from "./ZuzycieFormularz";

import { 
  fetchPlanowaneZuzycie
} from '../../Redux/Actions.js'; 

const Zuzycie = props => {

  const { mieszkancy, grupyAkt, lokale, ulice, udzialyKonta, wspolnoty, activeWindow } = props;

  const [wybranaWspolnota, setWybranaWspolnota] = useState(undefined);
  const [wybranyUdzialKonto, setWybranyUdzialKonto] = useState([]);
  const [wybranyMieszkaniec, setWybranyMieszkaniec] = useState([])
  const [planowaneZuzycie_u, setPlanowaneZuzycie_u] = useState([]);
  const [cieplaWoda, setCieplaWoda] = useState(0);
  const [zimnaWoda, setZimnaWoda] = useState(0);
  const [dataOd, setDataOd] = useState("");

  const planowaneZuzycie = useSelector( state  => state?.planowaneZuzycie?.data);

  const dispatch = useDispatch();
  
  useEffect( ()=> {
      dispatch(fetchPlanowaneZuzycie()); 
  },[dispatch]);

  useEffect( () => {
    setWybranyMieszkaniec( mieszkancy?.find( m => m.id_user == wybranyUdzialKonto?.id_user)  );
  },[mieszkancy, wybranyUdzialKonto]);

  // console.log(wybranyMieszkaniec)
  // console.log(wybranyUdzialKonto)

  useEffect( () => {
    setPlanowaneZuzycie_u( planowaneZuzycie?.filter( p => p.id_user == wybranyMieszkaniec?.id_user && p?.grupa_numer ==  wybranyUdzialKonto?.grupa_numer) ); 
  },[planowaneZuzycie,wybranyUdzialKonto])

  return (
    <div className="content woda">
      <h3 className="nazwa">Wprowadź planowane zużycie</h3>
      <div>
        <div className="naglowek wiersz">
          <SelectWspolnoty 
            setWybranaWspolnota={setWybranaWspolnota}
            wspolnoty={wspolnoty}
          />
          <div className="cena">


          </div>
        </div>
          
          <div className="container">
            <ListaMieszkancow 
              wybranyMieszkaniec={wybranyMieszkaniec}
              wybranyUdzialKonto={wybranyUdzialKonto}
              setWybranyUdzialKonto={setWybranyUdzialKonto}
              setWybranyMieszkaniec={setWybranyMieszkaniec}
              planowaneZuzycie={planowaneZuzycie}
              setPlanowaneZuzycie_u={setPlanowaneZuzycie_u}
              wybranaWspolnota={wybranaWspolnota}
              udzialyKonta={udzialyKonta}
              grupyAkt={grupyAkt}
              mieszkancy={mieszkancy}
              lokale={lokale}
              ulice={ulice}
              activeWindow={activeWindow}
              setZimnaWoda={setZimnaWoda}
              setCieplaWoda={setCieplaWoda}
              setDataOd={setDataOd}
            />
            <div className="rightSide">
              {wybranyMieszkaniec ? (
                  <ZuzycieFormularz 
                    // mode={mode}
                    // setMode={setMode}
                    // loadedLicznik={loadedLicznik}
                    wybranaWspolnota={wybranaWspolnota}
                    wybranyMieszkaniec={wybranyMieszkaniec}
                    wybranyUdzialKonto={wybranyUdzialKonto}
                    planowaneZuzycie_u={planowaneZuzycie_u}
                    setPlanowaneZuzycie_u={setPlanowaneZuzycie_u}
                    setWybranyMieszkaniec={setWybranyMieszkaniec}
                    setWybranyUdzialKonto={setWybranyUdzialKonto}
                    cieplaWoda={cieplaWoda}
                    setCieplaWoda={setCieplaWoda}
                    zimnaWoda={zimnaWoda}
                    setZimnaWoda={setZimnaWoda}
                    dataOd={dataOd}
                    setDataOd={setDataOd}
                  />
                ) : ""}
              {/* <StanLicznikowAddFormListaOdczytow 
                liczniki={liczniki.filter(licznik=>licznik.id_mieszkanca==wybranyMieszkaniec)}
                loadLicznik={loadLicznik}
              />  */}
            </div>

          </div>

        </div>
    </div>
  )
}

export default Zuzycie;