import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { dataFormat2 } from "../../../functions/dataFormat";
import { dropdownOptionsA, dropdownOptionsB, dropdownOptionsD, dropdownOptionsE, 
    dropdownOptionsF, dropdownOptionsGaraz, dropdownOptionsStrych, dropdownOptionsKomorka, dropdownOptionsWoda, woda } from "./WspolnotyStawkiAddFormListy";
import DialogOk from '../../DialogOk';


const WspolnotyStawkiAddForm = (props) => {

    const { wspolnota, idw, stawkiAkt } = props;

    const navigate = useNavigate();

    const addData_arch = async (obj) => {
        try {
          await axios.post('/api/dodaj_stawki_wspolnoty_arch', {
            obj
          }).then( response => {
            navigate(`/wspolnoty/`);
          });
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
    };

    const [open, setOpen] = useState(false);
    const message = `Nie wszystkie rodzaje rozliczania są uzupełnione.`;

    const [eksploatacja, setEksploatacja] = useState({val: stawkiAkt?.eksploatacja ? stawkiAkt?.eksploatacja : 0, czy: false, rodzaj: ""});
    const [garaz, setGaraz] = useState({val: stawkiAkt?.garaz ? stawkiAkt?.garaz : 0, czy: false, rodzaj: 16});
    const [strych, setStrych] = useState({val: stawkiAkt?.strych ? stawkiAkt?.strych : 0, czy: false, rodzaj: 17});
    const [komorka, setKomorka] = useState({val: stawkiAkt?.komorka ? stawkiAkt?.komorka : 0, czy: false, rodzaj: 18});
    const [utrzymanieCzystosci, setUtrzymanieCzystosci] = useState({val: stawkiAkt?.utrzymanie_czystosci ? stawkiAkt?.utrzymanie_czystosci : 0, czy: false, rodzaj: ""});
    const [wywoz, setWywoz] = useState({val: stawkiAkt?.wywoz_smieci ? stawkiAkt?.wywoz_smieci : 0, czy: false, rodzaj: 3});
    const [funduszRemontowy, setFunduszRemontowy] = useState({val: stawkiAkt?.fundusz_remontowy ? stawkiAkt?.fundusz_remontowy : 0, czy: false, rodzaj: ""});
    const [konserwacjaDomofonu, setKonserwacjaDomofonu] = useState({val: stawkiAkt?.konserw_domofonu ? stawkiAkt?.konserw_domofonu : 0, czy: false, rodzaj: 1});
    const [konserwacjaWindy, setKonserwacjaWindy] = useState({val: stawkiAkt?.konserw_windy ? stawkiAkt?.konserw_windy : 0, czy: false, rodzaj: 1});
    const [oplZaAdm, setOplZaAdm] = useState({val: stawkiAkt?.opl_za_adm ? stawkiAkt?.opl_za_adm : 0, czy: false, rodzaj: ""});
    const [zimnaWoda, setZimnaWoda] = useState({val: stawkiAkt?.zimna_woda ? stawkiAkt?.zimna_woda : 0, czy: false, rodzaj: 1});
    const [cieplaWoda, setCieplaWoda] = useState({val: stawkiAkt?.ciepla_woda ? stawkiAkt?.ciepla_woda : 0, czy: false, rodzaj: 1});
    const [abonamentCw, setAbonamentCw] = useState({val: stawkiAkt?.abonament_cw ? stawkiAkt?.abonament_cw : 0, czy: false, rodzaj: 5});
    const [co, setCo] = useState({val: stawkiAkt?.co ? stawkiAkt?.co : 0, czy: false, rodzaj: 5});
    const [energiaEl, setEnergiaEl] = useState({val: stawkiAkt?.energia_el_lokalu ? stawkiAkt?.energia_el_lokalu : 0, czy: false, rodzaj: ""});
    const [ryczaltGaz, setRyczaltGaz] = useState({val: stawkiAkt?.ryczalt_gaz ? stawkiAkt?.ryczalt_gaz : 0, czy: false, rodzaj: ""});
    const [zagospTerenu, setZagospTerenu] = useState({val: stawkiAkt?.zagosp_terenu ? stawkiAkt?.zagosp_terenu : 0, czy: false, rodzaj: ""});
    const [antena, setAntena] = useState({val: stawkiAkt?.antena ? stawkiAkt?.antena : 0, czy: false, rodzaj: ""});
    const [wodaRozliczanie, setWodaRozliczanie] = useState({val: stawkiAkt?.rozliczanie_wody ? stawkiAkt?.rozliczanie_wody : 0, czy: true, rodzaj: 1});
    
    


    const [dataOd, setDataOd] = useState(dataFormat2(new Date()));

    const onSubmit = ( ) => {
        const obj = {
            id_wspolnoty:               idw,
            eksploatacja:               eksploatacja.val,
            czy_eksploatacja:           eksploatacja.czy,
            eksploatacja_rodzaj:        eksploatacja.rodzaj,
            garaz:                      garaz.val,
            czy_garaz:                  garaz.czy,
            garaz_rodzaj:               garaz.rodzaj,
            strych:                     strych.val,
            czy_strych:                 strych.czy,
            strych_rodzaj:              strych.rodzaj,
            komorka:                    komorka.val,
            czy_komorka:                komorka.czy,
            komorka_rodzaj:             komorka.rodzaj,
            utrzymanie_czystosci:       utrzymanieCzystosci.val,
            czy_utrzymanie_czystosci:   utrzymanieCzystosci.czy,
            utrzymanie_czystosci_rodzaj:utrzymanieCzystosci.rodzaj,
            wywoz_smieci:               wywoz.val,
            czy_wywoz_smieci:           wywoz.czy,
            wywoz_smieci_rodzaj:        wywoz.rodzaj,
            fundusz_remontowy:          funduszRemontowy.val,
            czy_fundusz_remontowy:      funduszRemontowy.czy,
            fundusz_remontowy_rodzaj:   funduszRemontowy.rodzaj,
            konserw_domofonu:           konserwacjaDomofonu.val,
            czy_konserw_domofonu:       konserwacjaDomofonu.czy,
            konserw_domofonu_rodzaj:    konserwacjaDomofonu.rodzaj,
            konserw_windy:              konserwacjaWindy.val,
            czy_konserw_windy:          konserwacjaWindy.czy,
            konserw_windy_rodzaj:       konserwacjaWindy.rodzaj,
            opl_za_adm:                 oplZaAdm.val,
            czy_opl_za_adm:             oplZaAdm.czy,
            opl_za_adm_rodzaj:          oplZaAdm.rodzaj,
            abonament_cw:               abonamentCw.val,
            czy_abonament_cw:           abonamentCw.czy,
            abonament_cw_rodzaj:        abonamentCw.rodzaj,
            co:                         co.val,
            czy_co:                     co.czy,
            co_rodzaj:                  co.rodzaj,
            energia_el_lokalu:          energiaEl.val,
            czy_energia_el_lokalu:      energiaEl.czy,
            energia_el_lokalu_rodzaj:   energiaEl.rodzaj,
            ryczalt_gaz:                ryczaltGaz.val,
            czy_ryczalt_gaz:            ryczaltGaz.czy,
            ryczalt_gaz_rodzaj:         ryczaltGaz.rodzaj,
            zagosp_terenu:              zagospTerenu.val,
            czy_zagosp_terenu:          zagospTerenu.czy,
            zagosp_terenu_rodzaj:       zagospTerenu.rodzaj,
            antena:                     antena.val,
            czy_antena:                 antena.czy,
            antena_rodzaj:              antena.rodzaj,
            data_od:                    dataOd ,
            rozliczanie_wody:           wodaRozliczanie.rodzaj,
            ciepla_woda:                cieplaWoda.val,
            czy_ciepla_woda:            cieplaWoda.czy,
            ciepla_woda_rodzaj:         cieplaWoda.rodzaj,
            zimna_woda:                 zimnaWoda.val,
            czy_zimna_woda:             zimnaWoda.czy,
            zimna_woda_rodzaj:          zimnaWoda.rodzaj
        }
        // ;
        valid(obj) ? addData_arch(obj) : setOpen(true);
    }

    const check = (state, set, opis) => {
        const handle = () => { 
            if (!state.czy == false) {
                set((prevState) => ({ ...prevState, val: 0})); 
                set((prevState) => ({ ...prevState, rodzaj:  ""})); 
            }
            set((prevState) => ({ ...prevState, czy: !state.czy})); 
        }

        return (
            <div className="check">
                <input type='checkbox' checked={state.czy} onChange={handle} />
                <span onClick={handle} className="pointer">{opis}</span>
            </div>    
        )
    }

    const input = (state, set) => {
        const handle = e => set((prevState) => ({...prevState, val: e.target.value}) );
        return (
            <div className="input">
                <input 
                    type="number"
                    value={state.val}
                    onChange={handle}
                    disabled={!state.czy}
                />
            </div>
        )
    }

    const lista = (lista, state, set) => {
        const opcje = lista?.map( (r, i) => {
            return (
                <option key={i} value={r.value} >
                    {r.key}
                </option>
            )
        })
        const handle = e => {set((prevState) => ({...prevState, rodzaj: e.target.value}))}
        return (
            <div className="lista">
                <select value={state.rodzaj} onChange={handle} disabled={!state.czy}>
                    {opcje}
                </select>
            </div>
        )
    }

    const handleDataOd = e => setDataOd( e.target.value);

    const valid = obj => {

        let wynik = true;

        const keys = Object.keys(obj);

        // Pętla przechodzi przez wszystkie klucze
        keys.forEach(key => {
          // Sprawdzamy, czy klucz kończy się na "_rodzaj"
          if (key.endsWith('_rodzaj')) {
            // Usuwamy "_rodzaj" z klucza, aby uzyskać podstawową nazwę składnika
            const baseKey = key.replace('_rodzaj', '');
            const czyKey = `czy_${baseKey}`;
            const rodzajKey = `${baseKey}_rodzaj`;
      
            // Sprawdzamy, czy odpowiednie klucze istnieją w obiekcie
            if (baseKey in obj && czyKey in obj && rodzajKey in obj) {
              // Sprawdzamy warunki: czyKey = true i rodzajKey = 0
              if (obj[czyKey] == true && obj[rodzajKey] == 0) {
                // Wyświetlamy alert
                wynik = false;
              }
            }
          }
        });
        return wynik;
    }

    return (
        <div className="stawkiContainer">
            <DialogOk
                open={open}
                setOpen={setOpen}
                message={message}
            />
            <h3>Nowe stawki</h3>

            <div className="formularz">
                <div className="wiersz">
                    { check(eksploatacja, setEksploatacja, "Eksploatacja:") }
                    { input(eksploatacja, setEksploatacja) }
                    { lista(dropdownOptionsA, eksploatacja, setEksploatacja) }
                </div>
                <div className="wiersz">
                    { check(garaz, setGaraz, "Garaż:") }
                    { input(garaz, setGaraz) }
                    { lista(dropdownOptionsGaraz, garaz, setGaraz) }
                </div>
                <div className="wiersz">
                    { check(strych, setStrych, "Strych:") }
                    { input(strych, setStrych) }
                    { lista(dropdownOptionsStrych, strych, setStrych) }
                </div>
                <div className="wiersz">
                    { check(komorka, setKomorka, "Komórka:") }
                    { input(komorka, setKomorka) }
                    { lista(dropdownOptionsKomorka, komorka, setKomorka) }
                </div>
                <div className="wiersz">
                    { check(utrzymanieCzystosci, setUtrzymanieCzystosci, "Utrzymanie czystości:") }
                    { input(utrzymanieCzystosci, setUtrzymanieCzystosci) }
                    { lista(dropdownOptionsB, utrzymanieCzystosci, setUtrzymanieCzystosci) }
                </div>
                <div className="wiersz">
                    { check(wywoz, setWywoz, "Wywóz śmieci:") }
                    { input(wywoz, setWywoz) }
                    { lista(dropdownOptionsD, wywoz, setWywoz) }
                </div>
                <div className="wiersz">
                    { check(funduszRemontowy, setFunduszRemontowy, "Fundusz remontowy:") }
                    { input(funduszRemontowy, setFunduszRemontowy) }
                    { lista(dropdownOptionsB, funduszRemontowy, setFunduszRemontowy) }
                </div>
                <div className="wiersz">
                    { check(konserwacjaDomofonu, setKonserwacjaDomofonu, "Konserwacja domofonu:") }
                    { input(konserwacjaDomofonu, setKonserwacjaDomofonu) }
                    { lista(dropdownOptionsF, konserwacjaDomofonu, setKonserwacjaDomofonu) }
                </div>
                <div className="wiersz">
                    { check(konserwacjaWindy, setKonserwacjaWindy, "Konserwacja windy:") }
                    { input(konserwacjaWindy, setKonserwacjaWindy) }
                    { lista(dropdownOptionsF, konserwacjaWindy, setKonserwacjaWindy) }
                </div>
                <div className="wiersz">
                    { check(oplZaAdm, setOplZaAdm, "Opłata za administację:") }
                    { input(oplZaAdm, setOplZaAdm) }
                    { lista(dropdownOptionsA, oplZaAdm, setOplZaAdm) }
                </div>
                <div className="wiersz">
                    { check(zimnaWoda, setZimnaWoda, "Zimna woda:") }
                    { input(zimnaWoda, setZimnaWoda) }
                    { lista(dropdownOptionsWoda, zimnaWoda, setZimnaWoda) }
                </div>
                <div className="wiersz">
                    { check(cieplaWoda, setCieplaWoda, "Ciepła woda:") }
                    { input(cieplaWoda, setCieplaWoda) }
                    { lista(dropdownOptionsWoda, cieplaWoda, setCieplaWoda) }
                </div>
                <div className="wiersz">
                    { check(abonamentCw, setAbonamentCw, "Abonament cw:") }
                    { input(abonamentCw, setAbonamentCw) }
                    { lista(dropdownOptionsE, abonamentCw, setAbonamentCw) }
                </div>
                <div className="wiersz">
                    { check(co, setCo, "Centralne Ogrzewanie:") }
                    { input(co, setCo) }
                    { lista(dropdownOptionsE, co, setCo) }
                </div>
                <div className="wiersz">
                    { check(energiaEl, setEnergiaEl, "Energia el. lokalu:") }
                    { input(energiaEl, setEnergiaEl) }
                    { lista(dropdownOptionsB, energiaEl, setEnergiaEl) }
                </div>
                <div className="wiersz">
                    { check(ryczaltGaz, setRyczaltGaz, "Ryczałt za gaz:") }
                    { input(ryczaltGaz, setRyczaltGaz) }
                    { lista(dropdownOptionsB, ryczaltGaz, setRyczaltGaz) }
                </div>
                <div className="wiersz">
                    { check(zagospTerenu, setZagospTerenu, "Zagospodarowanie terenu:") }
                    { input(zagospTerenu, setZagospTerenu) }
                    { lista(dropdownOptionsB, zagospTerenu, setZagospTerenu) }
                </div>
                <div className="wiersz">
                    { check(antena, setAntena, "Antena:") }
                    { input(antena, setAntena) }
                    { lista(dropdownOptionsB, antena, setAntena) }
                </div>
                <div className="wiersz">
                    <span>Sposób rozliczania wody:</span>
                    { lista(woda, wodaRozliczanie, setWodaRozliczanie) }
                </div>
                <div className="wiersz">
                    <span>Data obowiązywania od:</span>
                    <input type="date" value={dataOd} onChange={handleDataOd} />
                </div>

                <div className="button">
                    <button onClick={onSubmit}>Zapisz</button>
                </div>

            </div>
        </div>
    )
}

export default WspolnotyStawkiAddForm;