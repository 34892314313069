import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../scss/style.css';
import '../../scss/listy.css';
import '../../scss/form.css';
import '../../scss/woda.css';
import GoBackBtn from '../GoBackBtn';
import StanLicznikow from './StanLicznikow';
import Zuzycie from './Zuzycie.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import { 
    fetchWspolnoty,
    fetchMieszkancy,
    fetchStawki,
    fetchOdczyty,
    fetchLokale,
    fetchUlice,
    fetchGrupy,
    fetchGrupyAkt,
    fetchUdzialyKonta,
    fetchWersje
  } from '../../Redux/Actions.js'; 

const Woda = () => {

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const liczniki = useSelector( state => state?.odczyty?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data)
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const wersje = useSelector( state => state?.wersje?.data);

    const dispatch = useDispatch();

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchStawki()); 
        dispatch(fetchOdczyty());
        dispatch(fetchLokale());
        dispatch(fetchUlice());
        dispatch(fetchGrupy()); 
        dispatch(fetchUdzialyKonta());
      },[dispatch]);

      useEffect( () => {
        dispatch(fetchWersje(grupy));
      },[dispatch, grupy]); 

      useEffect( () => {
        dispatch(fetchGrupyAkt(grupy, wersje));
      },[dispatch,grupy,wersje])

    const [activeWindow, setActiveWindow] = useState("")
    
    const window = () => {
        switch(activeWindow) {
            case "dodajStanLicznikow":
                return <StanLicznikow
                    stawki={stawki}
                    mieszkancy={mieszkancy}
                    grupyAkt={grupyAkt}
                    liczniki={liczniki}
                    lokale={lokale}
                    ulice={ulice}
                    udzialyKonta={udzialyKonta}
                    wspolnoty={wspolnoty}
                    activeWindow={activeWindow}
                />;
            case "dodajZuzycie":
                return <Zuzycie
                    mieszkancy={mieszkancy}
                    grupyAkt={grupyAkt}
                    udzialyKonta={udzialyKonta}
                    lokale={lokale}
                    ulice={ulice}
                    wspolnoty={wspolnoty}
                    activeWindow={activeWindow}
                />;   
            default: return <></> 
        }
    }

    return (
        <motion.div 
            className="content woda"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">Woda - stan liczników i zużycie</h1>
            {/* <p><span className="red">w budowie <FontAwesomeIcon icon={faPersonDigging} /></span></p> */}
            <GoBackBtn />
            <button 
                onClick={()=>setActiveWindow("dodajZuzycie")}
            >
                Wprowadź planowane zużycie
            </button>
            <button 
                onClick={()=>setActiveWindow("dodajStanLicznikow")}
            >
                Wprowadź stan liczników
            </button>
            <div className='window'>
                {window()}
            </div>

        </motion.div>
    )

}

export default Woda;