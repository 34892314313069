import React, {useEffect, useState } from 'react';
import { useLocation, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import GoBackBtn from '../GoBackBtn';
import MieszkaniecWydruk from '../Mieszkaniec/MieszkaniecWydruk.js';
import MieszkancyRozliczenieSaldo from './MieszkancyRozliczenieSaldo';
import Loader from '../Loader.js';
import { dataFormatMsc, addOneDay, nextMonth } from '../../functions/dataFormat';
import { zl, podstawa } from '../../functions/utilities.js';
import { adres } from "../../functions/ulice";
import { ikona } from "../../functions/ikony";
import { hasKey, arrayHasElement, usunKluczNaN, filterKeysWithNumbers } from '../../functions/tablice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import {
    fetchMieszkancy,
    fetchWspolnoty,
    fetchGrupy,
    fetchGrupyAkt,
    fetchWersje,
    fetchLokale,
    fetchUlice,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUdzialyKontaAll,
    fetchStawki, 
    fetchPrzelewy,
    fetchStawkiJednorazowe,
} from '../../Redux/Actions.js';

const MieszkancyRozliczenieList = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id_user = queryParams.get("id");
    const idw = queryParams.get("idw");
    const grupa_numer = queryParams.get("g");

    const dispatch = useDispatch();

    const mieszkancy = useSelector( state => state?.mieszkancy?.data );
    const mieszkaniec = mieszkancy ? mieszkancy?.filter( m => m.id_user == id_user)[0] : undefined;
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data);
    const grupyAkt_u = grupyAkt ? grupyAkt?.filter( g => g.id_user == id_user) : undefined;
    const wersje = useSelector( state => state?.wersje?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const ulice = useSelector(state => state?.ulice?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const stawki = useSelector( state => state?.stawki?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    
    const location = useLocation();
    const { state } = location;
    const czynszeMieszkaniec = state?.from?.czynszeMieszkaniec;
    const przelewyMieszkaniec = state?.from?.przelewyMieszkaniec;
    const wspolnota = state?.from?.wspolnota;

    const [czynszeGrupa, setCzynszeGrupa] = useState([]);
    const [ miesiac, setMiesiac ] = useState();
    const [ ind, setInd ] = useState(false);
    const [ listaMsc, setListaMsc ] = useState([]);
    const [ grupyUdzialyMieszkaniec, setGrupyUdzialyMieszkaniec ] = useState([]);
    const [czyWydruk, setCzyWydruk] = useState(false);
    const [czynszeMieszkaniecBezNaN, setCzynszeMieszkaniecBezNan] = useState([]);
    const [loaderOn, setLoaderOn] = useState(true);
    const [headers, setHeaders] = useState([]);
    const [stawki_w, setStawki_w] = useState([]);

    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);
    const [stawkiJednorazowe_msc, setStawkiJednorazowe_msc] = useState([]);

    const [grupy_a, setGrupy_a] = useState([]);
    const [udzialyKonta_a, setUdzialyKonta_a] = useState([]);
    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);
    
    useEffect( () => {
        setStawkiJednorazowe_w( 
            stawkiJednorazowe?.filter( s => s.id_wspolnoty == idw)
        )
    },[stawkiJednorazowe, wspolnota]);

    useEffect( () => {
        setUdzialyKonta_a( 
            udzialyKonta?.find(u => u.id_wspolnoty == idw && u.id_user == id_user && u.grupa_numer == grupa_numer)
        )
        setGrupy_a( grupy?.filter( g => g?.id_user == id_user && g.grupa_numer == grupa_numer) )
    },[udzialyKonta, id_user, grupa_numer, grupy])

    useEffect( () => {
        setStawkiJednorazowe_msc( 
            stawkiJednorazowe_w?.find( s => new Date(s.miesiac)?.getFullYear() == new Date()?.getFullYear() 
                                        && new Date(s.miesiac)?.getMonth() == new Date()?.getMonth() ) )
    },[stawkiJednorazowe_w])

    useEffect( () => {
        setTablicaCzynszeAkt(czynszeGrupa?.find( t => t?.msc == miesiac))
    },[czynszeGrupa, miesiac])

    useEffect( () => {
        setCzynszeMieszkaniecBezNan( usunKluczNaN(czynszeGrupa) )
    },[czynszeGrupa]);

    useEffect( () => {
        if (czynszeGrupa && czynszeGrupa?.length) {
            const temp = filterKeysWithNumbers(czynszeGrupa);
            const zredukowanaTablica = temp.reduce((acc, obj) => {
                Object.keys(obj).forEach(key => {
                    if (!acc.includes(key)) {
                        acc.push(key);
                    }
                });
                return acc;
            }, []);
            setHeaders(zredukowanaTablica)
        }

            // setHeaders( filterKeysWithNumbers(czynszeGrupa)[1] )
    },[czynszeGrupa])

    useEffect( ()  => {
        if (czynszeMieszkaniecBezNaN && czynszeMieszkaniecBezNaN?.length)
            setLoaderOn(false)
    },[czynszeMieszkaniecBezNaN])

    useEffect( () => {
        setCzynszeGrupa( czynszeMieszkaniec?.find( c => c?.grupa_numer == grupa_numer)?.temp_msc )
    },[czynszeGrupa]);
    
    useEffect( () => {
        setMiesiac( listaMsc[0] )
    },[listaMsc])
    
    useEffect(()=>{
        dispatch(fetchMieszkancy()); 
        dispatch(fetchWspolnoty())
        dispatch(fetchGrupy()); 
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAkt());
        dispatch(fetchUdzialyKontaAll());
        dispatch(fetchLokale());
        dispatch(fetchUlice());
        dispatch(fetchPrzelewy()); 
        dispatch(fetchStawki()); 
        dispatch(fetchStawkiJednorazowe());
    },[dispatch]);

    useEffect( () => {
        dispatch(fetchWersje(grupy));
      },[dispatch, grupy]);

    useEffect( () => {
        dispatch(fetchGrupyAkt(grupy, wersje));
    },[dispatch, grupy, wersje]);
        
    useEffect( () => {
        setGrupyUdzialyMieszkaniec(udzialyKonta?.filter( d => d.id_user == id_user )[0]);
    },[id_user, udzialyKonta]);

    useEffect( () => {
        setStawki_w( stawki?.find( s => s?.id_wspolnoty == idw ) )
    },[stawki, idw]);

    const handleMiesiac = () => setMiesiac("");

    const indShow = () => setInd( prevState => !prevState);

    useEffect(()=> {
        
        let minData = addOneDay(grupyUdzialyMieszkaniec?.data_od);
    
        function dodajMiesiac(d) {
            d.setMonth(d.getMonth() + 1);
            return d;
        }
        const dataObecna = new Date();
        const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
        const tablicaMiesiecy = [];
        let aktualnaData = new Date(minData.getFullYear(), minData.getMonth(), 1);
        while (aktualnaData <= dataMiesiacPoObecnej) {
            tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
            aktualnaData = dodajMiesiac(aktualnaData);
        }
        setListaMsc(tablicaMiesiecy)
      },[grupyUdzialyMieszkaniec, mieszkaniec])

    const wybierzMiesiac = (m) => {
        setMiesiac(m);
        setInd();
        showPrint();
    }
    
    const showPrint = () => setCzyWydruk( prevState => !prevState );

    // console.log(czynszeGrupa)
    // console.log(headers)

    const tab = listaMsc?.map( (msc,i) => {
        const czynszMiesiac = czynszeMieszkaniecBezNaN?.find( czynsz => czynsz.msc == msc);
        
        return (   
            <tr key={i}>
                <td className="pointer" style={{"fontWeight": 800}} >{ msc }</td>
                { arrayHasElement(headers, "eksploatacja") ? (<td>{ zl(czynszMiesiac?.eksploatacja) }</td> ) : ""}
                { arrayHasElement(headers, "garaz") ? ( <td>{ zl(czynszMiesiac?.garaz) }</td> ) : ""}
                { arrayHasElement(headers, "strych") ? (<td>{ zl(czynszMiesiac?.strych) }</td> ) : ""}
                { arrayHasElement(headers, "komorka") ? (<td>{ zl(czynszMiesiac?.komorka) }</td> ) : ""}
                { arrayHasElement(headers, "utrzymanie_czystosci") ? (<td>{ zl(czynszMiesiac?.utrzymanie_czystosci) }</td> ) : ""}
                { arrayHasElement(headers, "wywoz_smieci") ?  (<td>{ zl(czynszMiesiac?.wywoz_smieci) }</td> ) : "" }
                { arrayHasElement(headers, "fundusz_remontowy") ? (<td>{ zl(czynszMiesiac?.fundusz_remontowy) }</td> ) : ""}
                { arrayHasElement(headers, "konserw_domofonu") ? (<td>{ zl(czynszMiesiac?.konserw_domofonu) }</td> ) : ""}
                { arrayHasElement(headers, "konserw_windy") ? (<td>{ zl(czynszMiesiac?.konserw_windy) }</td> ) : ""}
                { arrayHasElement(headers, "opl_za_adm") ? (<td>{ zl(czynszMiesiac?.opl_za_adm) }</td> ) : ""}
                { arrayHasElement(headers, "abonament_cw") ? ( <td>{ zl(czynszMiesiac?.abonament_cw)}</td> ) : ""}
                { arrayHasElement(headers, "co") ? (<td>{ zl(czynszMiesiac?.co) }</td> ) : ""}
                { arrayHasElement(headers, "energia_el_lokalu") ? (<td>{ zl(czynszMiesiac?.energia_el_lokalu) }</td> ) : ""}
                { arrayHasElement(headers, "ryczalt_gaz") ? (<td>{ zl(czynszMiesiac?.ryczalt_gaz) }</td> ) : ""}
                { arrayHasElement(headers, "zagosp_terenu") ? (<td>{ zl(czynszMiesiac?.zagosp_terenu) }</td> ) : ""}
                { arrayHasElement(headers, "antena") ? (<td>{ zl(czynszMiesiac?.antena) }</td> ) : ""}
                { arrayHasElement(headers, "ciepla_woda") ? (<td>{ zl(czynszMiesiac?.ciepla_woda) }</td> ) : ""}
                { arrayHasElement(headers, "zimna_woda") ? (<td>{ zl(czynszMiesiac?.zimna_woda) }</td> ) : ""}
                { arrayHasElement(headers, "dod_1") ? (<td>{ zl(czynszMiesiac?.dod_1) }</td> ) : ""}
                { arrayHasElement(headers, "dod_2") ? (<td>{ zl(czynszMiesiac?.dod_2) }</td> ) : ""}
                { arrayHasElement(headers, "dod_3") ? (<td>{ zl(czynszMiesiac?.dod_3) }</td> ) : ""}
                { arrayHasElement(headers, "czynsz_na_msc") ? (<td>{ zl(czynszMiesiac?.czynsz_na_msc) }</td> ): "" }
                {   
                    <td
                        style={{textAlign: "center"}}
                    >
                        <FontAwesomeIcon 
                            icon={faPrint} 
                            className='pointer'
                            onClick={()=> {wybierzMiesiac(msc)}}
                        />
                    </td>
                }
            </tr>
        )
    })

    const lokaleGrupy = grupyAkt_u?.filter( grupa => grupa.id_user == id_user && grupa.grupa_numer == grupa_numer );

    const lokaleTabela = lokaleGrupy?.map( (lokal, i ) => {
        return (
          <li key={i}>
            {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal?.id_lokal )[0]?.ulica, ulice, lokal?.id_lokal, lokale)}`} 
          </li>
        )
    });
    
    return (
        <div className="content">
            <Loader stan={loaderOn}/>
            <h1 className="nazwa">
                Lista rozliczeń mieszkańca  
            </h1>
            <h3>
                { mieszkaniec?.imie_nazwisko /*+ " (" + mieszkaniec[0]?.ulica + " " + mieszkaniec[0]?.numer_domu +"/" + mieszkaniec[0]?.numer_lokalu + ")"*/}
            </h3>
            <GoBackBtn />

            <div className='lokale'>
                <h5>{`Lokale w tej grupie czynszowej numer ${grupa_numer}:`}</h5>
                <ul style={{"listStyle": "none"}}>
                    {lokaleTabela}
                </ul>
            </div>

            <table className="lista tabela naleznosci">
                <thead>
                    <tr>
                        <td>Miesiąc</td>
                        { arrayHasElement(headers,"eksploatacja") ? (<td>eks.</td>) : "" }
                        { arrayHasElement(headers, "garaż") ? (<td>gar.</td>) : "" }
                        { arrayHasElement(headers, "strych") ? (<td>str.</td>) : "" }
                        { arrayHasElement(headers, "komorka") ? (<td>kom.</td>) : "" }
                        { arrayHasElement(headers, "utrzymanie_czystosci") ? (<td>utr.</td>) : "" }
                        { arrayHasElement(headers, "wywoz_smieci") ? (<td>wyw.</td>) : "" }
                        { arrayHasElement(headers, "fundusz_remontowy") ? (<td>rem.</td>) : "" }
                        { arrayHasElement(headers, "konserw_domofonu") ? (<td>dom.</td>) : "" }
                        { arrayHasElement(headers, "konserw_windy") ? (<td>win.</td>) : "" }
                        { arrayHasElement(headers, "opl_za_adm") ? (<td>adm.</td>) : "" }
                        { arrayHasElement(headers, "abonament_cw") ? (<td>abo.</td>) : "" }
                        { arrayHasElement(headers, "co") ? (<td>co</td>) : "" }
                        { arrayHasElement(headers, "energia_el_lokalu") ? (<td>ene.</td>) : "" }
                        { arrayHasElement(headers, "ryczalt_gaz") ? (<td>gaz</td>) : "" }
                        { arrayHasElement(headers, "zagosp_terenu") ? (<td>zag.</td>) : "" }
                        { arrayHasElement(headers, "antena") ? (<td>ant.</td>) : "" }
                        { arrayHasElement(headers, "ciepla_woda") ? (<td>w. ciepła</td>) : "" }
                        { arrayHasElement(headers, "zimna_woda") ? (<td>w. zimna</td>) : "" }
                        { arrayHasElement(headers, "dod_1") ? (<td>dod. 1</td>) : "" }
                        { arrayHasElement(headers, "dod_2") ? (<td>dod. 2</td>) : "" }
                        { arrayHasElement(headers, "dod_3") ? (<td>dod. 3</td>) : "" }
                        <td>Suma</td>
                        <td>Drukuj zaliczkę za miesiąc</td>
                    </tr>
                </thead>
                <tbody>
                    {tab}
                </tbody>
            </table>

            <div className='mt-100'></div>
            
             <MieszkancyRozliczenieSaldo 
                czynsze={czynszeGrupa} 
                przelewy={przelewyMieszkaniec}
                wybierzMiesiac={wybierzMiesiac}
                listaMsc={listaMsc}
                showPrint={showPrint}
            />

            {czyWydruk && (
                <MieszkaniecWydruk 
                    udzialyKonta_a={udzialyKonta_a}
                    showPrint={showPrint}
                    mieszkaniec={mieszkaniec}
                    tablicaCzynszeAkt={tablicaCzynszeAkt}
                    podstawa={podstawa}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_msc={stawkiJednorazowe_msc}
                    lokale={lokale}
                    ulice={ulice}
                    grupy_a={grupy_a}
                />
            ) }
        </div>
    )
}

export default MieszkancyRozliczenieList;