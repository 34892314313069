import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { AuthContext} from '../../Context/AuthProvider';
import KontaktForm from './KontaktForm';
import { motion } from 'framer-motion';
import scrollToTop from '../../functions/ScrollToTop';
import '../../scss/style.css';
import '../../scss/kontakt.css';
import Strzalka from '../Strzalka';
import { wstepy } from '../../functions/motions';

const Kontakt = () => {
    useEffect( ()=> {
        scrollToTop();
    },[])
    const [sukces, setSukces] = useState(false)

    const { userId } = useContext(AuthContext);

    useEffect( () => {
        const id_temp = userId ? userId : 0;
        axios.post("/api/log", { id_user: id_temp, akcja: "Kontakt" })
    },[])

    return (
        <motion.div 
            className='kontakt'
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <div className='content cienie'>
                <div className='names'>
                    <div className='naglowek'>
                        <h3>
                            Kontakt
                        </h3>
                        <div className='separator'></div>
                    </div>

                    <div className='osoba'>
                        <h4>
                            Biuro Obsługi Nieruchomości<br/>
                            Mojsiewicz & Stanek s.c.
                        </h4>
                        <span>
                            ul. Mieszka I 57/6<br/>
                            66-400 Gorzów Wielkopolski<br/>
                            tel: <a href="tel:690240779">690 240 779</a><br/>
                            tel: <a href="tel:957241908">95 724 19 08</a>
                        </span>
                    </div>
                    <div className='osoba'>
                        <h4>
                            Katarzyna Stanek
                        </h4>
                        <span>
                            Właściciel - <a href="tel:504005503">504 005 503</a>
                        </span>
                    </div>
                    <div className='osoba'>
                        <h4>
                            Aleksandra Mojsiewicz
                        </h4>
                        <span>
                            Właściciel - <a href="tel:502379164">502 379 164</a>
                        </span>
                    </div>
                    <div className='osoba'>
                        <h4>
                            Janusz Mojsiewicz
                        </h4>
                        <span>
                            Rzeczoznawca majątkowy - <a href="tel:601875300">601 875 300</a>
                        </span>
                    </div>
                </div>
                <div className='formularz'>
                    <motion.h2
                        initial={{scale: 0.1}}
                        animate={
                            {scale: 1, transition: {type: 'spring'}}
                        }
                        
                    >
                        Napisz do nas
                    </motion.h2>
                    <KontaktForm setSukces={setSukces}/>
                    {
                        sukces && (
                            <h3>Wiadomość wysłana</h3>
                        )
                    }
                </div>
            </div>
            <div className='content cienie'>
                <div className='mapa'>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2415.5582510941667!2d15.229188612424934!3d52.7401633720034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47071f9c43169d69%3A0x4441e02dd170c065!2sMieszka%20I%2057%2F6%2C%2066-400%20Gorz%C3%B3w%20Wielkopolski!5e0!3m2!1spl!2spl!4v1701209618432!5m2!1spl!2spl" 
                        style={{"width": "100%","height": "600px","border": "0"}}    >
                    </iframe>   
                </div>
            </div>
            <Strzalka/>
        </motion.div>
    )
}

export default Kontakt;