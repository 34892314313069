import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

function InputF(props){
    const {label, name, f, ...rest} = props;
    return (
        <div className='form-control'>
            <label htmlFor={name} className='input'>{label}</label>
            <Field 
                id={name} 
                onChange={ (e)=> {
                    f(e.currentTarget.value);
                }}
                name={name} 
                {...rest} 
            />
            <ErrorMessage name={name} component={TextError}/>
        </div>

    )
}

export default InputF;