import React, { useState, useEffect } from 'react';
import axios from "axios";

const Test = () => {

    const [grupy, setGrupy] = useState([]);
    const [json, setJson] = useState();

    const id_wspolnoty = 1;

    useEffect(()=>{

        axios.get("/api/grupy", { params: {p: id_wspolnoty} }) 
          .then(response => {
            const { data } = response;
            console.log(data)
            setGrupy( data )
          })
          
    },[]);

    useEffect( () => {
        const temp  = JSON.stringify(grupy, null, 2);
        setJson(temp);
    })

    return (
        <div>
            {
                json
            }
        </div>
    )
}

export default Test;