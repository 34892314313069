import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { ikona } from "../../../functions/ikony";

const WspolnotyLokaleLista = props => {

    

    const { lokaleUlica, setActiveWindow, setWybranaUlica } = props;

    // const handleWybranaUlica = e => {
    //     setWybranaUlica(e);
    // }

    const _MIESZKANIE_ = "mieszkanie";
    const _GARAZ_ = "garaż";
    const _KOMORKA_ = "komórka";
    const _STRYCH_ = "strych";


    const [mieszkania, setMieszkania] = useState([]);
    const [garaze, setGaraze] = useState([]);
    const [komorki, setKomorki] = useState([]);
    const [strychy, setStrychy] = useState([]);

    useEffect(() => {
        setMieszkania( lokaleUlica?.filter( l => l.rodzaj === _MIESZKANIE_) );
        setGaraze(lokaleUlica?.filter( l => l.rodzaj === _GARAZ_) );
        setKomorki(lokaleUlica?.filter( l => l.rodzaj === _KOMORKA_ ));
        setStrychy(lokaleUlica?.filter(l => l.rodzaj === _STRYCH_));
    },[lokaleUlica])

    const listaLokale = (lokale) => {
        return (
            <>
                <li className="naglowek">
                    <div style={{"textAlign": "center"}}>Klatka</div>
                    <div>Nr lok.</div>
                    <div>Pow.</div>
                    <div>Udział</div>
                    <div style={{"textAlign": "center"}}>Zajęty</div> 
                </li>
                
                {lokale?.map( (l, i) => {
                    return (
                        <li>
                            <div
                                 style={{"textAlign": "center"}}
                            >
                                {l.klatka === null || l.klatka === "" ? "-" : l.klatka }
                            </div>
                            <div>{l.numer}</div>
                            <div>{`${l.metraz} m²`}</div>
                            <div>{`${l.udzial} %`}</div>
                            <div style={{"textAlign": "center"}}>{l.czy_zajety !== null ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}</div>
                        </li>
                    )
                })}
            </>
        )
    };

    const handleClick = () => setActiveWindow("dodaj");

    return (
        <div className="wspolnotyLokaleLista">
            <div className="naglowek">
                <h3>
                    Lista lokali 
                </h3>
                <FontAwesomeIcon 
                    icon={faPlus}
                    className="pointer" 
                    onClick={handleClick}
                />
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_MIESZKANIE_)}
                    <h4>
                        Mieszkania
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(mieszkania, _MIESZKANIE_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_GARAZ_)}
                    <h4>
                        Garaże
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(garaze, _GARAZ_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_KOMORKA_)}
                    <h4>
                        Komórki
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(komorki, _KOMORKA_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_STRYCH_)}
                    <h4>
                        Strychy
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(strychy, _STRYCH_)}
                </ul>
            </div>

            
        </div>
    )
}

export default WspolnotyLokaleLista;