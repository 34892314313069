import React, {useEffect } from 'react';
import { zl } from "../../../functions/utilities";

const ImportTransakcjiWgranaLista = props => {

    const { fileName, pelneDane, wspolnoty, mieszkancy } = props;
    
    return (
        <>
            <h5>
                { fileName !== "" ? `Nazwa pliku: ${fileName}` : ""}
            </h5>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Operacja</th>
                        <th
                            style={{minWidth: "80px"}}
                        >Data operacji</th>
                        <th>Nadawca</th>
                        <th>Mieszkaniec</th>
                        <th>Wspólnota</th>
                        <th>Kwota</th>
                        <th
                            style={{maxWidth: "250px"}}
                        >Tytuł</th>
                        <th>Odbiorca</th>
                    </tr>
                </thead>
                <tbody>
                    {pelneDane?.map((row, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 ? "var(--podswietlenieListy)" : ""  }}>
                            <td>{row.id_transkacji}</td>
                            <td>{row.operacja}</td>
                            <td>{row.data_operacji}</td>
                            <td>{row.nadawca_nazwa}</td>
                            <td>{row.id_user === undefined && row.operacja !== "prowijza" ? <span></span> : mieszkancy?.find( m => m.id_user == row?.id_user)?.imie_nazwisko }</td>
                            <td>{row.id_wspolnoty !== undefined ? wspolnoty?.find( w => w?.id === row?.id_wspolnoty)?.name : ""}</td>
                            <td
                                style={{textAlign: "right"}}
                            >
                                {zl(row.kwota)}
                            </td>
                            <td>{row.nazwa_transakcji}</td>
                            <td>{row.odbiorca_nazwa}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ImportTransakcjiWgranaLista;