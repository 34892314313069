import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import '../../scss/style.css';
import '../../scss/wspolnoty.css';
import GoBackBtn from '../GoBackBtn';
import FakturyLista from "./Faktury/FakturyLista.js";
import KontrahenciLista from "./Kontrahent/KontrahenciLista.js";
import KontaLista from "./Konta/KontaLista.js";
import ImportTransakcji from "./Bank/ImportTransakcji.js";
import HistoriaTransakcji from './Bank/HistoriaTransakcji';
import Przeksiegowania from "./Przeksiegowania/Przeksiegowania.js";
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import { getTablicaCzynsze2, getTablicaPrzelewy2, getSaldo_w } from "../../functions/tablice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport, faListOl, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { 
    fetchWspolnoty, 
    fetchMieszkancy, 
    fetchMieszkancyAll,
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice, 
    fetchStawki, 
    fetchStawkiJednorazowe,
    fetchGrupy, 
    fetchGrupyAkt,
    fetchWersje, 
    fetchUdzialyKonta,
    fetchUsers,
    fetchPlanowaneZuzycie,
    fetchUdzialyKontaAkt
  } from '../../Redux/Actions.js'; 
import { sortByTxt } from "../../functions/utilities.js";

const Ksiegowosc = () => {

    const [activeWindow, setActiveWindow] = useState("przelewy import");

    const dispatch = useDispatch();

    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [mieszkancyF, setMieszkancyF] = useState([]);
    const [saldo, setSaldo] = useState([]);

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    useEffect( () => {
        setMieszkancyF( sortByTxt(mieszkancy, "imie_nazwisko") );
    },[mieszkancy])

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKontaAkt, [], lokale, stawki, planowaneZuzycie, stawkiJednorazowe /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe /*, zaliczki */])
  
    useEffect( () => {
        setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKontaAkt, przelewy)[0] )
    },[wspolnoty, udzialyKontaAkt, przelewy])

    useEffect( () => {
        if (tablicaPrzelewy && tablicaPrzelewy){
            setSaldo( getSaldo_w(tablicaPrzelewy, tablicaCzynsze, wspolnoty) );
        }
    },[tablicaPrzelewy, tablicaCzynsze, wspolnoty]);

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchMieszkancyAll());
        dispatch(fetchUsers());
        dispatch(fetchPrzelewy()); 
        dispatch(fetchLokale()); 
        dispatch(fetchUlice()); 
        dispatch(fetchStawki()); 
        dispatch(fetchStawkiJednorazowe());
        dispatch(fetchGrupy()); 
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAkt());
        dispatch(fetchPlanowaneZuzycie());
    },[dispatch]);
    const renterComponent = () => {
        switch (activeWindow) {
            case "faktury":
                return <FakturyLista />
            case "kontrahenciLista":
                return <KontrahenciLista />
            case "konta":
                return <KontaLista />
            case "przelewy import":
                return <ImportTransakcji 
                    wspolnoty={wspolnoty}
                    przelewy={przelewy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    mieszkancy={mieszkancy}
                />
            case "przelewy historia":
                return <HistoriaTransakcji 
                    wspolnoty={wspolnoty}
                    przelewy={przelewy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    mieszkancy={mieszkancy}
                />
            case "przeksiegowania":
                return <Przeksiegowania 
                    wspolnoty={wspolnoty}
                    przelewy={przelewy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    mieszkancy={mieszkancy}
                    tablicaCzynsze={tablicaCzynsze}
                    tablicaPrzelewy={tablicaPrzelewy}
                />
            default:
                return <></>
        }
    }

    return (
        <motion.div 
            className="content ksiegowosc"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">
                Księgowość
            </h1>
            <div className="buttons">
                <GoBackBtn />
                <button disabled={true} onClick={()=>{setActiveWindow("faktury")}}>Faktury</button>
                <button disabled={true} onClick={()=>{setActiveWindow("kontrahenciLista")}}>Lista Kontrahentów</button>
                <button disabled={true} onClick={()=>{setActiveWindow("konta")}}>Konta</button>
                <button onClick={()=>{setActiveWindow("przelewy import")}}><FontAwesomeIcon icon={faFileImport} />   Wczytaj przelewy</button>
                <button onClick={()=>{setActiveWindow("przelewy historia")}}><FontAwesomeIcon icon={faListOl} />   Historia transakcji</button>
                <button onClick={()=>{setActiveWindow("przeksiegowania")}}><FontAwesomeIcon icon={faArrowRightArrowLeft} />   Przeksięgowania</button>
            </div>
            <div className="window">
                { renterComponent() }
            </div>
        </motion.div>
    )
}

export default Ksiegowosc;