import React, {useContext, useState, useEffect } from 'react';
import '../../scss/style.css';
import '../../scss/komunikaty.css';
import '../../scss/form.css';
import axios from "axios";
import GoBackBtn from '../GoBackBtn';
import KomunikatyLista from './KomunikatyLista';
import KomunikatyAddForm from './KomunikatyAddForm';
import { AuthContext } from '../../Context/AuthProvider';
import scrollToTop from '../../functions/ScrollToTop';
import Strzalka from '../Strzalka';

const Komunikaty = () =>{
    useEffect( ()=> {
        scrollToTop();
    },[])
    const { auth, role, userId } = useContext(AuthContext);

    const [activeWindow, setActiveWindow] = useState("lista");

    useEffect( () => {
        const id_temp = userId ? userId : 0;
        axios.post("/api/log", { id_user: id_temp, akcja: "Komunikaty" })
    },[])

    const page = () => {
        switch (activeWindow) {
            case "lista":
                return <KomunikatyLista setActiveWindow={setActiveWindow}/>
            case "dodaj":
                return <KomunikatyAddForm setActiveWindow={setActiveWindow}/>
            default: return (<></>)
        }
    }

    return (
        <div className='content'>
            <h1 className="nazwa">
                Komunikaty
            </h1>
            <GoBackBtn />
            { auth && role === "admin" ? (
                <>
                    <button onClick={()=>{setActiveWindow("lista")}}>Lista komunikatów</button>
                    <button onClick={()=>{setActiveWindow("dodaj")}}>Dodaj komunikat</button>
                </>
            ) : ""} 
            {page()}
            <Strzalka/>
            
        </div>
    )

}

export default Komunikaty;