import React from "react";
import { NavLink } from 'react-router-dom';
import '../../../scss/style.css';
import '../../../scss/wspolnoty.css';

const KontrahentBtnAdd = () => {


    return (
        <NavLink to="/kontrahenci/dodaj">
            <button>Dodaj kontrahenta</button>
        </NavLink>
        
    )
}

export default KontrahentBtnAdd;