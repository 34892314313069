import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import GoBackBtn from "../../GoBackBtn";
import { dataFormatMsc, nextMonth } from '../../../functions/dataFormat';
import { wstepy } from '../../../functions/motions';
import { getSaldo_g, saldoWykrzyknik } from "../../../functions/tablice";
import { dataFormat2 } from '../../../functions/dataFormat';
import Loader from "../../Loader";

import WspolnotaRaportZbiorowyTHead from "./WspolnotaRaportZbiorowyTHead";
import WspolnotaRaportZbiorowyTFoot from "./WspolnotaRaportZbiorowyTFoot";
import WspolnotyRaportZbiorowyTBody from "./WspolnotaRaportZbiorowyTBody";

const WspolnotaRaportZbiorowy = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");

    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const wspolnoty = useSelector(state => state?.wspolnoty?.data);

    const [wspolnota, setWspolnota] = useState([]);
    const [miesiac, setMiesiac] = useState(dataFormatMsc(new Date()));
    const [zakres, setZakres] = useState();
    const [zakresOd, setZakresOd] = useState(dataFormat2(new Date("2024-01-01")));
    const [zakresDo, setZakresDo] = useState(dataFormat2(new Date()));
    const [listaMsc, setListaMsc] = useState([]);
    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([])

    const [czyZakres, setCzyZakres] = useState(false)

    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);

    useEffect( () =>{
        setWspolnota( wspolnoty?.find( w => w?.id_wspolnoty == idw))
    }, [wspolnoty, idw])

    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( () => {
        if (wspolnota && listaMsc && udzialyKontaAkt_w)
          setLoaderOn(false)
    },[wspolnota, listaMsc, udzialyKontaAkt_w])

    const dodajMiesiac = d => {
        d.setMonth(d.getMonth() + 1);
        return d;
    }
    
    console.log(listaMsc)

    useEffect( () => {
        setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( udzial => udzial?.id_wspolnoty == idw))
    },[udzialyKonta, idw])

    useEffect(()=> {
        if (stawki?.length > 0) {
          let minData = stawki[0].data_od
          for (let i = 1; i < stawki.length; i++) {
            if (stawki[i].data_od < minData) {
              minData = stawki[i].data_od;
            }
          }
  
          const dataObecna = new Date();
          const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
          const tablicaMiesiecy = [];
          let aktualnaData = new Date(minData);
          while (aktualnaData <= dataMiesiacPoObecnej) {
            tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
            aktualnaData = dodajMiesiac(aktualnaData);
        }

          setListaMsc(tablicaMiesiecy.slice(0, -1))
        }
    },[stawki]);
   
    const onChangeHandle = (e) => {
        if (e.target.value === "Wybierz zakres") {
            setCzyZakres(true);
            setMiesiac(null)
        } 
        else {
            setCzyZakres(false);
            setMiesiac(e.target.value);
        }
    }
        
    const select = listaMsc?.map( (e,i) => (
        <option key={i} value={e}>
          {e} 
        </option>
    ));

    return (
        <div 
            className="content wspolnotaRozliczenie zbiorowka"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Raport zbiorowy wspólnoty
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className="buttonsZbiorowka">

                <GoBackBtn />

                <select onChange={onChangeHandle} value={miesiac}>
                    <option value="0">
                    Wybierz okres:
                    </option>
                    {select}
                    <option>
                        2024
                    </option>
                    {/* <option>
                        Wybierz zakres
                    </option> */}
                </select>

                { czyZakres && (
                    <>
                        <input type="date" value={zakresOd}/>
                        <input type="date" value={zakresDo}/>
                    </>
                ) }

            </div>

            <div style={{"height": "20px"}}></div>
            <div className="table-container zbiorowka">
                <table className="lista tabela zbiorowka" >
                    <WspolnotaRaportZbiorowyTHead 
                        miesiac={miesiac}
                        listaMsc={listaMsc}
                    />

                    <WspolnotyRaportZbiorowyTBody
                        miesiac={miesiac}
                        listaMsc={listaMsc}
                        idw={idw}
                    />
                    <WspolnotaRaportZbiorowyTFoot
                        miesiac={miesiac}
                        listaMsc={listaMsc}
                    />
                </table>
            </div>

            <div style={{"height": "50px"}}></div>
        </div>
    ) 

}

export default WspolnotaRaportZbiorowy;