import React, {  } from "react";
// import { motion } from 'framer-motion' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';

const MieszkancyDaneGrupyLokale = props => {

    const { tytul, obj } = props;

    const ikona = (tytul) => {
        switch (tytul){
            case "Mieszkanie": 
                return (<FontAwesomeIcon icon={faHouse} />)
            case "Garaże":
                return (<FontAwesomeIcon icon={faWarehouse} />);
            case "Komórki":
                return (<FontAwesomeIcon icon={faDoorClosed} />);
            case "Strychy":
                return (<FontAwesomeIcon icon={faAccusoft} />);
            default:
                return "";
        }
    }

    const lista = obj.map( (el,i) => (
        <div key={i} className="lokal">
            <ul className='lokale lista'>
                <li>
                    <div>{`Adres:`}</div>
                    <div>{`${el?.ulica?.rodzaj} ${el?.ulica?.nazwa} ${el?.ulica?.numer_budynku} / ${el?.lokal} ${el?.klatka}`}</div>
                </li>
                <li>
                    <div>Powierzchnia:</div>
                    <div>{`${el?.metraz} m²`}</div>
                </li>
                <li>
                    <div>Udział:</div>
                    <div>{`${el?.udzial}`}</div>
                </li>
                {
                    tytul === "Mieszkanie" ? (
                        <li>
                            <div>Liczba mieszkańców:</div>
                            <div>{el?.liczba_domownikow}</div>
                        </li>
                    ) : ""
                }
            </ul>
        </div>
    ))

    const liczbaLokali = tytul === "Mieszkanie" ? (` ${tytul}`) : (` ${tytul} (${obj?.length})`);

    return (
        <div className="lokale">
            <h4>{ikona(tytul)} {liczbaLokali} </h4>
            { lista }
        </div>
    )
}

export default MieszkancyDaneGrupyLokale;