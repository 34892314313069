import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { 
  fetchWspolnoty, 
  fetchMieszkancy, 
  fetchMieszkancyAll,
  fetchPrzelewy, 
  fetchLokale, 
  fetchUlice, 
  fetchStawki, 
  fetchStawkiJednorazowe,
  fetchGrupy, 
  fetchGrupyAkt,
  fetchWersje, 
  fetchUdzialyKonta,
  fetchUdzialyKontaAkt,
  fetchUsers,
  fetchPlanowaneZuzycie  
} from '../../Redux/Actions.js'; 
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import { NavLink } from "react-router-dom";

import GoBackBtn from "../GoBackBtn";
import Loader from "../Loader.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faList } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'

// import A4Component from './A4Component.js'

import { getTablicaCzynsze2, getSaldo_w, getTablicaPrzelewy2, saldoWykrzyknik } from "../../functions/tablice.js";

const WspolnotyLista = () => {

    const dispatch = useDispatch();
    
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAll = useSelector( state => state?.grupyAll?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const wersje = useSelector( state => state?.wersje?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);

    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [saldo, setSaldo] = useState([]);
    const [numeracjaNot, setNumeracjaNot] = useState([]);

    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( () => {
      if (tablicaCzynsze?.length && tablicaPrzelewy?.length && saldo?.length && wspolnoty?.length)
        setLoaderOn(false)
    },[tablicaCzynsze, tablicaPrzelewy, saldo, wspolnoty])
  
    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchMieszkancyAll());
      dispatch(fetchUsers());
      dispatch(fetchPrzelewy()); 
      dispatch(fetchLokale()); 
      dispatch(fetchUlice()); 
      dispatch(fetchStawki()); 
      dispatch(fetchStawkiJednorazowe());
      dispatch(fetchGrupy()); 
      dispatch(fetchUdzialyKonta());
      dispatch(fetchUdzialyKontaAkt());
      dispatch(fetchPlanowaneZuzycie());
    },[dispatch]);

    useEffect( () => {
      dispatch(fetchWersje(grupy));
    },[dispatch, grupy]);

    useEffect( () => {
      dispatch(fetchGrupyAkt(grupy, wersje));
    },[dispatch, grupy, wersje])

    useEffect( () => {
      setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe /*, zaliczki */])

    useEffect( () => {
      setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKontaAkt, przelewy)[0] )
    },[wspolnoty, udzialyKonta, przelewy])

    useEffect( () => {
      if (tablicaPrzelewy && tablicaPrzelewy){
        setSaldo( getSaldo_w(tablicaPrzelewy, tablicaCzynsze, wspolnoty) );
      }
    },[tablicaPrzelewy, tablicaCzynsze, wspolnoty]);
    
    return (
        <motion.div 
          className="content"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Lista wspólnot
            </h1>
            <div className="buttons">
              <GoBackBtn />
              <NavLink to="/wspolnoty/dodaj">
                  <button>Dodaj wspólnotę</button>
              </NavLink>
            </div>
            
            <ul className="lista wspolnoty">
              <li>
                  <div>   Nazwa</div>
                  <div>   NIP</div>
                  <div>   Adres</div>
                  <div 
                    className="central"
                  >
                    Dane
                  </div>
                  <div className="central">
                      Stawki</div>
                  <div className="central">
                      Należności</div>
                  <div className="central">
                      Raport zbiorowy</div>
                  <div className="central">
                      Mieszkańcy</div>
                  <div className="central">
                      Udziały</div>
              </li>
              {wspolnoty?.map( (item,i) => {
                  
                let czy_saldo = saldo?.filter( s => s.id_wspolnoty == item.id)[0]?.saldo < 0 ? true : false;
                return (
                  <li key={item.id} className={ i % 2 === 0 ? "g": ""} style={{minHeight: "40px"}}>
                      <div>{item.name}</div>
                      <div>{item.nip}</div>
                      <div>{item.ulica}</div>
                      <div className="central"><NavLink to={`/wspolnoty/dane?id=${item.id}`} exact="true" ><FontAwesomeIcon icon={faAddressCard} /></NavLink></div>
                      <div className="central"><NavLink to={`/wspolnoty/stawki/?id=${item.id}`} exact="true" ><FontAwesomeIcon icon={faList} /></NavLink></div>
                      <div className="central">
                        <NavLink 
                          to={`/wspolnoty/rozliczenia/?id=${item.id}`} 
                          exact="true" 
                          style={{ "textDecoration": 'none'}} 
                          state={
                            {
                              from: 
                              {
                                tablicaPrzelewy: tablicaPrzelewy?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                                tablicaCzynsze: tablicaCzynsze?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                                // saldo_w: saldo?.filter( s => s?.id_wspolnoty == item.id)
                              }
                            }
                          }
                        >
                          <FontAwesomeIcon icon={faHandHoldingDollar} />
                          { czy_saldo ? saldoWykrzyknik() : ""}
                        </NavLink>
                      </div>
                      <div className="central">
                        <NavLink 
                          to={`/wspolnoty/raportZbiorowy/?id=${item.id}`} 
                          exact="true" 
                          style={{ "textDecoration": 'none'}} 
                          state={
                            {
                              from: 
                              {
                                tablicaPrzelewy: tablicaPrzelewy?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                                tablicaCzynsze: tablicaCzynsze?.find( t => t?.id_wspolnoty == item.id)?.grupy,
                                // saldo_w: saldo?.filter( s => s?.id_wspolnoty == item.id)
                              }
                            }
                          }
                        >
                          <FontAwesomeIcon icon={faBook} />
                          { czy_saldo ? saldoWykrzyknik() : ""}
                        </NavLink>
                      </div>
                      <div className="central">
                        <NavLink 
                          to={`/mieszkancy?idw=${item.id}`} 
                          exact="true" 
                        >
                          <FontAwesomeIcon icon={faUserGroup} />
                        </NavLink>
                      </div>
                      <div className='central'>
                        <NavLink  
                          to={`/wspolnoty/udzialy?id=${item.id}`}  
                        >
                          <FontAwesomeIcon 
                            icon={faChartPie} 
                            style={{color: "#307bfd",}} 
                          />
                        </NavLink>
                      </div>
                  </li>
                )
              })}
            </ul>
            {/* <A4Component data={data} /> */}
        </motion.div>
    )
}

export default WspolnotyLista;

