import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { usun_duplikaty } from "../../functions/grupy.js";
import { ikona } from "../../functions/ikony.js";
import { zl } from "../../functions/utilities.js";
import { adres } from "../../functions/ulice.js";

const ZarzadRozliczenieWspolnoty = props => {

    const { stawki_w, stawkiJednorazowe_w, czynszeWspolnota, grupy, idw, przelewyWspolnota, wspolnota, udzialyKonta, miesiac } = props;

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);

    const [czynszeMsc, setCzynszeMsc] = useState([]);

    useEffect( () => { 
        const temp2  = [];
        
        czynszeWspolnota?.forEach( czynsz => {
          czynsz?.grupy_udzial_msc?.forEach( c => {
            const temp = c.temp_msc?.filter( t => t.msc == miesiac)
            if (temp && temp.length > 0) {
              temp2.push(
                {
                  id_user: czynsz.id_user,
                  grupa_numer: c.grupa_numer,
                  czynsz: temp[0],
                }
              )
            }
          });
        });
          
        setCzynszeMsc(temp2)
      },[czynszeWspolnota, miesiac])

      
    const tab = udzialyKonta?.map( (udzial, i) => {

        const mieszkaniec = mieszkancy.filter( mieszkaniec => mieszkaniec.id_user == udzial.id_user)[0];
        // console.log(mieszkaniec)
        const czynsz_temp = czynszeMsc?.filter( czynsz => czynsz.id_user == udzial.id_user && czynsz.grupa_numer == udzial.grupa_numer);
        // console.log(czynsz_temp)
        const czynsz = czynsz_temp && czynsz_temp.length > 0 ? czynsz_temp[0].czynsz : null
        // console.log(czynsz)
        if (czynsz?.length == 0)
          return {}
  
        const grupy_usera = grupy?.filter( g => g.id_user == mieszkaniec.id_user);
        const lokale_usera_temp = grupy_usera?.map( g => {
          return {
            id_lokal: g.id_lokal,
            id_user: g.id_user,
            grupa_numer: g.grupa_numer
          }
        });
  
        const lokale_usera = usun_duplikaty(lokale_usera_temp);
  
        const lokale_td = lokale_usera?.map( (lokal, i) => {
          return (
            
            <li 
                key={i}
                style={{textAlign: "left"}}    
            >
                {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.ulica, ulice, lokal.id_lokal, lokale)}`} 
              {/* </NavLink> */}
            </li>
  
          )
        })
  
        return (
          <>
          { 
            czynsz && <tr key={i}>
            <td>
              {`${mieszkaniec.imie_nazwisko}`}
            </td>
            <td>
              <ul style={{"listStyle": "none"}}>
                {lokale_td}
              </ul>
            </td>
              { stawki_w?.czy_eksploatacja == 1 ? (<td>{zl(czynsz.eksploatacja)}</td>) : ""   }
              { stawki_w?.czy_garaz == 1 ? (<td>{zl(czynsz.garaz)}</td>) : ""}
              { stawki_w?.czy_strych == 1 ? (<td>{zl(czynsz.strych)}</td>) : "" }
              { stawki_w?.czy_komorka ==  1 ? (<td>{zl(czynsz.komorka)}</td>) : "" }
              { stawki_w?.czy_utrzymanie_czystosci == 1 ? (<td>{zl(czynsz.utrzymanie_czystosci)}</td>) : ""} 
              { stawki_w?.czy_wywoz_smieci == 1 ? (<td>{zl(czynsz.wywoz_smieci)}</td>) : ""} 
              { stawki_w?.czy_fundusz_remontowy == 1 ? (<td>{zl(czynsz.fundusz_remontowy)}</td>) : ""} 
              { stawki_w?.czy_konserw_domofonu == 1 ? (<td>{zl(czynsz.konserw_domofonu)}</td>) : ""} 
              { stawki_w?.czy_konserw_windy == 1 ? (<td>{zl(czynsz.konserw_windy)}</td>) : ""} 
              { stawki_w?.czy_opl_za_adm == 1 ? (<td>{zl(czynsz.opl_za_adm)}</td>) : ""} 
              { stawki_w?.czy_abonament_cw == 1 ? (<td>{zl(czynsz.abonament_cw)}</td>) : ""} 
              { stawki_w?.czy_co == 1 ? (<td>{zl(czynsz.co)}</td>) : ""} 
              { stawki_w?.czy_energia_el_lokalu == 1 ? (<td>{zl(czynsz.energia_el_lokal)}</td>) : ""} 
              { stawki_w?.czy_ryczalt_gaz == 1 ? (<td>{zl(czynsz.ryczalt_ga)}</td>) : ""} 
              { stawki_w?.czy_zagosp_terenu == 1 ? (<td>{zl(czynsz.zagosp_teren)}</td>) : ""} 
              { stawki_w?.czy_antena == 1 ? (<td>{zl(czynsz.antena)}</td>) : ""} 
              { stawki_w?.czy_ciepla_woda == 1 ? (<td>{zl(czynsz.ciepla_woda)}</td>) : ""} 
              { stawki_w?.czy_zimna_woda == 1 ? (<td>{zl(czynsz.zimna_woda)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_1_czy == 1 ? (<td>{zl(czynsz.dod_1)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_2_czy == 1 ? (<td>{zl(czynsz.dod_2)}</td>) : ""} 
              { stawkiJednorazowe_w?.dod_3_czy == 1 ? (<td>{zl(czynsz.dod_3)}</td>) : ""} 
              <td>{zl(czynsz.czynsz_na_msc)}</td>
          </tr>}
          </>
  
        )
    })

    return (
        <table className="lista tabela naleznosci">
            <thead>
                <tr key={0}>
                    <th>Mieszkaniec</th>
                    <th>Lokal</th>
                    { stawki_w?.czy_eksploatacja == 1 ? ( <th>eks.</th> ) : ""   }
                    { stawki_w?.czy_garaz == 1 ? ( <th>gar.</th> ) : ""}
                    { stawki_w?.czy_strych == 1 ? ( <th>str.</th> ) : "" }
                    { stawki_w?.czy_komorka ==  1 ? ( <th>kom.</th> ) : "" }
                    { stawki_w?.czy_utrzymanie_czystosci == 1 ? ( <th>utr.</th> ) : ""} 
                    { stawki_w?.czy_wywoz_smieci == 1 ? ( <th>wyw.</th> ) : ""} 
                    { stawki_w?.czy_fundusz_remontowy == 1 ? ( <th>rem.</th> ) : ""} 
                    { stawki_w?.czy_konserw_domofonu == 1 ? ( <th>dom.</th> ) : ""} 
                    { stawki_w?.czy_konserw_windy == 1 ? ( <th>win.</th> ) : ""} 
                    { stawki_w?.czy_opl_za_adm == 1 ? ( <th>adm.</th> ) : ""} 
                    { stawki_w?.czy_abonament_cw == 1 ? ( <th>abo.</th> ) : ""} 
                    { stawki_w?.czy_co == 1 ? ( <th>c.o.</th> ) : ""} 
                    { stawki_w?.czy_energia_el_lokalu == 1 ? ( <th>ene.</th> ) : ""} 
                    { stawki_w?.czy_ryczalt_gaz == 1 ? ( <th>gaz </th>) : ""} 
                    { stawki_w?.czy_zagosp_terenu == 1 ? ( <th>zag.</th> ) : ""} 
                    { stawki_w?.czy_antena == 1 ? ( <th>ant.</th> ) : ""} 
                    { stawki_w?.czy_ciepla_woda == 1 ? ( <th>cie.</th> ) : ""} 
                    { stawki_w?.czy_zimna_woda == 1 ? ( <th>zim.</th> ) : ""} 
                    { stawkiJednorazowe_w?.dod_1_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_1_opis}</th> ) : ""} 
                    { stawkiJednorazowe_w?.dod_2_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_2_opis}</th> ) : ""} 
                    { stawkiJednorazowe_w?.dod_3_czy == 1 ? ( <th>{stawkiJednorazowe_w?.dod_3_opis}</th> ) : ""}
                    <th>Suma </th>
                </tr>
            </thead>
            <tbody>
                {tab}
            </tbody>
        </table>
    )
}

export default ZarzadRozliczenieWspolnoty;