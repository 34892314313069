import React, {useState, useEffect, useContext} from 'react';
import GoBackBtn from '../GoBackBtn';
import { AuthContext } from '../../Context/AuthProvider';
import axios from "axios";


import '../../scss/style.css';
import '../../scss/komunikaty.css';
import '../../scss/form.css';

const KomunikatyAddForm = () => {

    const { userId, setActiveWindow } = useContext(AuthContext);

    const [tytul, setTytul] = useState("");
    const [tresc, setTresc] = useState("");
    const [data, setData] = useState("");
    const [fileInput, setFileInput] = useState(null);
    const [itemName, setItemName] = useState('');
    const [list, setList] = useState([]);

    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        setFileInput(e.target.files[0]);
    };

    const handleItemNameChange = (e) => {
        setItemName(e.target.value);
    };
    

    const handleAddItem = async () => {
        if (itemName && fileInput) {
          const formData = new FormData();
          formData.append('file', fileInput);
    
          try {
            const response = await axios.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
    
            const newItem = {
              name: itemName,
              filename: response.data.filename
            };
            setList([...list, newItem]);
            setItemName('');
            setFileInput(null);
          } catch (error) {
            console.error('Error uploading file:', error);
          }
        } else {
          alert("Both item name and file are required");
        }
    };

    const addData = async () => {
        try {
          // Wysyłamy dane na serwer
          await axios.post('/api/komunikaty/dodaj', {
            autor_id: userId,
            tytul: tytul,
            tresc: tresc,
            data: data,
            status_komunikatu: "widoczny"
          }).then( response => {
            const idKomunikatu = response?.data?.id;
            list?.forEach( (l,i) => {
                axios.post('/api/komunikaty/att/add', {
                    id_komunikat: idKomunikatu,
                    nazwa_pliku: l.filename,
                    opis: l.name
                  }).then( response => {
                    if ( list.length == i )
                        setActiveWindow("lista")
                  })
            });
        });
          // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
    };

    const onSubmit = (  ) => addData();

    const handleTytul = e => setTytul(e.target.value);
    const handleTresc = e => setTresc(e.target.value);
    const handleData = e => setData(e.target.value);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
          const response = await axios.get('/files');
          setFiles(response.data);
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };
    
    return (
        <div className='content'>
            <h1 className="nazwa">
                Dodaj komunikat
            </h1>

            <GoBackBtn />
            <div className='komunikatFormularz'>
                <p>Tytył komunikatu:</p>
                <input 
                    type='text' 
                    value={tytul} 
                    onChange={handleTytul}
                />
                <p>Treść</p>
                <textarea 
                    rows="10" 
                    cols="50" 
                    value={tresc}
                    onChange={handleTresc}
                />
                <p>Data publikacji</p>
                <input
                    type='date'
                    value={data}
                    onChange={handleData}
                />
                <div className='att'>
                    <p>Dodaj załączniki</p>
                    <input 
                        type="text" 
                        value={itemName} 
                        onChange={handleItemNameChange} 
                        placeholder="Nazwa pliku" 
                    />
                    <input 
                        type="file" 
                        onChange={handleFileChange} 
                    />
                    <button onClick={handleAddItem}>Zapisz załącznik</button>
                    <ul>
                        {list.map((item, index) => (
                            <li key={index}>
                            {item.name} 
                            {/* <button onClick={() => handleDownloadFile(item.filename)}>Download File</button> */}
                            </li>
                        ))}
                    </ul>
                </div>
                <button onClick={onSubmit}>Zapisz komunikat</button>
            </div>       
            
            {/* <div>
                <h3>Available Files</h3>
                <ul>
                    {files.map((file, index) => (
                    <li key={index}>
                        {file} 
                        <button onClick={() => handleDownloadFile(file)}>Download File</button>
                    </li>
                    ))}
                </ul>
            </div> */}

        </div>
    )
}

export default KomunikatyAddForm;