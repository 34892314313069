export const dictionary = [
    ["eksploatacja","Eksploatacja"],
    ["garaz", "Garaż"],
    ["strych", "Strych"],
    ["utrzymanie_czystosci", "Utrzymanie czystości"],
    ["wywoz_smieci", "Wywóz śmieci"],
    ["fundusz_remontowy", "Fundusz remontowy"],
    ["konserw_domofonu", "Konserwacja domofonu"],
    ["konserw_windy", "Konserwacja windy"],
    ["opl_za_adm", "Opłata za administrację"],
    ["zimna_woda", "Zimna woda"],
    ["ciepla_woda", "Ciepła woda"],
    ["abonament_cw", "Abonament - c.w."],
    ["co", "Centralne ogrzewanie"],
    ["energia_el_lokalu", "Energia elekt. lokalu"],
    ["ryczalt_gaz", "Ryczałt za gaz"],
    ["zagosp_terenu", "Zagospodarowanie terenu"],
    ["antena", "Opłata za antenę"],
    ["data_od", "Data obowiązywania"],
    ["komorka", "Komórka"],
    ["imie_nazwisko", "Imię i nazwisko"],
    ["ulica", "Ulica"],
    ["numer_domu", "Numer budynku"],
    ["numer_lokalu", "Numer mieszkania"],
    ["kod_pocztowy", "Kod pocztowy"],
    ["miejscowosc", "Miejscowość"],
    ["czy_strych", "Strych"],
    ["czy_komorka", "Komórka"],
    ["strych_metraz", "Metraż strychu"],
    ["komorka_metraz", "Metraż komórki"],
    ["czy_garaz", "Garaż"],
    ["garaz_metraz", "Metraż garażu"],
    ["status_mieszkania", "Status"],
    ["data_zasiedlenia", "Data zasiedlenia"],
    ["data_zwolnienia", "Data zwolnienia"],
    ["lokal_metraz", "Metraż lokalu"],
    ["liczba_domownikow", "Liczba domowników"],
    ["klatka", "Klatka"],
    ["czy_nota", "Nota obciążeniowa"],
    ["czy_zarzad", "Członek zarządu wspólnoty"],
    ["nip", "NIP"]
  ];

export const translate = (word) => { 
    let result = dictionary.filter((a)=>a[0]===word);
    return result.length > 0 ? result[0][1] : word;
}

export const rodzaj_rozliczenia = (rodzaj, czy_woda) => {
  if (czy_woda == "zimna_woda" || czy_woda == "ciepla_woda")
    return "m³"

  switch (rodzaj) {
    case '1': return "opłata stała";
    case '3': return "zł/os."
    case '5': return "m² p.u.";
    case '9': return 0;
    case '16': return "m² p.u.";
    case '17': return "m² p.u.";
    case '18': return "m² p.u.";
    default: return "-";
  }
}