import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import './../scss/cookie.css';


const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const navigate = useNavigate();
    const giveCookieConsent = () => {
      setCookie("cookieConsent", true, { path: "/" });
    };
    const handlePolityka = () => {
        navigate(`/polityka-prywatnosci`);
    }
    return (
        <div className="cookie-consent cienie">
          <p>
            Używamy cookies i podobnych technologii w celach świadczenia usług. Korzystanie z witryny bez zmiany ustawień Twojej przeglądarki oznacza, że będą one umieszczane w Twoim urządzeniu końcowym. 
            Szczegóły znajdziesz w <span className='pointer' onClick={()=>{handlePolityka()}} >Polityce Prywatności</span>.
          </p>
          <button onClick={giveCookieConsent}>
            Akceptuję
          </button>
        </div>
      );
}




export default CookieConsent;