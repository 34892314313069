export const dropdownOptions = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'},
    { key: 'per osoba', value: '3'},
    { key: 'p.u. lokalu', value: '5'},
    { key: 'p.u. garażu', value: '16'},
    { key: 'p.u. strychu', value: '17'},
    { key: 'p.u. komórki', value: '18'},
    { key: 'całkowita p.u.', value: '9'},
    { key: 'm3', value: '1'},
]

export const dropdownOptionsJednorazowe = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'},
    { key: 'per osoba', value: '3'},
    { key: 'p.u. lokalu', value: '5'},
    { key: 'całkowita p.u.', value: '9'}
]

export const dropdownOptionsA = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'},
    { key: 'p.u. lokalu', value: '5'},
    { key: 'całkowita p.u.', value: '9'},
]

export const dropdownOptionsB = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'},
    { key: 'p.u. lokalu', value: '5'},
]

export const dropdownOptionsC = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'},
    { key: 'per osoba', value: '3'},
]

export const dropdownOptionsD = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per osoba', value: '3'},
]

export const dropdownOptionsE = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'p.u. lokalu', value: '5'}
]

export const dropdownOptionsF = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'per lokal', value: '1'}
]

export const dropdownOptionsGaraz = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'p.u. garażu', value: '16'},
    { key: 'per lokal', value: '1'}
]

export const dropdownOptionsStrych = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'p.u. strychu', value: '17'},
    { key: 'per lokal', value: '1'}
]

export const dropdownOptionsKomorka = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'p.u. komórki', value: '18'},
    { key: 'per lokal', value: '1'}
]

export const dropdownOptionsWoda = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'm3', value: '1'},
]

export const woda = [
    { key: 'Wybierz wariant', value: ''},
    { key: 'Prosty', value: '1'},
    { key: 'Fakturowy', value: '2'}
]