import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import {  
    fetchUlice
  } from '../../../Redux/Actions.js'; 

const WspolnotyUliceDodaj = props =>{

    const { setActiveWindow, idw } = props;

    const dispatch = useDispatch();

    const _UL_ = "ul.";
    const _AL_ = "al.";

    const [nazwaUlicy, setNazwaUlicy] = useState("");
    const [numerBudynku, setNumerBudynku] = useState("");
    const [rodzajUlicy, setRodzajUlicy] = useState(_UL_);

    const handleUlica = e => setNazwaUlicy(e.target.value);
    const handleRodzaj = e => setRodzajUlicy(e.target.value);
    const handleNumer = e => setNumerBudynku(e.target.value);

    const handleAnuluj = () => setActiveWindow("lista");

    const handleZapisz = async () => {
        try {   
            // Wysyłamy dane na serwer
            await axios.post('/api/ulica/dodaj', {
              rodzaj: rodzajUlicy,
              nazwa: nazwaUlicy,
              id_wspolnoty: idw,
              numer_budynku: numerBudynku
            }).then(response => {
                if (response.status === 200) {
                    dispatch(fetchUlice()); 
                    setNazwaUlicy("");
                    setNumerBudynku("");
                    setRodzajUlicy(_UL_);
                    setActiveWindow("lista");
                }
            });
          } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
          }
    }

    return (
        <div className="wspolnotyUliceDodaj">
            <div className="naglowek">
                <h3>
                    Dodawanie ulicy
                </h3>
            </div>
            <div>
                <p>Nazwa ulicy:</p>
                <select 
                    onChange={handleRodzaj} 
                    value={rodzajUlicy}
                >
                    <option value={_UL_}>ul.</option>
                    <option value={_AL_}>al.</option>
                </select>
                <input 
                    type="text" 
                    onChange={handleUlica} 
                    value={nazwaUlicy}
                />
            </div>
            <div>
                <p>Numer budynku:</p>
                <input 
                    type="text" 
                    className="nr" 
                    onChange={handleNumer} 
                    value={numerBudynku}
                />
            </div>
            <button 
                onClick={handleZapisz}
            >
                Dodaj
            </button>
            <button
                onClick={handleAnuluj}
            >  
                Anuluj
            </button>
        </div>
    )
}

export default WspolnotyUliceDodaj;